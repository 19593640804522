import { SET_CLIENT_MODAL_VISIBLE, ON_CLIENT_ADD_REQUEST,ON_CLIENT_EDIT_REQUEST } from "./actionType";

export const ON_CHANGE_CLIENT_MODAL_VISIBLE = visible => ({
  type: SET_CLIENT_MODAL_VISIBLE,
  payload: visible
});

export const onClientAddRequest = data => dispatch =>
  dispatch({
    types: ON_CLIENT_ADD_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/portal/client",
        method: "post",
        data: {
          ...data
        }
      }
    }
  });
  

export const onClientEditRequest = data => dispatch =>
  dispatch({
    types: ON_CLIENT_EDIT_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/portal/client",
        method: "put",
        data: {
          ...data
        }
      }
    }
  });