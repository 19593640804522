/* eslint-disable array-callback-return */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Avatar } from "antd";
import { textColor, sizes, colors } from "../../constants/constants";
import TripInformationCard from "../tripInforamation/tripInformationCard";
import MapComponent from "../mapComponent/mapComponent";
import TripHistoryCard from "../tripInforamation/tripHistoryCard";
// import TableComponent from "../tableComponent/tableComponent";
import ProfileStrength from "../profileStrength/profileStrength";
import VehicleInfoCard from "../detailInfoCard/vehicleInfoCard";
import TripTable from "../tableComponent/tripTable";
import DriverInfoCard from "../detailInfoCard/driverInfoCard";
import ClientInfoCard from "../detailInfoCard/clientInfoCard";
import BrokerInfoCard from "../detailInfoCard/brokerInfoCard";
import CPtable from "../tableComponent/CPtable";
import FLtable from "../tableComponent/FItable";
import openSocket from "socket.io-client";
import { Images } from "../../public/asset";
import TripRequestInfoCard from "../tripRequestInfo/TripRequestInfoCard";
import {  driverAppGpsSocket } from "../../constants/config";
import BidsTable from "../tripRequestInfo/bidingList";
const socket = openSocket(driverAppGpsSocket, {
  transports: ["websocket"],
  upgrade: false
});
class FullInfoViewLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OnlineDrivers: []
    };
  }

  componentWillReceiveProps(nextProps){
    if (this.props.data!==nextProps.data) {
      if (nextProps.type==="Trips") {
        let allDrivers = new Array();
        this.state.OnlineDrivers.length = 0;
        nextProps.data.assignedTo.map((val,ind)=>{
          if (val.type==="Driver") {
            allDrivers[val.driverGlobalId] = new Array();
            allDrivers[val.driverGlobalId].push({
              name: val.driverName,
              phoneNumber: val.driverName,
              localId: val.driverGlobalId,
              lat: 0,
              long: 0
            });
        
          }
        })
     
        this.setState({ OnlineDrivers: allDrivers });
      }
     
      if (nextProps.type==="Trips") {
        let allDrivers = new Array();
        this.state.OnlineDrivers.length = 0;
        allDrivers[this.props.data.driverId] = new Array();
        allDrivers[this.props.data.driverId].push({
          name: this.props.data.name,
          phoneNumber: this.props.data.name,
          localId: this.props.data.driverId,
          lat: 0,
          long: 0
        }); 
     
        this.setState({ OnlineDrivers: allDrivers });
      }
    

    }
  }

  componentWillMount(){
    if (this.props.type==="Trips") {
      let allDrivers = new Array();
      let id = new Array();
      this.state.OnlineDrivers.length = 0;
      if (this.props.data) {
        this.props.data.assignedTo.map((val,ind)=>{
          if (val.type==="Driver") {
            allDrivers[val.driverGlobalId] = new Array();
            allDrivers[val.driverGlobalId].push({
              name: val.driverName,
              phoneNumber: val.driverName,
              localId: val.driverGlobalId,
              lat: 0,
              long: 0
            }); 
          }
        })
        allDrivers.map((val, ind) => {
          console.log('val[0].localId',val[0].localId)
          id.push(val[0].localId);
        });
        socket.emit("join", id);
        socket.on("newMessage", msg => {
          // console.log('message from socket ',msg);
        });
        socket.on("driverUpdates", data => {
          if (this.state.OnlineDrivers[data.token]) {
            console.log("this.state.OnlineDrivers[data]", data);
            this.state.OnlineDrivers[data.token][0].lat = Number(data.lat);
            this.state.OnlineDrivers[data.token][0].long = Number(data.long);
            // =console.log('this.state.OnlineDrivers[data.token]',this.state.OnlineDrivers[data.token][0])
            this.setState({ OnlineDrivers: this.state.OnlineDrivers });
          }
        });
        this.setState({ OnlineDrivers: allDrivers });
      }
      
    }


    if (this.props.type==="Driver") {
      let allDrivers = new Array();
      let id = new Array();
      this.state.OnlineDrivers.length = 0;
      if (this.props.data.driverId) {
        allDrivers[this.props.data.driverId] = new Array();
        allDrivers[this.props.data.driverId].push({
          name: this.props.data.name,
          phoneNumber: this.props.data.name,
          localId: this.props.data.driverId,
          lat: 0,
          long: 0
        }); 
        allDrivers.map((val, ind) => {
          console.log('val[0].localId',val[0].localId)
          id.push(val[0].localId);
        });
        socket.emit("join", id);
        socket.on("newMessage", msg => {
          // console.log('message from socket ',msg);
        });
        socket.on("driverUpdates", data => {
          if (this.state.OnlineDrivers[data.token]) {
            console.log("this.state.OnlineDrivers[data]", data);
            this.state.OnlineDrivers[data.token][0].lat = Number(data.lat);
            this.state.OnlineDrivers[data.token][0].long = Number(data.long);
            // =console.log('this.state.OnlineDrivers[data.token]',this.state.OnlineDrivers[data.token][0])
            this.setState({ OnlineDrivers: this.state.OnlineDrivers });
          }
        });
        this.setState({ OnlineDrivers: allDrivers });
      }
      
    }
      
  }



  renderFirstCol = () => {
    //  clearInterval(inter);
    if (this.props.type === "Trips") {
      return <TripInformationCard {...this.props} />;
    } else if (this.props.type === "Driver") {
      return <DriverInfoCard {...this.props} />;
    }
    else if (this.props.type === "Requests") {
      return <TripRequestInfoCard {...this.props} />;
    }
    else if (this.props.type === "Vehicle") {
      return <VehicleInfoCard {...this.props} />;
    } else if (this.props.type === "Client") {
      return <ClientInfoCard {...this.props} />;
    } else if (this.props.type === "Broker") {
      return <BrokerInfoCard {...this.props} />;
    }
  };

  renderSecondCol = () => {
    const { data } = this.props;
    if (this.props.type === "Trips") {
      return (
        <div style={{ width: "100%", height: "100%" }}>
          <h2 style={{ color: textColor.text_light, fontSize: sizes.h1 }}>
            Location
          </h2>
          <MapComponent markersArray={this.state.OnlineDrivers} latlng={false} height={"300px"} />
          {/* <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 10,
              marginBottom: 10
            }}
          >
            Logs
          </h2>
          <TableComponent type="Trips" data={[]} />
          <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 10,
              marginBottom: 10
            }}
          >
            Documents
          </h2>
          <div style={{ marginBottom: 50 }}>
            <TableComponent type="Trips" data={[]} />
          </div> */}
        </div>
      );
    } 
    else if (this.props.type === "Requests") {
      return (
        <div style={{ width: "100%", height: "100%" }}>
          <h2 style={{ color: textColor.text_light, fontSize: sizes.h1 }}>
            Bids
          </h2>
          <BidsTable {...this.props} />
          <h2 style={{ color: textColor.text_light, fontSize: sizes.h1,marginTop:20 }}>
            Location
          </h2>
          <MapComponent
            markersArray={[]}
            latlng={false}
            height={"300px"}
          />
          
        </div>
      );
    }
    else if (this.props.type === "Client") {
      return (
        <div style={{ width: "100%" }}>
          <h2 style={{ color: textColor.text_light, fontSize: sizes.h1 }}>
            Contact Person(s)
          </h2>
          <CPtable
            type="Trips"
            data={this.props.data.poc !== null ? this.props.data.poc : []}
          />
          <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 20,
              marginBottom: 10
            }}
          >
            Trips
          </h2>
          <div style={{ float: "right", marginTop: -30, marginRight: 10 }}>
            <span
              style={{
                fontSize: sizes.h2,
                color: colors.primaryColor,
                cursor: "pointer"
              }}
              onClick={() => {
                this.props.clicked();
              }}
            >
              View All
            </span>
          </div>
          <div style={{ marginBottom: 10 }}>
            <TripTable type="Trips" data={data.tripInformation} />
          </div>
          {/* <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 20,
              marginBottom: 10
            }}
          >
            Logs
          </h2>
          <TableComponent type="Trips" data={[]} />
          <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 20,
              marginBottom: 10
            }}
          >
            Documents
          </h2>
          <TableComponent type="Trips" data={[]} /> */}
        </div>
      );
    } else if (this.props.type === "Driver") {
      return (
        <div style={{ width: "100%" }}>
          <h2 style={{ color: textColor.text_light, fontSize: sizes.h1 }}>
            Location
          </h2>
          <MapComponent markersArray={this.state.OnlineDrivers} latlng={false} height={"300px"} />
          <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 20,
              marginBottom: 10
            }}
          >
            Trips
          </h2>
          <div style={{ float: "right", marginTop: -30, marginRight: 10 }}>
            <span
              style={{
                fontSize: sizes.h2,
                color: colors.primaryColor,
                cursor: "pointer"
              }}
              onClick={() => {
                this.props.clicked();
              }}
            >
              View All
            </span>
          </div>
          <div style={{ marginBottom: 10 }}>
            <TripTable type="Driver" data={data.tripInformation} />
          </div>
          {/* <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 20,
              marginBottom: 10
            }}
          >
            Logs
          </h2>
          <TableComponent type="Trips" data={[]} />
          <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 20,
              marginBottom: 10
            }}
          >
            Documents
          </h2>
          <TableComponent type="Trips" data={[]} /> */}
        </div>
      );
    } else if (this.props.type === "Vehicle") {
      return (
        <div style={{ width: "100%" }}>
          <h2 style={{ color: textColor.text_light, fontSize: sizes.h1 }}>
            Location
          </h2>
          <MapComponent markersArray={[]} latlng={false} height={"300px"} />
          <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 20,
              marginBottom: 10
            }}
          >
            Trips
          </h2>
          <div style={{ float: "right", marginTop: -30, marginRight: 10 }}>
            <span
              style={{
                fontSize: sizes.h2,
                color: colors.primaryColor,
                cursor: "pointer"
              }}
              onClick={() => {
                this.props.clicked();
              }}
            >
              View All
            </span>
          </div>
          <div style={{ marginBottom: 10 }}>
            <TripTable type="Trips" data={data.tripInformation} />
          </div>
          {/* <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 20,
              marginBottom: 10
            }}
          >
            Logs
          </h2>
          <TableComponent type="Trips" data={[]} />
          <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 20,
              marginBottom: 10
            }}
          >
            Documents
          </h2>
          <TableComponent type="Trips" data={[]} /> */}
        </div>
      );
    } else {
      return (
        <div style={{ width: "100%" }}>
          <h2 style={{ color: textColor.text_light, fontSize: sizes.h1 }}>
            Fleet Info
          </h2>
          <FLtable
            type="Trips"
            data={
              this.props.data.dedicatedFleet
                ? this.props.data.dedicatedFleet
                : []
            }
          />
          <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 20,
              marginBottom: 10
            }}
          >
            Trips
          </h2>
          <div style={{ float: "right", marginTop: -30, marginRight: 10 }}>
            <span
              style={{
                fontSize: sizes.h2,
                color: colors.primaryColor,
                cursor: "pointer"
              }}
              onClick={() => {
                this.props.clicked();
              }}
            >
              View All
            </span>
          </div>
          <div style={{ marginBottom: 10 }}>
            <TripTable type="Trips" data={data.tripInformation} />
          </div>
          {/* <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 20,
              marginBottom: 10
            }}
          >
            Logs
          </h2>
          <TableComponent type="Trips" data={[]} />
          <h2
            style={{
              color: textColor.text_light,
              fontSize: sizes.h1,
              marginTop: 20,
              marginBottom: 10
            }}
          >
            Documents
          </h2>
          <TableComponent type="Trips" data={[]} /> */}
        </div>
      );
    }
  };
  renderButtons = () => {
    const { data } = this.props;
    if (data.tripStatus === "Upcoming") {
      return (
        <Button
          type="default"
          style={{ margin: 10 }}
          onClick={this.props.showConfirmStart}
        >
          Start
        </Button>
      );
    } else if (data.tripStatus === "Ongoing") {
      return (
        <Button
          type="danger"
          ghost
          style={{ margin: 10 }}
          onClick={this.props.showConfirmEnd}
        >
          End
        </Button>
      );
    }
  };
  renderThirdCol = () => {
    const { type } = this.props;
    if (type === "Trips") {
      return (
        <div>
          <TripHistoryCard data={this.props.data} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 5,
              float: "right"
            }}
          >
            {this.renderButtons()}
          </div>
        </div>
      );
    } else {
      return <ProfileStrength data={this.props.data} />;
    }
  };
  // inter = setInterval(() => {
  //   Axios.get("http://54.208.64.193:3000/getLocation").then((res)=>{
  //   this.setState({mapdata:{lat:res.data.latitude,long:res.data.longitude,zoom:14}})
  //   })
  //  }, 2000);
  render() {
    const { data } = this.props;
    return (
      <div
        style={{
          padding: 30,
          width: "100%",
          overflowY: "scroll",
          overflowX: "scroll",
          backgroundColor:"#EBEBEB"
        }}
      >
        {data ? (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <div
                style={{
                  padding: 10
                }}
              >
                {this.renderFirstCol()}
              </div>
              <div
                className="container2 midle"
                style={{
                  height: "789px",
                  padding: 10,
                  overflowY: "scroll",
                  width: "100%"
                }}
              >
                {this.renderSecondCol()}
              </div>
              <div style={{ padding: 10 }}>{this.renderThirdCol()}</div>
            </div>
            <div
              className="container2 whole"
              style={{
                height: "789px",
                padding: 10,
                overflowY: "scroll",
                width: "100%",
                paddingTop: 20
              }}
            >
              {this.renderSecondCol()}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Avatar
              size="large"
              style={{
                width: 110,
                height: 90,
                marginTop: "20%",
                opacity: 0.55
              }}
              src={Images.NO_DATA}
            />
            <h1
              style={{
                color: colors.black,
                fontSize: sizes.h2,
                fontWeight: 300,
                opacity: 0.4
              }}
            >
              No Trips Found for selected Filters
            </h1>
          </div>
        )}
      </div>
    );
  }
}
export default FullInfoViewLayout;
FullInfoViewLayout.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object
};
FullInfoViewLayout.defaultProps = {
  type: "Trips",
  data: {}
};
