import React, { Component } from "react";
import { Row, Avatar, Col, Icon, Divider } from "antd";
import { textColor, sizes } from "../../constants/constants";
import { Images } from "../../public/asset/index";

class BrokerInfoCard extends Component {
  render() {
    const data = this.props.data;
    return (
      <div style={{ minWidth: 310, maxWidth: 310 }}>
        <h2 style={{ color: textColor.text_light, fontSize: sizes.h1 }}>
          Details
        </h2>
        <div
          className="ant-card"
          style={{
            padding: 25,
            minHeight: 600,
            borderColor: "rgba(0,0,0,0.15)",
            borderWidth: 1,
            borderStyle: "groove"
          }}
        >
          <Row>
            <div
              style={{
                width: "100%",
                display: "grid",
                justifyContent: "center"
              }}
            >
              <Avatar
                style={{ width: 120, height: 120 }}
                src={
                  data.profilePicture ? data.profilePicture : Images.ICON_ONE
                }
              />
            </div>
          </Row>
          <Divider orientation="left">
            <span
              style={{
                fontSize: sizes.h5,
                color: textColor.text_light,
                marginBottom: 10,
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: 130,
                height: "1.32em",
                whiteSpace: "nowrap"
              }}
            >
              Information
            </span>
          </Divider>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="phone" style={{ marginRight: 5 }} /> Phone Number:
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: textColor.text_light }}
                >
                  {data.phoneNumber ? data.phoneNumber : "Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="database" style={{ marginRight: 5 }} /> CNIC No.
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: textColor.text_light }}
                >
                  {data.cnicNo ? data.cnicNo : "Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="database" style={{ marginRight: 5 }} /> Company
                  Name.
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: textColor.text_light }}
                >
                  {data.companyName ? data.companyName : "Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="environment" style={{ marginRight: 5 }} />{" "}
                  Address.
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: textColor.text_light }}
                >
                  {data.address
                    ? data.address.street + " "
                    : "Street : Not Assigned "}
                  {data.address ? " " + data.address.city : "City : Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}
export default BrokerInfoCard;
