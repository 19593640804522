import { createApiActionTypes } from "../../../../constants/constants";

let main = "DRIVERS";

export const SET_DRIVER_MODAL_VISIBLE = `${main}/SET_DRIVER_MODAL_VISIBLE`;


export const ON_DRIVER_ADD_REQUEST = createApiActionTypes(main, "ON_DRIVER_ADD_REQUEST");
export const ON_DRIVER_EDIT_REQUEST = createApiActionTypes(main, "ON_DRIVER_EDIT_REQUEST");
export const ON_GET_CONNECT_DRIVER_INFO = createApiActionTypes(main, "ON_GET_CONNECT_DRIVER_INFO");
export const ON_CONNECT_DRIVER = createApiActionTypes(main, "ON_CONNECT_DRIVER");
export const ON_CONNECT_DRIVER_LOCAL = createApiActionTypes(main, "ON_CONNECT_DRIVER_LOCAL");

export const ON_DISCONNECT_DRIVER = createApiActionTypes(main, "ON_DISCONNECT_DRIVER");





