import React, { Component } from 'react';
import { Col, Icon, Divider } from 'antd';
import { textColor, sizes } from '../../constants/constants';
import { Images } from '../../public/asset/index';
// import VehicleInfo from './vehicleInfo';
import moment from "moment"
class TripRequestInfoCard extends Component {
  render() {
    const { data }= this.props
    return (
      <div style={{ minWidth: 330, maxWidth: 330 }}>
        <h2 style={{ color: textColor.text_light, fontSize: sizes.h1 }}>
          Trip Request Information
        </h2>
        <div
          className="ant-card"
          style={{
            padding: 25,
            minHeight: 100,
            borderColor: 'rgba(0,0,0,0.15)',
            borderWidth: 1,
            borderStyle: 'groove',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Col span={12}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
               
                <span
                  style={{
                    fontSize: sizes.h3,
                    color: textColor.text_midle,
                    marginBottom: 10,
                  }}
                >
                  <Icon type="calendar" style={{ marginRight: 4 }} />
                  {' '}
                    Picup
                  Date:
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <img
                    style={{
                      marginTop: 3,
                      marginRight: 5,
                      color: textColor.text_midle,
                    }}
                    alt=""
                    height={50}
                    src={Images.LINER}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span
                    style={{
                      fontSize: sizes.h3,
                      color: textColor.text_midle,
                      marginBottom: 10,
                    }}
                  >

                    Pickup:
                  </span>
                  <span
                    style={{
                      fontSize: sizes.h3,
                      color: textColor.text_midle,
                      marginBottom: 10,
                    }}
                  >

                    Drop-off:
                  </span>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
              
                <span
                  style={{
                    fontSize: sizes.h2,
                    color: textColor.text_light,
                    marginBottom: 13,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: 130,
                    height: '1.32em',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {data?(data.pickupDate?moment(data.pickupDate).format("L"):"N/A"):"N/A"}

                </span>
                <span
                  style={{
                    fontSize: sizes.h3,
                    color: textColor.text_light,
                    marginBottom: 10,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: 130,
                    height: '1.32em',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {data?data.pickupLocation.street+" "+(data.pickupLocation.city):"N/A"}
                </span>
                <span
                  style={{
                    fontSize: sizes.h3,
                    color: textColor.text_light,
                    marginBottom: 10,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: 130,
                    height: '1.32em',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {data?data.dropoffLocation.street+" "+data.dropoffLocation.city:"N/A"}

                </span>
              </div>
            </Col>
          </div>
          <Divider orientation="left">
            <span
              style={{
                fontSize: sizes.h5,
                color: textColor.text_light,
                marginBottom: 10,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 130,
                height: '1.32em',
                whiteSpace: 'nowrap',
              }}
            >

              Goods Detail
            </span>
          </Divider>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Col span={12}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span
                  style={{
                    fontSize: sizes.h3,
                    color: textColor.text_midle,
                    marginBottom: 10,
                  }}
                >
                  <Icon type="windows" style={{ marginRight: 4 }} />
                  {' '}
                  Type:
                </span>
                <span
                  style={{
                    fontSize: sizes.h3,
                    color: textColor.text_midle,
                    marginBottom: 10,
                  }}
                >
                  <Icon type="lock" style={{ marginRight: 4 }} />
                  {' '}
                Weight:
                </span>
                <span
                  style={{
                    fontSize: sizes.h3,
                    color: textColor.text_midle,
                    marginBottom: 10,
                  }}
                >
                  <Icon type="dollar" style={{ marginRight: 4 }} />
                  {' '}
                    Ammount:
                </span>
                <span
                  style={{
                    fontSize: sizes.h3,
                    color: textColor.text_midle,
                    marginBottom: 10,
                  }}
                >
                  <Icon type="bars" style={{ marginRight: 4 }} />
                  {' '}
                   Detail:
                </span>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span
                  style={{
                    fontSize: sizes.h3,
                    color: textColor.text_light,
                    marginBottom: 10,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: 130,
                    height: '1.32em',
                    whiteSpace: 'nowrap',
                  }}
                >

                  {data?(data.goodsTypesInformation?data.goodsTypesInformation.map((val,ind)=>{return val.name+", "}):"N/A"):"N/A"}
                 
                </span>
                <span
                  style={{
                    fontSize: sizes.h2,
                    color: textColor.text_light,
                    marginBottom: 13,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: 130,
                    height: '1.32em',
                    whiteSpace: 'nowrap',
                  }}
                >

                  {data?(data.goodsInformation?(data.goodsInformation.weight?data.goodsInformation.weight.quantity:"N/A"):"N/A")+" "+(data.goodsInformation?(data.goodsInformation.weight?data.goodsInformation.weight.unit:"N/A"):"N/A"):"N/A"}
                  
                </span>
                <span
                  style={{
                    fontSize: sizes.h3,
                    color: textColor.text_light,
                    marginBottom: 10,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: 130,
                    height: '1.32em',
                    whiteSpace: 'nowrap',
                  }}
                >

                  {data?(data.totalPayment?data.totalPayment:"N/A"):"N/A"}
                </span>
                <span
                  style={{
                    fontSize: sizes.h3,
                    color: textColor.text_light,
                    marginBottom: 10,
                  }}
                >

                  {data?(data.vehicleRequestInformation?data.vehicleRequestInformation.additionalDescription:"Not Assigned"):"N/A"}
                </span>
              </div>
            </Col>
          </div>
          {/* <Divider orientation="left">
            <span
              style={{
                fontSize: sizes.h5,
                color: textColor.text_light,
                marginBottom: 10,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 130,
                height: '1.32em',
                whiteSpace: 'nowrap',
              }}
            >
              Vehicles
            </span>
          </Divider>
          <div
            style={{ overflowY: 'scroll', overflowX: 'hidden', height: 200 }}
          >
            {data?(data.assignedTo?data.assignedTo.map((val,ind)=>{
              return  <VehicleInfo data={val} key={ind} />
            }):"N/A"):"N/A"}
          </div> */}
        </div>
      </div>
    );
  } 
}
export default TripRequestInfoCard;
