import React, { Component } from "react";
import {
  Modal,
  Col,
  Form,
  Select,
  Input,
  Button,
  Table,
  Row,
  Popconfirm,
  message
} from "antd";
import { connect } from "react-redux";
import * as Actions from "../../../modules/loggedin/clientmanagement/action/actionCreater";
import * as ActionsGlobal from "../../../modules/loggedin/sidebar/action/actionCreater";
import * as ActionsDetail from "../../../modules/loggedin/detail/action/actionCreater";
import ModalCompoentLeftComtent from "../../modalComponentLeftContent/modalComponentLeftViewContent";
import { Images } from "../../../public/asset/index";
import ApnaDivider from "../../apnaDivider/apnaDivider";
import { sizes, textColor } from "../../../constants/constants";
import UploadSingleImage from "../../uploadImage/uploadImageSingle";
import cities from "../../../constants/jsons/cities.json";
import moment from "moment";
const Option = Select.Option;
const childrenCities = [];
childrenCities.length = 0;
if (cities["Pakistan"] === undefined) {
} else {
  cities["Pakistan"].forEach(element => {
    childrenCities.push(<Option key={element}>{element}</Option>);
  });
}

class ClientModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // this is data for Contact persons
      CPdata: [],
      // this is the data for loading points
      LPdata: [],
      // these are for Loading points state management
      LPaddress: "",
      LPcity: "",
      // these are for Contact Persons state management
      CPname: "",
      CPmobile: "",
      CPemail: "",
      CPdesc: "",

      count1: 1,
      count2: 1,
      profilePic: false,
      picArra:["https://elasticbeanstalk-us-east-1-435177095658.s3.amazonaws.com/02796750-6e5f-11e9-9a6e-93292be1f397","https://elasticbeanstalk-us-east-1-435177095658.s3.amazonaws.com/597c7d30-6e5f-11e9-9a6e-93292be1f397","https://elasticbeanstalk-us-east-1-435177095658.s3.amazonaws.com/3b8d1140-6e5f-11e9-9a6e-93292be1f397",,"https://elasticbeanstalk-us-east-1-435177095658.s3.amazonaws.com/1d6ea610-6e5f-11e9-9a6e-93292be1f397",]
    };

    // these are the coulms of Loading Points
    this.LPcolumns = [
      {
        title: (
          <span style={{ color: textColor.text_light, fontSize: sizes.h3 }}>
            Address
          </span>
        ),
        dataIndex: "address"
      },
      {
        title: (
          <span style={{ color: textColor.text_light, fontSize: sizes.h3 }}>
            City
          </span>
        ),
        dataIndex: "city"
      },
      {
        title: "",
        dataIndex: "",
        key: "x",
        render: (text, record) =>
          this.state.LPdata.length >= 1 ? (
            <Popconfirm
              title="Sure to remove?"
              onConfirm={() => this.handleDeleteLP(record.key)}
            >
              <Button
                shape="circle"
                size="small"
                icon="cross"
                type="ghost"
                style={{ color: "red", borderColor: "red" }}
              />
            </Popconfirm>
          ) : null
      }
    ];

    // these are the coulms of contact persons
    this.CPcolumns = [
      {
        title: (
          <span style={{ color: textColor.text_light, fontSize: sizes.h3 }}>
            Name
          </span>
        ),
        dataIndex: "name"
      },
      {
        title: (
          <span style={{ color: textColor.text_light, fontSize: sizes.h3 }}>
            Mobile
          </span>
        ),
        dataIndex: "mobile"
      },
      {
        title: (
          <span style={{ color: textColor.text_light, fontSize: sizes.h3 }}>
            Email
          </span>
        ),
        dataIndex: "email"
      },
      {
        title: (
          <span style={{ color: textColor.text_light, fontSize: sizes.h3 }}>
            Designation
          </span>
        ),
        dataIndex: "des"
      },
      {
        title: "",
        dataIndex: "",
        render: (text, record) =>
          this.state.CPdata.length >= 1 ? (
            <Popconfirm
              title="Sure to remove?"
              onConfirm={() => this.handleDeleteCP(record.key)}
            >
              <Button
                shape="circle"
                size="small"
                icon="cross"
                type="ghost"
                style={{ color: "red", borderColor: "red" }}
              />
            </Popconfirm>
          ) : null
      }
    ];
  }
  getImageUrlFromChild = imageUrl => {
    if (imageUrl === false) {
      this.setState({ profilePic: false });
    } else {
      this.setState({ profilePic: imageUrl });
    }
  };
  // this will delete/Remove Contact persons
  handleDeleteCP = key => {
    const dataSource = [...this.state.CPdata];
    this.setState({ CPdata: dataSource.filter(item => item.key !== key) });
  };

  // this will delete loading points
  handleDeleteLP = key => {
    const dataSource = [...this.state.LPdata];
    this.setState({ LPdata: dataSource.filter(item => item.key !== key) });
  };

  // this will handle add Contact persons
  handleAddCP = key => {
    if (
      this.state.CPname !== "" &&
      this.state.CPname !== undefined &&
      this.state.CPmobile !== "" &&
      this.state.CPmobile !== undefined
    ) {
      const newData = {
        key: this.state.count1,
        name: this.state.CPname,
        mobile: this.state.CPmobile,
        email: this.state.CPemail,
        des: this.state.CPdesc
      };
      this.setState({
        CPdata: [...this.state.CPdata, newData],
        count1: this.state.count1 + 1,
        CPname: "",
        CPmobile: "",
        CPemail: "",
        CPdesc: ""
      });
    } else {
      message.error("You have to Enter Name and Mobile Information");
    }
  };
  //  this will handle add Loading points
  handleAddLP = key => {
    if (
      this.state.LPaddress !== "" &&
      this.state.LPaddress !== undefined &&
      this.state.LPcity !== "" &&
      this.state.LPcity !== undefined
    ) {
      const newData = {
        key: this.state.count2,
        address: this.state.LPaddress,
        city: this.state.LPcity
      };
      this.setState({
        LPdata: [...this.state.LPdata, newData],
        count2: this.state.count2 + 1,
        LPaddress: "",
        LPcity: ""
      });
    } else {
      message.error("You have to Enter Both Information");
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        let pLocArray = [];
        let CPArray = [];
        console.log(this.state.CPdata);
        this.state.LPdata.map((val, ind) => {
          pLocArray.push({
            street: val.address,
            city: val.city
          });
        });
        this.state.CPdata.map((val, ind) => {
          CPArray.push({
            name: val.name, //this field should be string , optional and should be between 3 to 50 character long
            mobileNumber: val.mobile, // this field should be number , optional
            email: val.email ? val.email : null, // this field should be email , optional
            designation: val.des ? val.des : null // this field is string , optional
          });
        });
        const {
          onClientAddRequest,
          userInfo,
          onChangeClientModalVisible,
          onAddClientPush,
          onGetLogData,
          onClientEditRequest,
          onAllClientsInfoGet,
          onChangeNavName,
          onGetDetailClientData
        } = this.props;

        if (userInfo !== {}) {
          const data = {
            name: values.fullName, //this field should be string , required and should be between 3 to 50 character long
            primaryNumber: "+92" + values.mobileNumber, // this field should be number , required
            secondaryNumber: null, // this field should be number , optional
            email: values.email ? values.email : null, //this field is required and should be email
            type: "Guest", // this field is required and should be string of "Guest" or "Saved"
            address: {
              street: values.streetAddress ? values.streetAddress : null,
              city: values.city ? values.city : null
            }, // this field is optional and should be object of key street and city
            preferedLocations: this.state.LPdata.length > 0 ? pLocArray : null, // this field is optional and should be array of objects
            profilePicture: this.state.profilePic
              ? this.state.profilePic
              : this.state.picArra[Math.floor(Math.random() * Math.floor(3))], //this field is optional and should be a url
            poc: this.state.CPdata.length > 0 ? CPArray : null, // poc should be array of object and optional
            accountCreatedDate: moment().toISOString(), // this field is required and should be iso date,
            userId: userInfo.user._id // this field is required and should be mongoose id
          };

          const data2 = {
            clientId: this.props.data ? this.props.data._id : "",
            name: values.fullName, //this field should be string , required and should be between 3 to 50 character long
            primaryNumber: "+92" + values.mobileNumber, // this field should be number , required
            secondaryNumber: null, // this field should be number , optional
            email: values.email ? values.email : null, //this field is required and should be email
            type: "Guest", // this field is required and should be string of "Guest" or "Saved"
            address: {
              street: values.streetAddress ? values.streetAddress : null,
              city: values.city ? values.city : null
            }, // this field is optional and should be object of key street and city
            preferedLocations: this.state.LPdata.length > 0 ? pLocArray : null, // this field is optional and should be array of objects
            profilePicture: this.state.profilePic
              ? this.state.profilePic
              : this.state.picArra[Math.floor(Math.random() * Math.floor(3))], //this field is optional and should be a url
            poc: this.state.CPdata.length > 0 ? CPArray : null, // poc should be array of object and optional
            userId: userInfo.user._id // this field is required and should be mongoose id
          };

          if (this.props.data) {
            const hello2 = async () => {
              let response = await onClientEditRequest(data2);
              if (response.payload.data !== "") {
                onChangeClientModalVisible(false);
                message.success("Client Updated");
                onChangeNavName({
                  first: "Vehicles",
                  second: "Detail",
                  third: values.fullName
                });
                onAllClientsInfoGet({ id: userInfo.user._id });
                onGetDetailClientData({
                  id: this.props.data._id,
                  userId: userInfo.user._id
                });
              }
            };
            hello2();
          } else {
            const hello = async () => {
              let response = await onClientAddRequest(data);
              if (response.payload.data !== "") {
                onChangeClientModalVisible(false);
                onAddClientPush(response.payload.data);
                onGetLogData({ id: userInfo.user._id });
                message.success("Client Added");
              }
            };
            hello();
          }
        }
      }
    });
  };
  render() {
    const { CPdata, LPdata } = this.state;
    const {
      clientModalVisible,
      onChangeClientModalVisible,
      loading
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title={null}
        footer={null}
        header={null}
        visible={clientModalVisible}
        onOk={() => {
          alert("OK");
        }}
        maskClosable={false}
        onCancel={() => {
          onChangeClientModalVisible(false);
        }}
        width={900}
        bodyStyle={{ padding: 0, borderRadius: 0 }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            className="hideSideModal"
            style={{ height: 550, width: 400, backgroundColor: "#8665c8" }}
          >
            <ModalCompoentLeftComtent
              mainHeading={"Add Client"}
              extraText={"Add Client Details"}
              Image={Images.DRIVER}
            />
          </div>
          <div
            style={{
              width: "100%",
              height: 550,
              overflowY: "scroll",
              paddingTop: 45,
              paddingBottom: 45,
              paddingLeft: 45,
              paddingRight: 45
            }}
          >
            <Form onSubmit={this.handleSubmit} spellCheck={true}>
              <ApnaDivider text={"Personal Information"} />

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "70%"
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Col span={9} style={{ paddingTop: 5 }}>
                      <span
                        style={{
                          fontSize: sizes.h3,
                          color: textColor.text_dark
                        }}
                      >
                        * Name :
                      </span>
                    </Col>
                    <Col span={15}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Form.Item style={{ lineHeight: "15px" }}>
                          {getFieldDecorator("fullName", {
                            rules: [
                              {
                                required: true,
                                message: "Please Enter Name",
                                min: 3,
                                max: 50
                              }
                            ]
                          })(
                            <Input
                              style={{
                                width: 230,
                                paddingRight: 10,
                                marginLeft: -8
                              }}
                              placeholder="Enter Full Name"
                              type="text"
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Col span={9} style={{ paddingTop: 5 }}>
                      <span
                        style={{
                          fontSize: sizes.h3,
                          color: textColor.text_dark
                        }}
                      >
                        * Mobile :
                      </span>
                    </Col>
                    <Col span={15}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Input.Group>
                          <Col span={6}>
                            <Input
                              style={{
                                borderRadius: 0,
                                marginLeft: -8
                              }}
                              defaultValue="+92"
                              disabled
                            />
                          </Col>
                          <Col span={18}>
                            <Form.Item>
                              {getFieldDecorator("mobileNumber", {
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Enter Mobile No"
                                  }
                                ]
                              })(
                                <Input
                                  pattern="^()-{0,1}3{1}\d{2}-{0,1}\d{7}$|^0{0,1}3{1}\d{10}$|^0{0,1}3{1}\d{2}-\d{7}$"
                                  style={{
                                    width: 180,
                                    marginLeft: -16,
                                    borderRadius: 0
                                  }}
                                  placeholder="Enter Mobile No"
                                  type="text"
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Input.Group>
                      </div>
                    </Col>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "30%",
                    paddingLeft: 30
                  }}
                >
                  <UploadSingleImage
                    getImageUrlFromChild={this.getImageUrlFromChild}
                    profile={this.state.profilePic}
                  />
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Col span={6} style={{ paddingTop: 5 }}>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_dark }}
                  >
                    Email :
                  </span>
                </Col>
                <Col span={18}>
                  <Form.Item>
                    {getFieldDecorator("email", {})(
                      <Input
                        pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                        style={{ width: "100%", marginRight: 10 }}
                        placeholder="Enter Email (Optional)"
                        type="text"
                      />
                    )}
                  </Form.Item>
                </Col>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Col span={6} style={{ paddingTop: 5 }}>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_dark }}
                  >
                    Address :
                  </span>
                </Col>
                <Col span={18}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item>
                      {getFieldDecorator("streetAddress", {})(
                        <Input
                          style={{ width: 230, marginRight: 10 }}
                          placeholder="Enter Street Address (Optional)"
                          type="text"
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("city", {})(
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: 154, marginLeft: 10 }}
                          placeholder="Select City"
                        >
                          {childrenCities}
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </div>
              <ApnaDivider text={"Loading Points"} />
              <Row
                style={{
                  marginBottom: 20,
                  height: "200px",
                  overflow: "scroll"
                }}
              >
                <Col span={24}>
                  <Table
                    pagination={false}
                    columns={this.LPcolumns}
                    dataSource={LPdata}
                    size="middle"
                  />
                </Col>
              </Row>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 20
                }}
              >
                <Input
                  style={{ width: 300, marginRight: 10 }}
                  placeholder="Enter Street Address"
                  type="text"
                  value={this.state.LPaddress}
                  onChange={e => {
                    this.setState({ LPaddress: e.target.value });
                  }}
                />

                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: 154, marginLeft: 10 }}
                  placeholder="Select City"
                  value={this.state.LPcity}
                  onChange={value => {
                    this.setState({ LPcity: value });
                  }}
                >
                  {childrenCities}
                </Select>
                <Button
                  icon="plus"
                  type="primary"
                  size="small"
                  shape="circle"
                  style={{ marginLeft: 20, marginTop: 5 }}
                  onClick={this.handleAddLP}
                />
              </div>

              <ApnaDivider text={"Contact persons"} />
              <Row style={{ marginBottom: 20 }}>
                <Col span={24}>
                  <Table
                    pagination={false}
                    columns={this.CPcolumns}
                    dataSource={CPdata}
                    size="default"
                  />
                </Col>
              </Row>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 20
                }}
              >
                <Input
                  style={{ width: 120, marginRight: 5 }}
                  placeholder="Name"
                  type="text"
                  onChange={e => {
                    this.setState({ CPname: e.target.value });
                  }}
                  value={this.state.CPname}
                />
                <Input
                  style={{ width: 120, marginRight: 5 }}
                  placeholder="Mobile"
                  type="text"
                  onChange={e => {
                    this.setState({ CPmobile: e.target.value });
                  }}
                  value={this.state.CPmobile}
                />
                <Input
                  style={{ width: 120, marginRight: 5 }}
                  placeholder="Email"
                  type="text"
                  onChange={e => {
                    this.setState({ CPemail: e.target.value });
                  }}
                  value={this.state.CPemail}
                />

                <Input
                  style={{ width: 120, marginRight: 5 }}
                  placeholder="Designation"
                  type="text"
                  onChange={e => {
                    this.setState({ CPdesc: e.target.value });
                  }}
                  value={this.state.CPdesc}
                />
                <Button
                  icon="plus"
                  type="primary"
                  shape="circle"
                  size="small"
                  style={{ marginLeft: 10, marginTop: 5 }}
                  onClick={this.handleAddCP}
                />
              </div>
              <div>
                <div style={{ float: "right" }}>
                  <Button
                    type="default"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      onChangeClientModalVisible(false);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  }

  componentWillMount() {
    const { data } = this.props;
    if (data) {
      let arr1 = [];
      let count1 = 1;
      if (data.poc !== null && data.poc !== "" && data.poc !== undefined) {
        data.poc.map((val, ind) => {
          arr1.push({
            key: count1,
            name: val.name,
            mobile: val.mobileNumber,
            email: val.email,
            des: val.designation
          });
          count1 = count1 + 1;
        });
        this.setState({ CPdata: arr1, count1: count1 });
      }

      if (
        data.preferedLocations !== null &&
        data.preferedLocations !== undefined &&
        data.preferedLocations !== ""
      ) {
        let arr2 = [];
        let count2 = 1;
        data.preferedLocations.map((val, ind) => {
          arr2.push({
            key: count2,
            address: val.street,
            city: val.city
          });
          count2 = count2 + 1;
        });
        this.setState({ LPdata: arr2, count2: count2 });
      }
    }
  }

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      this.setState({ profilePic: data.profilePicture });
      this.props.form.setFields({
        fullName: {
          value: this.props.data.name ? this.props.data.name : ""
        },
        mobileNumber: {
          value: this.props.data.primaryNumber
            ? this.props.data.primaryNumber.substring(3)
            : ""
        },
        email: {
          value: this.props.data.email ? this.props.data.email : ""
        },
        streetAddress: {
          value: this.props.data.address ? this.props.data.address.street : ""
        },
        city: {
          value: this.props.data.address ? this.props.data.address.city : ""
        }
      });
    }
  }
}
const ClientModal = Form.create()(ClientModalForm);
const mapStateToProps = ({ clients, signup }) => ({
  clientModalVisible: clients.clientModalVisible,
  userInfo: signup.content,
  loading: clients.loading
});
const mapDispatchToProps = dispatch => ({
  onChangeClientModalVisible: visible =>
    dispatch(Actions.ON_CHANGE_CLIENT_MODAL_VISIBLE(visible)),
  onClientAddRequest: data => dispatch(Actions.onClientAddRequest(data)),
  onAddClientPush: data =>
    dispatch(ActionsGlobal.ON_CLIENT_ADDED_PUSH_TO_ALL(data)),
  onGetLogData: data => dispatch(ActionsGlobal.onGetLogData(data)),

  onClientEditRequest: data => dispatch(Actions.onClientEditRequest(data)),
  onAllClientsInfoGet: data =>
    dispatch(ActionsGlobal.onAllClientsInfoGet(data)),
  onChangeNavName: navName =>
    dispatch(ActionsGlobal.ON_CHANGE_NAV_NAME(navName)),
  onGetDetailClientData: data =>
    dispatch(ActionsDetail.onGetDetailClientData(data))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientModal);
