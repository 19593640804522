import {
  ON_TRIPS_DETAIL_GET_REQUEST,
  ON_TRIP_STATUS_CHANGE,
  SET_TRIP_UPON_PUSH_TO_ARRAY,
  ON_TRIPS_GET_REQUEST,
  SET_DETAIL_DATA_FALSE,
  ON_TRIPS_GET_REQUEST_SEARCH,
  SET_TRIP_REQUEST_PUSH_TO_ARRAY,
  ON_TRIPS_REQUEST_GET,
  ON_TRIPS_REQUEST_DETAIL_GET,
  ON_TRIPS_REQUEST_GET_SEARCH,
  ON_TRIP_REQUEST_CHANGE_TO_TRIP
} from "./actionType";

export const onGetDetailTripData = data => dispatch =>
  dispatch({
    types: ON_TRIPS_DETAIL_GET_REQUEST,
    payload: {
      client: "default",
      request: {
        url:
          "/trip/single?id=" +
          data.id +
          "&userId=" +
          data.userId +
          "&accountType=Transporter",
        method: "get"
      }
    }
  });

  export const onGetDetailTripRequestData = data => dispatch =>
  dispatch({
    types: ON_TRIPS_REQUEST_DETAIL_GET,
    payload: {
      client: "default",
      request: {
        url:"/trip/request/single?id="+data.id+"&userId="+data.userId+"&accountType=Transporter",
        method: "get"
      }
    }
  });
  

export const onGetTripArrayDataSearch = data => dispatch =>
  dispatch({
    types: ON_TRIPS_GET_REQUEST_SEARCH,
    payload: {
      client: "default",
      request: {
        url: "/trip/search/accepted",
        method: "post",
        data: {
          ...data
        }
      }
    }
  });

  export const onGetTripRequestArrayDataSearch = data => dispatch =>
  dispatch({
    types: ON_TRIPS_REQUEST_GET_SEARCH,
    payload: {
      client: "default",
      request: {
        url: "/trip/search/requested",
        method: "post",
        data: {
          ...data
        }
      }
    }
  });
  
export const onGetTripArrayData = data => dispatch =>
  dispatch({
    types: ON_TRIPS_GET_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/trip/search/accepted",
        method: "post",
        data: {
          ...data
        }
      }
    }
  });

  export const onGetTripRequestArrayData = data => dispatch =>
  dispatch({
    types: ON_TRIPS_REQUEST_GET,
    payload: {
      client: "default",
      request: {
        url: "trip/request?id=" + data.id+ "&skip=0&limit=500&accountType=Transporter&type=Market",
        method: "get",
        
      }
    }
  });
  
export const onChangeTripRequestToTrip = data => dispatch =>
  dispatch({
    types: ON_TRIP_REQUEST_CHANGE_TO_TRIP,
    payload: {
      client: "default",
      request: {
        url: "/general/trip",
        method: "post",
        data: {
          ...data
        }
      }
    }
  });

  export const onChangeTripStatus = data => dispatch =>
  dispatch({
    types: ON_TRIP_STATUS_CHANGE,
    payload: {
      client: "default",
      request: {
        url: "/trip/status",
        method: "put",
        data: {
          ...data
        }
      }
    }
  });
export const ON_TRIP_UPON_PUSH_TO_ARRAY = data => ({
  type: SET_TRIP_UPON_PUSH_TO_ARRAY,
  payload: data
});

export const ON_TRIP_REQUEST_PUSH_TO_ARRAY = data => ({
  type: SET_TRIP_REQUEST_PUSH_TO_ARRAY,
  payload: data
});

export const ON_SET_DETAIL_DATA_FALSE = data => ({
  type: SET_DETAIL_DATA_FALSE,
  payload: data
});
