import React from 'react';
import {
  Col, Row, Progress, Icon, Divider,
} from 'antd';
import PropTypes from 'prop-types';
import { textColor, sizes } from '../../constants/constants';

const ProfileStrength = () => (
  <div style={{ minWidth: 250, maxWidth: 250 }}>
    <h2 style={{ color: textColor.text_light, fontSize: sizes.h1 }}>


        Profile Strength
    </h2>
    <div className="ant-card" style={{ padding: 20 }}>
      <Row>
        <div
          style={{ width: '100%', display: 'grid', justifyContent: 'center' }}
        >
          <Progress
            type="circle"
            size="default"
            percent={35}
            width={130}
            strokeWidth={10}
            strokeLinecap="square"
          />
        </div>
      </Row>
      <Row style={{ textAlign: 'center', marginTop: 15 }}>
        <span style={{ color: textColor.text_midle, fontSize: sizes.h4 }}>


            This profile is 32% complete Following details are missing
        </span>
      </Row>
      <Divider />
      <Row style={{ marginBottom: 10 }}>
        <Col span={20}>
          <span style={{ fontSize: sizes.h4, color: textColor.text_midle }}>


              Profile Picture
          </span>
        </Col>
        <Col span={4} style={{ textAlign: 'end' }}>
          <Icon
            type="check"
            style={{ color: '#42f46e', fontSize: sizes.h3 }}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={20}>
          <span style={{ fontSize: sizes.h4, color: textColor.text_midle }}>


              Driving Licence
          </span>
        </Col>
        <Col span={4} style={{ textAlign: 'end' }}>
          <Icon
            type="check"
            style={{ color: '#42f46e', fontSize: sizes.h3 }}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={20}>
          <span style={{ fontSize: sizes.h4, color: textColor.text_midle }}>


              Email Address
          </span>
        </Col>
        <Col span={4} style={{ textAlign: 'end' }}>
          <Icon
            type="close"
            style={{ color: '#f44141', fontSize: sizes.h3 }}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={20}>
          <span style={{ fontSize: sizes.h4, color: textColor.text_midle }}>


              CNIC No
          </span>
        </Col>
        <Col span={4} style={{ textAlign: 'end' }}>
          <Icon
            type="close"
            style={{ color: '#f44141', fontSize: sizes.h3 }}
          />
        </Col>
      </Row>
    </div>
  </div>
);
export default ProfileStrength;
ProfileStrength.propTypes = {
  percent: PropTypes.string,
  data: PropTypes.object,
};
ProfileStrength.defaultProps = {
  percent: '40%',
  data: {},
};
