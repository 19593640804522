import { SET_CLIENT_MODAL_VISIBLE, ON_CLIENT_ADD_REQUEST ,ON_CLIENT_EDIT_REQUEST} from "../action/actionType";
import { message } from "antd";

export default (state = { clientModalVisible: false , loading:false, newClient:'' , err:'' }, action) => {
  switch (action.type) {
    case SET_CLIENT_MODAL_VISIBLE:
      return { ...state, clientModalVisible: action.payload,loading:false };

    case ON_CLIENT_ADD_REQUEST[0]:
      return { ...state, loading: true };
    case ON_CLIENT_ADD_REQUEST[1]:
      return { ...state, loading: false, newClient: action.payload.data,err:'' };
    case ON_CLIENT_ADD_REQUEST[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false, err:'some thing went wronng' };

    case ON_CLIENT_EDIT_REQUEST[0]:
      return { ...state, loading: true };
    case ON_CLIENT_EDIT_REQUEST[1]:
      return { ...state, loading: false, newClient: action.payload.data,err:'' };
    case ON_CLIENT_EDIT_REQUEST[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false, err:'some thing went wronng' };
    default:
      return state;
  }
};
