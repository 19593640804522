import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Layout, message } from "antd";
import { connect } from "react-redux";
import * as Actions from "../signup/action/actionCreater";
import { Images } from "../../public/asset";
const { Footer } = Layout;

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { SET_LOADING_FALSE } = this.props;
    SET_LOADING_FALSE(false);
  }

  handleSubmit = e => {
    const { onUserSignIn } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        const hello = async () => {
          let response = await onUserSignIn({
            emailOrPhone: values.username,
            password: values.password
          });
          if (response.payload.data !== "") {
            if (response.payload.data.user) {
              localStorage.setItem(
                "AUTH_TOKEN",
                response.payload.data.jwtToken
              );
              this.props.history.push("/loggedIn");
              message.success("You are Logged In");
              // window.location.reload()

            } else {
              localStorage.setItem(
                "AUTH_TOKEN",
                response.payload.data.jwtToken
              );
              this.props.history.push("/auth");
              message.success("You are Logged In");
            }
          }
        };
        hello();
      }
    });
  };
  render() {
    const { loading } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Layout>
        <div className="login_container">
          <div className="login_sub_container">
            <Row type="flex" justify="center" align="middle">
              <div className="login_text_container">
                <h3 className="login_text_container_heading" />
                <h6 className="login_text_container_description" />
              </div>
            </Row>
            <Row type="flex" justify="center" align="middle">
              <div className="login_authentication_container">
                <div style={{ textAlign: "center", padding: 15 }}>
                  <img src={Images.LOGO_WHITE} width={200} height={70} alt="" />
                </div>
                <Row>
                  <Col offset={3} span={18}>
                    <Form onSubmit={this.handleSubmit} spellCheck={true}>
                      <Form.Item>
                        {getFieldDecorator("username", {
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Email or Phone Number"
                            }
                          ]
                        })(
                          <Input
                            type="text"
                            size="large"
                            placeholder="Phone , email or username"
                          />
                        )}
                      </Form.Item>
                      <Form.Item>
                        {getFieldDecorator("password", {
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Password"
                            }
                          ]
                        })(
                          <Input.Password
                            size="large"
                            type="password"
                            placeholder="Password"
                          />
                        )}
                      </Form.Item>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loading}
                        disabled={loading}
                      >
                        Login
                      </Button>
                      <Button
                        size="large"
                        type="default"
                        htmlType="submit"
                        style={{
                          color: "#33abfb",
                          borderColor: "#33abfb",
                          marginTop: 22
                        }}
                        block
                        onClick={() => {
                          this.props.history.push("/signup");
                        }}
                      >
                        <div className="login_button_text">
                          Don't have account? Signup now
                        </div>
                      </Button>
                    </Form>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col offset={7}>
                    {/* <h4 className="login_forget_password">Forget Password?</h4> */}
                  </Col>
                </Row>
              </div>
            </Row>
            <Footer
              className="login_footer_main"
              style={{
                backgroundColor: "rgba(11,11,11,0.3)",
                position: "fixed",
                bottom: "0",
                width: "100%"
              }}
            >
              <div>
                <h4 className="login_footer_text">
                  Trukkr. All Rights Reserved © 2019 | Developed by{" "}
                  <b>Trukkr Team</b>
                </h4>
              </div>
            </Footer>
          </div>
        </div>
      </Layout>
    );
  }
}
const Login = Form.create()(LoginForm);
const mapStateToProps = ({ signup }) => ({
  loading: signup.loading,
  err: signup.err,
  success: signup.success
});
const mapDispatchToProps = dispatch => ({
  onUserSignIn: data => dispatch(Actions.onUserSignIn(data)),
  SET_LOADING_FALSE: data => dispatch(Actions.SET_LOADING_FALSE(data))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
