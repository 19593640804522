import { SET_VEHICLE_MODAL_VISIBLE, ON_VEHICLE_ADD_REQUEST ,ON_VEHICLE_EDIT_REQUEST} from "../action/actionType";
import { message } from "antd";

export default (state = { vehicleModalVisible: false, newVehicle:'' , loading : false , err:'' }, action) => {
  switch (action.type) {
    case SET_VEHICLE_MODAL_VISIBLE:
      return { ...state, vehicleModalVisible: action.payload,loading:false };

    case ON_VEHICLE_ADD_REQUEST[0]:
      return { ...state, loading: true };
    case ON_VEHICLE_ADD_REQUEST[1]:
      return { ...state, loading: false, newVehicle: action.payload.data };
    case ON_VEHICLE_ADD_REQUEST[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false, err:'Something went wrong' };

    case ON_VEHICLE_EDIT_REQUEST[0]:
      return { ...state, loading: true };
    case ON_VEHICLE_EDIT_REQUEST[1]:
      return { ...state, loading: false, newVehicle: action.payload.data };
    case ON_VEHICLE_EDIT_REQUEST[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false, err:'Something went wrong' };
    default:
      return state;
  }
};
