import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Icon,
  Input,
  Select,
  Radio,
  Spin,
  Avatar
} from "antd";
import { sizes, colors } from "../../../constants/constants";
import { connect } from "react-redux";
import { Images } from "../../../public/asset";
import VehicleCard from "../../../components/mainCard/vehicleCard";
import MapComponent from "../../../components/mapComponent/mapComponent";
import { googleMapKey } from "../../../constants/config";
import MainModal from "../../../components/modals/mainModal";
import * as Actions from "./action/actionCreater";
import * as ActionsGlobal from "../sidebar/action/actionCreater";

const Search = Input.Search;
const Option = Select.Option;
const InputGroup = Input.Group;
const ButtonGroup = Button.Group;

class Vehicles extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, isCard: false, allVehiclesState: [] };
  }

  renderListView = () => {
    const { onChangeDetailData, onChangeNavName } = this.props;
    const { isCard, loading } = this.state;
    if (isCard) {
      return (
        <Spin
          style={{ minHeight: "65vh" }}
          indicator={
            <Icon type="chrome" style={{ fontSize: sizes.headingLarge }} spin />
          }
          spinning={!loading}
        >
          <div
            // className="dashboardMain"
            style={{ height: "100%", overflowY: "scroll" ,marginRight:"3%",marginLeft:"3%",marginTop:"2%"}}
          >
          <Row type={"flex"} style={{justifyContent:"center"}}>
            {this.state.allVehiclesState.length !== 0 ? (
              this.state.allVehiclesState.map((value, key) => {
                return (
                  <Col
                    key={key}
                    style={{ padding: 10, minWidth: 350 }}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    xl={6}
                    xxl={6}
                    span={6}
                    onClick={() => {
                      onChangeDetailData({
                        type: "Vehicle",
                        id: value._id
                      });
                      onChangeNavName({
                        first: "Vehicles",
                        second: value.plateNo,
                      });
                      this.props.history.push("/loggedin/detail");
                    }}
                  >
                    <VehicleCard
                      id={value._id}
                      type={value.vehicleTypeInformation.title}
                      name={value.plateNo}
                      picture={value.vehiclePictures[0]}
                      status="Not Assigned"
                      driver={
                        value.driverInformation
                          ? value.driverInformation.name
                          : "Not Assigned"
                      }
                      location="Not Assigned"
                    />
                  </Col>
                );
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Avatar
                  size="large"
                  style={{
                    width: 110,
                    height: 90,
                    marginTop: "20%",
                    opacity: 0.55
                  }}
                  src={Images.NO_DATA}
                />
                <h1
                  style={{
                    color: colors.black,
                    fontSize: sizes.heading,
                    fontWeight: 340,
                    opacity: 0.5,
                    marginTop: 10
                  }}
                >
                  You Have No Vehicle added in Your Fleet
                </h1>
                <h1
                  style={{
                    color: colors.black,
                    fontSize: sizes.h2,
                    fontWeight: 300,
                    opacity: 0.4
                  }}
                >
                  Use add vehicle button above to add your first Vehicle
                </h1>
              </div>
            )}
            </Row>
          </div>
        </Spin>
      );
    }
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              minWidth: 330,
              maxWidth: 330
            }}
          >
            <div
              style={{
                height: window.innerHeight - 120,
                backgroundColor: "white",
                width: "100%",
                overflowY: "scroll",
                marginLeft: 5
              }}
            >
              {this.state.allVehiclesState.length !== 0 ? (
                this.state.allVehiclesState.map((value, key) => {
                  return (
                    <div
                      className="list-card-box"
                      key={key}
                      onClick={() => {
                        onChangeDetailData({
                          type: "Vehicle",
                          id: value._id
                        });
                        onChangeNavName({
                          first: "Vehicles",
                          second: value.plateNo,
                        });
                        this.props.history.push("/loggedin/detail");
                      }}
                    >
                      <VehicleCard
                        id={value._id}
                        type={
                          value.vehicleTypeInformation
                            ? value.vehicleTypeInformation.title
                            : "Not Assigned"
                        }
                        name={value.plateNo}
                        picture={value.vehiclePictures[0]}
                        status="Not Assigned"
                        driver={
                          value.driverInformation
                            ? value.driverInformation.name
                            : "Not Assigned"
                        }
                        location="Not Assigned"
                      />
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Avatar
                    size="large"
                    style={{
                      width: 110,
                      height: 90,
                      marginTop: "20%",
                      opacity: 0.55
                    }}
                    src={Images.NO_DATA}
                  />
                  <h1
                    style={{
                      color: colors.black,
                      fontSize: sizes.h1,
                      fontWeight: 340,
                      opacity: 0.5,
                      marginTop: 10
                    }}
                  >
                    You Have No Vehicle added in Your Fleet
                  </h1>
                  <h1
                    style={{
                      color: colors.black,
                      fontSize: sizes.h5,
                      fontWeight: 300,
                      opacity: 0.4
                    }}
                  >
                    Use add vehicle button above to add your first Vehicle
                  </h1>
                </div>
              )}
            </div>
          </div>
          <Col span={19}>
            <div style={{ height: window.innerHeight - 120, width: "101%" }}>
              <MapComponent markersArray={[]} latlng={false} height="100%" />
            </div>
          </Col>
        </div>
      </div>
    );
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.allVehicles !== this.props.allVehicles) {
      const { allVehicles } = nextProps;
      this.setState({ allVehiclesState: allVehicles });
    } else {
      const { allVehicles } = this.props;
      this.setState({ allVehiclesState: allVehicles });
    }
  }
  componentWillMount() {
    const { allVehicles } = this.props;
    this.setState({ allVehiclesState: allVehicles });
  }
  Search = text => {
    const { allVehicles } = this.props;
    if (allVehicles !== 0) {
      if (text === "" || text === undefined) {
        this.setState({ allVehiclesState: allVehicles });
      } else {
        this.setState({
          allVehiclesState: allVehicles.filter(
            val =>
              val.plateNo === text || val.vehicleTypeInformation.title === text
          )
        });
      }
    }
  };
  render() {
    const { vehicleModalVisible, onChangeVehicleModalVisible } = this.props;
    return (
      <div>
        <MainModal visible={vehicleModalVisible} modalType={"vehicle"} />
        <div className="ant-card" style={{ height: "100%" }}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              span={12}
              style={{ padding: 20 }}
            >
              <InputGroup compact style={{ marginTop: 8, marginLeft: 20 }}>
                <Select placeholder="Category" style={{ width: 120 }}>
                  <Option value="Driver">Driver</Option>
                  <Option value="Location">Location</Option>
                  <Option value="Vehicle">Vehicle</Option>
                </Select>
                <Search
                  style={{ width: 300 }}
                  placeholder="Search"
                  onChange={text => {
                    this.Search(text.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              span={12}
              style={{ padding: 20 }}
            >
              <div style={{ float: "right" }}>
                <Button
                  size="default"
                  type="primary"
                  icon="plus"
                  style={{ marginRight: 12 }}
                  onClick={() => onChangeVehicleModalVisible(true)}
                >
                  Add Vehicle
                </Button>
                <Radio.Group>
                  <span style={{ paddingRight: 10, fontSize: 16 }}>view :</span>
                  <ButtonGroup>
                    <Button
                      size="default"
                      type={this.state.isCard ? "primary" : "ghost"}
                      icon="credit-card"
                      onClick={() => {
                        this.setState({ isCard: true });
                      }}
                    >
                      Card
                    </Button>
                    <Button
                      size="default"
                      type={!this.state.isCard ? "primary" : "ghost"}
                      icon="area-chart"
                      onClick={() => {
                        this.setState({ isCard: false });
                      }}
                    >
                      List Map
                    </Button>
                  </ButtonGroup>
                </Radio.Group>
              </div>
            </Col>
          </Row>
        </div>
        {this.renderListView()}
      </div>
    );
  }
}
const mapStateToProps = ({ vehicles, global }) => ({
  vehicleModalVisible: vehicles.vehicleModalVisible,
  allVehicles: global.allVehicles
});
const mapDispatchToProps = dispatch => ({
  onChangeVehicleModalVisible: visible =>
    dispatch(Actions.ON_CHANGE_VEHICLE_MODAL_VISIBLE(visible)),
  onChangeDetailData: data =>
    dispatch(ActionsGlobal.ON_CHANGE_DETAIL_DATA(data)),
  onChangeNavName: navName =>
    dispatch(ActionsGlobal.ON_CHANGE_NAV_NAME(navName))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Vehicles);
