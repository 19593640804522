
import { ON_DRIVER_DETAIL_GET_REQUEST , ON_VEHICLE_DETAIL_GET_REQUEST , ON_CLIENT_DETAIL_GET_REQUEST ,ON_BROKER_DETAIL_GET_REQUEST , ON_DELETE_REQUEST_DVCB} from './actionTypes'

export const onGetDetailDriverData = data => dispatch =>
  dispatch({
    types: ON_DRIVER_DETAIL_GET_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/portal/driver/single?driverId="+data.id+"&userId="+data.userId,
        method: "get"
      }
    }
  });

export const onGetDetailVehicleData = data => dispatch =>
  dispatch({
    types: ON_VEHICLE_DETAIL_GET_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/vehicle/single?id="+data.id+"&userId="+data.userId+"&accountType=Transporter" ,
        method: "get"
      }
    }
  });

export const onGetDetailClientData = data => dispatch =>
  dispatch({
    types: ON_CLIENT_DETAIL_GET_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/portal/client/single?clientId=" + data.id+"&userId="+data.userId,
        method: "get"
      }
    }
  });

export const onGetDetailBrokerData = data => dispatch =>
  dispatch({
    types: ON_BROKER_DETAIL_GET_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/portal/broker/single?brokerId=" + data.id+"&userId="+data.userId,
        method: "get"
      }
    }
  });


// to delete
export const onDeleteDVCB = data => dispatch =>
  dispatch({
    types: ON_DELETE_REQUEST_DVCB,
    payload: {
      client: "default",
      request: {
        url: data.url,
        method: "delete",
        data: data.body
      },
    }
  });