import { ON_REPORTS_GET_REQUEST, ON_REPORT_UPDATE_REQUEST } from "./actionType";

export const onReportsGetRequest = data => dispatch =>
  dispatch({
    types: ON_REPORTS_GET_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/portal/trip/report",
        method: "post",
        data: data
      }
    }
  });
export const onReportUpdateRequest = data => dispatch =>
  dispatch({
    types: ON_REPORT_UPDATE_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/portal/trip/report",
        method: "put",
        data: data
      }
    }
  });
