import React, { Component } from 'react';
import {
  Row, Avatar, Col, Icon, Divider, Modal,
} from 'antd';
import { textColor, sizes, colors } from '../../constants/constants';
import { Images } from '../../public/asset/index';

class DriverInfoCard extends Component {
    constructor(props){
      super(props);
      this.state={
        previewVisible: false,
        pic: undefined,
      }
    }
  render() {
    const data = this.props.data
    return (
      <div style={{ minWidth: 310, maxWidth: 310 }}>
        <h2 style={{ color: textColor.text_light, fontSize: sizes.h1 }}>

          Details
        </h2>
        <div
          className="ant-card"
          style={{
            padding: 25,
            minHeight: 100,
            borderColor: 'rgba(0,0,0,0.15)',
            borderWidth: 1,
            borderStyle: 'groove',
          }}
        >
          <Row>
            <div
              style={{
                width: '100%',
                display: 'grid',
                justifyContent: 'center',
              }}
            >
              <Avatar
                style={{ width: 120, height: 120 }}
                src={data.profilePicture?data.profilePicture:Images.ICON_FOUR}
              />
            </div>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <div
              style={{
                width: '100%',
                display: 'grid',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: "100%",
                  padding: 7,
                  backgroundColor: "gray",
                  borderRadius: 5,
                  cursor:"pointer"
                }}
                
              >
                <span
                  style={{ fontSize: sizes.h4, color: "white", }}
                >

                  {data.name}
                </span>
              </div>
            </div>
          </Row>
          <Divider orientation="left">
            <span
              style={{
                fontSize: sizes.h5,
                color: textColor.text_light,
                marginBottom: 10,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 130,
                height: '1.32em',
                whiteSpace: 'nowrap',
              }}
            >

              Information
            </span>
          </Divider>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="environment" style={{ marginRight: 5 }} />
                  {' '}
                  Current
                  City:
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: textColor.text_light }}
                >
                  {data.currentLocation?data.currentLocation:"Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="car" style={{ marginRight: 5 }} />
                  {' '}
                  Assigned
                  Vehicle:
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: colors.primaryColor,cursor:"pointer" }}
                >

                  {data.vehicleInformation?data.vehicleInformation.plateNo:"No Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="phone" style={{ marginRight: 5 }} />
                  {' '}
              Phone No.
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: textColor.text_light }}
                >

                  {data.phoneNumber?data.phoneNumber:"Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="database" style={{ marginRight: 5 }} />
                  {' '}
                CNIC No.
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: textColor.text_light }}
                >

                  {data.cnicNo?data.cnicNo:"Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="book" style={{ marginRight: 5 }} />
                  {' '}
                License No.
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: textColor.text_light }}
                >

                  {data.licence?data.licence.number:"Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="book" style={{ marginRight: 5 }} />
                  {' '}
                Address.
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: textColor.text_light }}
                >

                  {data.address?data.address.street+" "+data.address.city:"Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="book" style={{ marginRight: 5 }} />
                  {' '}
                Connection Status.
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  {data.connectionStatus}
                  <span
                    style={{ fontSize: sizes.h4, color: colors.primaryColor,cursor:"pointer" }}
                    onClick={()=>{this.props.toConnect(data.connectionStatus)}}
                  >
                    {data.connectionStatus==="Connected"?" (Disconect)":" (Connect)"}
                  </span>
                </span>
               
              </Col>
            </div>
          </Row>
          <Divider orientation="left">
            <span
              style={{
                fontSize: sizes.h5,
                color: textColor.text_light,
                marginBottom: 10,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 130,
                height: '1.32em',
                whiteSpace: 'nowrap',
              }}
            >

              Personal Documents
            </span>
          </Divider>
          <Modal
                  visible={this.state.previewVisible}
                  footer={null}
                  onCancel={() => {
                    this.setState({ previewVisible: false });
                  }}
                >
                  <img
                    alt="example"
                    style={{ width: "100%", height: "100%" }}
                    src={this.state.pic}
                  />
                </Modal>
          <Row>
            {data.documentPictures&&data.documentPictures.length!==0?data.documentPictures.map((val,key)=>{
              return(
                <Col
                  key={key}
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  span={8}
                  style={{ padding: 8 }}
                >
                  <Avatar
                   onClick={() => {
                    this.setState({
                      previewVisible: true,
                      pic: val
                    });
                    }}
                    shape="square"
                    style={{ width: 70, height: 70 }}
                    src={val?val:Images.BACKGROUND_DARK}
                  />
                </Col>
              )
            }):"No Document Pictures"}
          </Row>
        </div>
      </div>
    );
  }
}
export default DriverInfoCard;
