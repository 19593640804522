import { SET_BROKER_MODAL_VISIBLE, ON_BROKER_ADD_REQUEST ,ON_BROKER_EDIT_REQUEST} from "../action/actionType";
import { message } from "antd";

export default (state = { brokerModalVisible: false, loading: true , err:'', newBroker:'' }, action) => {
  switch (action.type) {
    case SET_BROKER_MODAL_VISIBLE:
      return { ...state, brokerModalVisible: action.payload,loading:false };
    case ON_BROKER_ADD_REQUEST[0]:
      return { ...state, loading: true };
    case ON_BROKER_ADD_REQUEST[1]:
      return { ...state, loading: false, newBroker: action.payload.data,err:'' };
    case ON_BROKER_ADD_REQUEST[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false, err:'some thing went wronng' };


    case ON_BROKER_EDIT_REQUEST[0]:
      return { ...state, loading: true };
    case ON_BROKER_EDIT_REQUEST[1]:
      return { ...state, loading: false, newBroker: action.payload.data,err:'' };
    case ON_BROKER_EDIT_REQUEST[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false, err:'some thing went wronng' };
    default:
      return state;
  }
};
