/* eslint-disable array-callback-return */
import React from "react";
// import GoogleMapReact from "google-map-react";
import {
  MarkerWithLabel,
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import PropTypes from "prop-types";

import { compose, withProps,withStateHandlers } from "recompose";
// import { Icon } from "antd";
import { googleMapKey } from "../../constants/config";
import { Popover } from "antd";
const MyMapComponent = compose(
  // withStateHandlers(() => ({
  //   isOpen: false,
  // }), {
  //   onToggleOpen: ({ isOpen }) => () => ({
  //     isOpen: !isOpen,
  //   })
  // }),
  withProps(props=>({
    googleMapURL:
    "https://maps.googleapis.com/maps/api/js?key="+googleMapKey,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height:props.height }} />,
    mapElement: <div style={{ height: `100%` }} />
  })),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={12} 
    defaultCenter={{ lat: 24.79313, lng:67.104847 }}
  >
   
        {/* {props.markersArray.map((val,ind)=>{
          console.log('markersArray','lat :',val[0].lat,' ', 'long : ',val[0].long , 'name' ,val[0].name)
        })} */}
        {props.markersArray.map((val,ind)=>{
          return(
              <Popover  key={ind} placement="top" content={<div>
                <h3>{val[0].name}</h3>
              </div>} title={false} trigger="click">
                <Marker key={val[0].localId}  position={{ lat:val[0].long, lng: val[0].lat }} />
              </Popover>
          )
        })}

  </GoogleMap>
));


class MapComponent extends React.Component {
  constructor(props){
    super(props);
    this.state={
      // center:{lat:this.props.lat,lng:this.props.long}
    }
  }
  // componentWillReceiveProps(nextProps){
  //   if (this.props!==nextProps) {
  //     this.setState({center:{lat:nextProps.lat,lng:nextProps.long}})
  //   }
  // }
 
  render() {
    const { height,markersArray,latlng } = this.props;
    console.log('latlng',markersArray)
    return (
      <MyMapComponent isMarkerShown={true} height={height} markersArray={markersArray} latlng={latlng} />
    );
  }
}
export default MapComponent;
MapComponent.propTypes = {
  markersArray:PropTypes.array,
  latlng:PropTypes.object,
  height: PropTypes.string
};
MapComponent.defaultProps = {
  height: "230px"
};
