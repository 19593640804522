import React, { Component } from 'react';
import {
  Row, Avatar, Col, Icon, Divider, Modal,
} from 'antd';
import { textColor, sizes, colors } from '../../constants/constants';
import { Images } from '../../public/asset/index';

class VehicleInfoCard extends Component {
  constructor(props){
    super(props);
    this.state={
      previewVisible: false,
      pic: undefined,
    }
  }
  render() {
    const data = this.props.data
    return (
      <div style={{ minWidth: 310, maxWidth: 310 }}>
        <h2 style={{ color: textColor.text_light, fontSize: sizes.h1 }}>

          Details
        </h2>
        <div
          className="ant-card"
          style={{
            padding: 25,
            minHeight: 100,
            borderColor: 'rgba(0,0,0,0.15)',
            borderWidth: 1,
            borderStyle: 'groove',
          }}
        >
          <Row>
            <div
              style={{
                width: '100%',
                height: 200,
              }}
            >
              <Avatar
                shape="square"
                style={{ width: '100%', height: '100%' }}
                src={data.vehiclePictures?data.vehiclePictures[0]:Images.BACKGROUND_DARK}
              />
            </div>
          </Row>
          <Modal
                  visible={this.state.previewVisible}
                  footer={null}
                  onCancel={() => {
                    this.setState({ previewVisible: false });
                  }}
                >
                  <img
                    alt="example"
                    style={{ width: "100%", height: "100%" }}
                    src={this.state.pic}
                  />
                </Modal>
          <Row style={{ marginTop: 1 }}>
            <div>
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {data.vehiclePictures?data.vehiclePictures.map((val,ind)=>{
                  return(
                    <Col
                      key={ind}
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      span={6}
                      style={{ padding: 6 }}
                    >
                      <Avatar
                       onClick={() => {
                    this.setState({
                      previewVisible: true,
                      pic: val
                    });
                    }}
                        shape="square"
                        style={{ width: '100%', height: 45 }}
                        src={val?val:Images.BACKGROUND_DARK}
                      />
                    </Col>
                  )
                }):"No Pictures"}
                
                
              </Row>
            </div>
          </Row>
          <Divider orientation="left">
            <span
              style={{
                fontSize: sizes.h5,
                color: textColor.text_light,
                marginBottom: 10,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 130,
                height: '1.32em',
                whiteSpace: 'nowrap',
              }}
            >

              Information
            </span>
          </Divider>

          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="user" style={{ marginRight: 5 }} />
                  {' '}
                    Driver:
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: colors.primaryColor }}
                >

                  {data.driverInformation?data.driverInformation.name:"Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="car" style={{ marginRight: 5 }} />
                  {' '}
            Vehicle:
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: textColor.text_light }}
                >

                  {data.plateNo?data.plateNo:"Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="appstore" style={{ marginRight: 5 }} />
                  {' '}
                Category.
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: textColor.text_light }}
                >

                  {data.vehicleTypeInformation?data.vehicleTypeInformation.title:"Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="database" style={{ marginRight: 5 }} />
                  {' '}
Chassis
                  No.
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: textColor.text_light }}
                >
                  {data.chassisNumber?data.chassisNumber:"Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h5, color: textColor.text_midle }}
                >
                  <Icon type="setting" style={{ marginRight: 5 }} />
                  {' '}
Engine No.
                </span>
              </Col>
              <Col span={12}>
                <span
                  style={{ fontSize: sizes.h4, color: textColor.text_light }}
                >

                  {data.engineNumber?data.engineNumber:"Not Assigned"}
                </span>
              </Col>
            </div>
          </Row>
          <Divider orientation="left">
            <span
              style={{
                fontSize: sizes.h5,
                color: textColor.text_light,
                marginBottom: 10,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 130,
                height: '1.32em',
                whiteSpace: 'nowrap',
              }}
            >

              Truck Documents
            </span>
          </Divider>
          <Row>
            {data.documentPictures?data.documentPictures.map((val,ind)=>{

              return(
                <Col
                  key={ind}
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  span={8}
                  style={{ padding: 8 }}
                >
                  <Avatar
                  onClick={() => {
                    this.setState({
                      previewVisible: true,
                      pic: val
                    });
                    }}
                    shape="square"
                    style={{ width: '100%', height: 60 }}
                    src={val?val:Images.BACKGROUND_DARK}
                  />
                </Col>
              )

            }):"No Documents Pictures"}
            
          
          </Row>
        </div>
      </div>
    );
  }
}
export default VehicleInfoCard;
