import React, { Component } from "react";
import {
  Input,
  Card,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  Table,
  message,
  InputNumber,
  Form
} from "antd";
import { connect } from "react-redux";
import * as Actions from "./action/actionCreater";
import moment from "moment";
import cities from "../../../constants/jsons/cities.json";
import _ from "lodash";
import { JsonToExcel } from "react-json-excel";
import { colors, sizes } from "../../../constants/constants";
const { RangePicker } = DatePicker;
const Option = Select.Option;
const childrenCities = [];
childrenCities.length = 0;
if (cities["Pakistan"] === undefined) {
} else {
  cities["Pakistan"].forEach(element => {
    childrenCities.push(
      <Option key={element} value={element}>
        {element}
      </Option>
    );
  });
}
const fields = {
  key: "Index",
  date: "Date",
  tripLog: "TripId",
  client: "Client Name",
  truckType: "Truck Type",
  licensePlate: "Vehicles",
  broker: "Brokers",
  driver: "Drivers",
  flocation: "From Street",
  fcity: "From City",
  tlocation: "To Location",
  tcity: "To City",
  goodsType: "Goods Type",
  weight: "Weight",
  unit: "Unit",
  description: "Description",
  tripStartTime: "Start Time",
  tripEndTime: "End Time",
  totalTripHours: "Total Trip Hours",
  bltNo: "Btl",
  BltDeliveredBy: "Deliverd By",
  BltReceivingDate: "BLT Recived By",
  costOfLabours: "Cost Of Labour",
  addDescription: "Additional Description",
  startMeter: "Start Meter",
  endMeter: "End Meter",
  totalMileage: "Total Milege",
  amount: "Ammount",
  advance: "Advance"
};
const EditableCell = ({ editable, value, onChange, type }) => {
  if (type === "number") {
    return (
      <div>
        {editable ? (
          <InputNumber
            type="number"
            style={{ margin: "-5px 0", minWidth: 70 }}
            value={value}
            onChange={e => onChange(e)}
          />
        ) : (
          value
        )}
      </div>
    );
  } else if (type === "date") {
    return (
      <div>
        {editable ? (
          <DatePicker
            style={{ margin: "-5px 0", minWidth: 100 }}
            defaultValue={value ? moment(value, "L") : null}
            format={"L"}
            onChange={e => onChange(moment(e).format("L"))}
          />
        ) : (
          value
        )}
      </div>
    );
  } else if (type === "dropUnit") {
    return (
      <div>
        {editable ? (
          <Select
            style={{ margin: "-5px 0", minWidth: 100 }}
            optionFilterProp="children"
            onChange={e => onChange(e)}
            value={value}
          >
            <Option value="Kg">Kg</Option>
            <Option value="Ton">Ton</Option>
          </Select>
        ) : (
          value
        )}
      </div>
    );
  } else if (type === "dropCities") {
    return (
      <div>
        {editable ? (
          <Select
            style={{ margin: "-5px 0", minWidth: 100 }}
            optionFilterProp="children"
            onChange={e => onChange(e)}
            value={value}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {childrenCities}
          </Select>
        ) : (
          value
        )}
      </div>
    );
  } else {
    return (
      <div>
        {editable ? (
          <Input
            style={{ margin: "-5px 0" }}
            value={value}
            onChange={e => onChange(e.target.value)}
          />
        ) : (
          value
        )}
      </div>
    );
  }
};
class EditableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fromto: [],
      reports: [],
      id: "",
      selection: ""
    };
    this.columns2 = [
      {
        title: "Date.",
        width: 100,
        dataIndex: "date",
        key: "date",
        sorter: true,
        fixed: "left"
      },
      {
        title: "Trip log",
        dataIndex: "tripLog",
        width: 130,
        key: "tripLog"
      },
      {
        title: "Client",
        dataIndex: "client",
        width: 130,
        key: "client"
      },
      {
        title: "Truck Type",
        dataIndex: "truckType",
        width: 130,
        key: "truckType"
      },
      {
        title: "License Plate",
        dataIndex: "licensePlate",
        width: 130,
        key: "licensePlate"
      },
      {
        title: "Transporter",
        dataIndex: "broker",
        width: 130,
        key: "broker"
      },
      {
        title: "Driver",
        dataIndex: "driver",
        width: 130,
        key: "driver"
      },
      {
        title: "From",
        children: [
          {
            title: "Location",
            dataIndex: "flocation",
            width: 180,
            key: "flocation",
            render: (text, record) =>
              this.renderColumns(text, record, "flocation")
          },
          {
            title: "city",
            dataIndex: "fcity",
            width: 130,
            key: "fcity",
            render: (text, record) =>
              this.renderColumns(text, record, "fcity", "dropCities")
          }
        ]
      },
      {
        title: "To",
        children: [
          {
            title: "Location",
            dataIndex: "tlocation",
            width: 180,
            key: "tlocation",
            render: (text, record) =>
              this.renderColumns(text, record, "tlocation")
          },
          {
            title: "city",
            dataIndex: "tcity",
            width: 130,
            key: "tcity",
            render: (text, record) =>
              this.renderColumns(text, record, "tcity", "dropCities")
          }
        ]
      },
      {
        title: "Goods Information",
        children: [
          {
            title: "Goods Type",
            dataIndex: "goodsType",
            width: 130,
            key: "goodsType"
          },
          {
            title: "Weight",
            children: [
              {
                title: "weight",
                dataIndex: "weight",
                width: 130,
                key: "weight",
                render: (text, record) =>
                  this.renderColumns(text, record, "weight", "number")
              },
              {
                title: "unit",
                dataIndex: "unit",
                width: 70,
                key: "unit",
                render: (text, record) =>
                  this.renderColumns(text, record, "unit", "dropUnit")
              }
            ]
          },

          {
            title: "Description",
            dataIndex: "description",
            width: 180,
            key: "description",
            render: (text, record) =>
              this.renderColumns(text, record, "description")
          }
        ]
      },
      {
        title: "Trip Start Date & Time",
        dataIndex: "tripStartTime",
        width: 200,
        key: "tripStartTime",
        render: (text, record) =>
          this.renderColumns(text, record, "tripStartTime", "date")
      },
      {
        title: "Trip End Date & Time",
        dataIndex: "tripEndTime",
        width: 200,
        key: "tripEndTime",
        render: (text, record) =>
          this.renderColumns(text, record, "tripEndTime", "date")
      },
      {
        title: "Total Trip Hours",
        dataIndex: "totalTripHours",
        width: 130,
        key: "totalTripHours"
      },
      {
        title: "Starting Meter",
        dataIndex: "startMeter",
        width: 130,
        key: "startMeter",
        render: (text, record) =>
          this.renderColumns(text, record, "startMeter", "number")
      },
      {
        title: "Ending Meter",
        dataIndex: "endMeter",
        width: 130,
        key: "endMeter",
        render: (text, record) =>
          this.renderColumns(text, record, "endMeter", "number")
      },
      {
        title: "Total Mileage",
        dataIndex: "totalMileage",
        width: 130,
        key: "totalMileage"
      },
      {
        title: "BLT No",
        dataIndex: "bltNo",
        width: 130,
        key: "bltNo",
        render: (text, record) => this.renderColumns(text, record, "bltNo")
      },

      {
        title: "BLT Delivered By",
        dataIndex: "BltDeliveredBy",
        width: 130,
        key: "BltDeliveredBy",
        render: (text, record) =>
          this.renderColumns(text, record, "BltDeliveredBy")
      },
      {
        title: "BLT Receiving Date",
        dataIndex: "BltReceivingDate",
        width: 130,
        key: "BltReceivingDate",
        render: (text, record) =>
          this.renderColumns(text, record, "BltReceivingDate", "date")
      },
      {
        title: "Cost of Labours",
        dataIndex: "costOfLabours",
        width: 60,
        key: "costOfLabours",
        render: (text, record) =>
          this.renderColumns(text, record, "costOfLabours", "number")
      },
      {
        title: "Amount (PKR)",
        dataIndex: "amount",
        width: 130,
        key: "amount",
        render: (text, record) =>
          this.renderColumns(text, record, "amount", "number")
      },
      {
        title: "Advance Paid",
        dataIndex: "advance",
        width: 130,
        key: "advance",
        render: (text, record) =>
          this.renderColumns(text, record, "advance", "number")
      },
      {
        title: "Additional Description",
        dataIndex: "addDescription",
        width: 230,
        key: "addDescription",
        render: (text, record) =>
          this.renderColumns(text, record, "addDescription")
      },
      {
        title: "Edit",
        fixed: "right",
        width: 70,
        dataIndex: "operation",
        render: (text, record) => {
          const { editable } = record;
          return (
            <div className="editable-row-operations">
              {editable ? (
                <span>
                  <span
                    style={{
                      color: colors.primaryColor,
                      cursor: "pointer",
                      fontSize: sizes.h4
                    }}
                    onClick={() => this.save(record.key)}
                  >
                    Save
                  </span>
                  <span
                    onClick={() => this.cancel(record.key)}
                    style={{
                      color: colors.primaryColor,
                      cursor: "pointer",
                      fontSize: sizes.h4,
                      marginLeft: 4
                    }}
                  >
                    cancel
                  </span>
                </span>
              ) : (
                <span
                  onClick={() => this.edit(record.key)}
                  style={{
                    color: colors.primaryColor,
                    cursor: "pointer",
                    fontSize: sizes.h4
                  }}
                >
                  Edit
                </span>
              )}
            </div>
          );
        }
      }
    ];
  }
  renderColumns(text, record, column, type) {
    return (
      <EditableCell
        editable={record.editable}
        value={text}
        onChange={value => this.handleChange(value, record.key, column)}
        type={type}
      />
    );
  }
  handleChange(value, key, column) {
    const newData = [...this.state.reports];
    const target = newData.filter(item => key === item.key)[0];
    if (target) {
      target[column] = value;
      this.setState({ data: newData });
    }
  }
  edit(key) {
    const newData = [...this.state.reports];
    const target = newData.filter(item => key === item.key)[0];
    if (target) {
      target.editable = true;
      this.setState({ reports: newData });
    }
  }
  save(key) {
    var newData = [...this.state.reports];
    const target = newData.filter(item => key === item.key)[0];
    if (target) {
      // newData = newData.filter(i=>{
      //   if (target.key===i.key && target.tripStartingMeter!==""&&target.tripEndingMeter!=="") {
      //      return i.totalMileage ===target.tripEndingMeter-target.tripStartingMeter
      //   }
      // })
      const { onReportUpdateRequest } = this.props;
      console.log(target);
      let data = {
        blt: target.bltNo ? target.bltNo.toString() : "Not Assigned",
        advancePaid: target.advance ? target.advance : null,
        bltReceivingDate: target.BltReceivingDate
          ? moment(target.BltReceivingDate).toISOString()
          : moment().toISOString(),
        bltDeliveredBy: target.BltDeliveredBy ? target.BltDeliveredBy : null,
        additionalDescription: target.addDescription
          ? target.addDescription
          : null,
        tripStartingMeter: target.startMeter ? target.startMeter : null,
        costOfLabour: target.costOfLabours ? target.costOfLabours : null,
        tripEndingMeter: target.endMeter ? target.endMeter : null,
        time: {
          ended: target.tripStartTime
            ? moment(target.tripStartTime).toISOString()
            : moment().toISOString(),
          started: target.tripEndTime
            ? moment(target.tripEndTime).toISOString()
            : moment().toISOString()
        },
        pickupLocation: {
          street: target.flocation ? target.flocation : "from",
          city: target.fcity ? target.fcity : "Karachi"
        },
        dropoffLocation: {
          street: target.tlocation ? target.tlocation : "to",
          city: target.tcity ? target.tcity : "Hyderabad"
        },
        weight: {
          quantity: target.weight ? target.weight : null,
          unit: target.unit ? target.unit : "Kg"
        },
        goodsDescription: target.description ? target.description : null,
        tripId: target.tripLog
      };
      this.setState({ loading: true });
      const hello = async () => {
        let response = await onReportUpdateRequest(data);
        if (response.payload.data !== "") {
          // onAddTripRequestPush(response.payload.data);
          message.success("Report Updated");
          delete target.editable;
          this.cacheData = newData.map(item => ({ ...item }));

          //   here api will hit
          this.setState({ loading: false });
          newData[key].totalMileage =
            newData[key].endMeter !== "" &&
            newData[key].endMeter !== undefined &&
            newData[key].startMeter !== "" &&
            newData[key].startMeter !== undefined
              ? newData[key].endMeter - newData[key].startMeter
              : 0;
          this.setState({ reports: newData });
        }
      };
      hello();
    }
  }
  cancel(key) {
    const newData = [...this.state.reports];
    const target = newData.filter(item => key === item.key)[0];
    if (target) {
      delete target.editable;
      this.setState({ reports: newData });
      this.cacheData = newData.map(item => ({ ...item }));
      this.setState({ reports: newData });
    }
  }
  renderSelection = () => {
    const { allBrokers, allClients, allDrivers, allVehicles } = this.props;
    if (this.state.selection === "driver") {
      return (
        <Select
          showSearch
          style={{ width: 220, paddingRight: 10 }}
          placeholder="Select a Driver"
          optionFilterProp="children"
          value={this.state.id !== "" ? this.state.id : undefined}
          onChange={e => {
            this.setState({ id: e });
          }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {allDrivers.map((value, index) => {
            return (
              <Option value={value._id} key={index}>
                {value.name}
              </Option>
            );
          })}
        </Select>
      );
    } else if (this.state.selection === "broker") {
      return (
        <Select
          showSearch
          style={{ width: 220, paddingRight: 10 }}
          placeholder="Select a Broker"
          optionFilterProp="children"
          value={this.state.id !== "" ? this.state.id : undefined}
          onChange={e => {
            this.setState({ id: e });
          }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {allBrokers.map((value, index) => {
            return (
              <Option value={value._id} key={index}>
                {value.name}
              </Option>
            );
          })}
        </Select>
      );
    } else if (this.state.selection === "vehicle") {
      return (
        <Select
          showSearch
          style={{ width: 220, paddingRight: 10 }}
          placeholder="Select a Vehicle"
          optionFilterProp="children"
          value={this.state.id !== "" ? this.state.id : undefined}
          onChange={e => {
            this.setState({ id: e });
          }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {allVehicles.map((value, index) => {
            return (
              <Option value={value._id} key={index}>
                {value.plateNo}
              </Option>
            );
          })}
        </Select>
      );
    } else if (this.state.selection === "client") {
      return (
        <Select
          showSearch
          style={{ width: 220, paddingRight: 10 }}
          placeholder="Select a Client"
          value={this.state.id !== "" ? this.state.id : undefined}
          onChange={e => {
            this.setState({ id: e });
          }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {allClients.map((value, index) => {
            return (
              <Option value={value._id} key={index}>
                {value.name}
              </Option>
            );
          })}
        </Select>
      );
    } else {
      return <div />;
    }
  };

  submit = () => {
    const { onReportsGetRequest, userInfo } = this.props;
    if (userInfo !== {}) {
      if (this.state.selection === "driver") {
        if (this.state.selection && this.state.id) {
          this.setState({ loading: true });
          let data = {
            from: moment(this.state.fromto[0]).toISOString(),
            to: moment(this.state.fromto[1]).toISOString(),
            userId: userInfo.user._id,
            type: "Driver",
            localId: this.state.id
          };
          const hello = async () => {
            let response = await onReportsGetRequest(data);
            if (response.payload.data !== "") {
              console.log(response.payload.data);
              if (response.payload.data.length) {
                this.state.reports.length = 0;
                response.payload.data.map((val, ind) => {
                  let truckTypes = val.assignedTo.map(
                    (v, i) => v.vehicleTypeTitle + ", "
                  );
                  let vehicles = val.assignedTo.map((v, i) => {
                    if (v.type === "Driver") {
                      return v.vehiclePlateNo + ", ";
                    } else {
                      return "Broker(" + v.brokerVehicle + "), ";
                    }
                  });
                  let brokers = val.assignedTo.map((v, i) => {
                    if (v.type === "Driver") {
                      return "Not Assigned, ";
                    } else {
                      return v.brokerName + ", ";
                    }
                  });
                  let drivers = val.assignedTo.map((v, i) => {
                    if (v.type === "Driver") {
                      return v.driverName + ", ";
                    } else {
                      return "Broker(" + v.brokerDriver + ")";
                    }
                  });
                  var now = moment(val.time.started);
                  var end = moment(val.time.ended);
                  var diff = moment.duration(end.diff(now));
                  var tripHours = parseFloat(diff.asHours()).toFixed(1);
                  var goodsType = val.goodsTypeInformation
                    ? val.goodsTypeInformation.map((val, ind) => {
                      return val.name;
                    })
                    : "";
                  this.state.reports.push({
                    key: ind,
                    date: moment(val.time.ended).format("L"),
                    tripLog: val.tripId,
                    client: val.clientInformation? val.clientInformation.name:val.userInformation.name,
                    truckType: truckTypes,
                    licensePlate: vehicles,
                    broker: brokers,
                    driver: drivers,
                    flocation: val.tripRequestInformation.pickupLocation.street,
                    tlocation:
                      val.tripRequestInformation.dropoffLocation.street,
                    fcity: val.tripRequestInformation.pickupLocation.city,
                    tcity: val.tripRequestInformation.dropoffLocation.city,
                    goodsType: goodsType,
                    weight: val.goodsInformation.weight
                      ? val.goodsInformation.weight.quantity
                      : 0,
                    unit: val.goodsInformation.weight.unit,
                    description: val.goodsInformation.goodsDescription,
                    tripStartTime: moment(val.time.started).format("L"),
                    tripEndTime: moment(val.time.ended).format("L"),
                    totalTripHours: tripHours,
                    bltNo: val.blt ? val.blt : 0,
                    BltDeliveredBy: val.bltDeliveredBy
                      ? val.bltDeliveredBy
                      : "Not Assigned",
                    BltReceivingDate: val.bltReceivingDate
                      ? moment(val.bltReceivingDate).format("L")
                      : "Not Assigned",
                    costOfLabours: val.costOfLabour ? val.costOfLabour : 0,
                    addDescription: val.additionalDescription
                      ? val.additionalDescription
                      : "Not Assigned",
                    startMeter: val.tripStartingMeter
                      ? val.tripStartingMeter
                      : 0,
                    endMeter: val.tripEndingMeter ? val.tripEndingMeter : 0,
                    totalMileage:
                      val.tripStartingMeter && val.tripEndingMeter
                        ? val.tripEndingMeter - val.tripStartingMeter
                        : 0,
                    amount: val.totalPayment ? val.totalPayment : 0,
                    advance: val.advancePaid ? val.advancePaid : 0
                  });
                });
              } else {
                this.state.reports.length = 0;
                message.info("No Reports Found");
              }
              this.setState({ loading: false });
            }
          };
          hello();
        } else {
          message.warn("Please Select Driver");
        }
      } else if (this.state.selection === "broker") {
        if (this.state.selection && this.state.id) {
          this.setState({ loading: true });
          let data = {
            from: moment(this.state.fromto[0]).toISOString(),
            to: moment(this.state.fromto[1]).toISOString(),
            userId: userInfo.user._id,
            type: "Broker",
            localId: this.state.id
          };
          const hello = async () => {
            let response = await onReportsGetRequest(data);
            if (response.payload.data !== "") {
              console.log(response.payload.data);
              if (response.payload.data.length !== 0) {
                this.state.reports.length = 0;
                response.payload.data.map((val, ind) => {
                  let truckTypes = val.assignedTo.map(
                    (v, i) => v.vehicleTypeTitle + ", "
                  );
                  let vehicles = val.assignedTo.map((v, i) => {
                    if (v.type === "Driver") {
                      return v.vehiclePlateNo + ", ";
                    } else {
                      return "Broker(" + v.brokerVehicle + "), ";
                    }
                  });
                  let brokers = val.assignedTo.map((v, i) => {
                    if (v.type === "Driver") {
                      return "Not Assigned, ";
                    } else {
                      return v.brokerName + ", ";
                    }
                  });
                  let drivers = val.assignedTo.map((v, i) => {
                    if (v.type === "Driver") {
                      return v.driverName + ", ";
                    } else {
                      return "Broker(" + v.brokerDriver + ")";
                    }
                  });
                  var now = moment(val.time.started);
                  var end = moment(val.time.ended);
                  var diff = moment.duration(end.diff(now));
                  var tripHours = parseFloat(diff.asHours()).toFixed(1);
                  var goodsType = val.goodsTypeInformation
                    ? val.goodsTypeInformation.map((val, ind) => {
                      return val.name;
                    })
                    : "";
                  this.state.reports.push({
                    key: ind,
                    date: moment(val.time.ended).format("L"),
                    tripLog: val.tripId,
                    client: val.clientInformation? val.clientInformation.name:val.userInformation.name,
                    truckType: truckTypes,
                    licensePlate: vehicles,
                    broker: brokers,
                    driver: drivers,
                    flocation: val.tripRequestInformation.pickupLocation.street,
                    tlocation:
                      val.tripRequestInformation.dropoffLocation.street,
                    fcity: val.tripRequestInformation.pickupLocation.city,
                    tcity: val.tripRequestInformation.dropoffLocation.city,
                    goodsType: goodsType,
                    weight: val.goodsInformation.weight
                      ? val.goodsInformation.weight.quantity
                      : 0,
                    unit: val.goodsInformation.weight.unit,
                    description: val.goodsInformation.goodsDescription,
                    tripStartTime: moment(val.time.started).format("L"),
                    tripEndTime: moment(val.time.ended).format("L"),
                    totalTripHours: tripHours,
                    bltNo: val.blt ? val.blt : 0,
                    BltDeliveredBy: val.bltDeliveredBy
                      ? val.bltDeliveredBy
                      : "Not Assigned",
                    BltReceivingDate: val.bltReceivingDate
                      ? moment(val.bltReceivingDate).format("L")
                      : "Not Assigned",
                    costOfLabours: val.costOfLabour ? val.costOfLabour : 0,
                    addDescription: val.additionalDescription
                      ? val.additionalDescription
                      : "Not Assigned",
                    startMeter: val.tripStartingMeter
                      ? val.tripStartingMeter
                      : 0,
                    endMeter: val.tripEndingMeter ? val.tripEndingMeter : 0,
                    totalMileage:
                      val.tripStartingMeter && val.tripEndingMeter
                        ? val.tripEndingMeter - val.tripStartingMeter
                        : 0,
                    amount: val.totalPayment ? val.totalPayment : 0,
                    advance: val.advancePaid ? val.advancePaid : 0
                  });
                });
              } else {
                this.state.reports.length = 0;
                message.info("No Reports Found");
              }
              this.setState({ loading: false });
            }
          };
          hello();
        } else {
          message.warn("Please Select Broker");
        }
      } else if (this.state.selection === "vehicle") {
        if (this.state.selection && this.state.id) {
          this.setState({ loading: true });
          let data = {
            from: moment(this.state.fromto[0]).toISOString(),
            to: moment(this.state.fromto[1]).toISOString(),
            userId: userInfo.user._id,
            type: "Vehicle",
            localId: this.state.id
          };
          const hello = async () => {
            let response = await onReportsGetRequest(data);
            if (response.payload.data !== "") {
              console.log(response.payload.data);
              if (response.payload.data.length !== 0) {
                this.state.reports.length = 0;
                response.payload.data.map((val, ind) => {
                  let truckTypes = val.assignedTo.map(
                    (v, i) => v.vehicleTypeTitle + ", "
                  );
                  let vehicles = val.assignedTo.map((v, i) => {
                    if (v.type === "Driver") {
                      return v.vehiclePlateNo + ", ";
                    } else {
                      return "Broker(" + v.brokerVehicle + "), ";
                    }
                  });
                  let brokers = val.assignedTo.map((v, i) => {
                    if (v.type === "Driver") {
                      return "Not Assigned, ";
                    } else {
                      return v.brokerName + ", ";
                    }
                  });
                  let drivers = val.assignedTo.map((v, i) => {
                    if (v.type === "Driver") {
                      return v.driverName + ", ";
                    } else {
                      return "Broker(" + v.brokerDriver + ")";
                    }
                  });
                  var now = moment(val.time.started);
                  var end = moment(val.time.ended);
                  var diff = moment.duration(end.diff(now));
                  var tripHours = parseFloat(diff.asHours()).toFixed(1);
                  var goodsType = val.goodsTypeInformation
                    ? val.goodsTypeInformation.map((val, ind) => {
                      return val.name;
                    })
                    : "";
                  this.state.reports.push({
                    key: ind,
                    date: moment(val.time.ended).format("L"),
                    tripLog: val.tripId,
                    client: val.clientInformation? val.clientInformation.name:val.userInformation.name,
                    truckType: truckTypes,
                    licensePlate: vehicles,
                    broker: brokers,
                    driver: drivers,
                    flocation: val.tripRequestInformation.pickupLocation.street,
                    tlocation:
                      val.tripRequestInformation.dropoffLocation.street,
                    fcity: val.tripRequestInformation.pickupLocation.city,
                    tcity: val.tripRequestInformation.dropoffLocation.city,
                    goodsType: goodsType,
                    weight: val.goodsInformation.weight
                      ? val.goodsInformation.weight.quantity
                      : 0,
                    unit: val.goodsInformation.weight.unit,
                    description: val.goodsInformation.goodsDescription,
                    tripStartTime: moment(val.time.started).format("L"),
                    tripEndTime: moment(val.time.ended).format("L"),
                    totalTripHours: tripHours,
                    bltNo: val.blt ? val.blt : 0,
                    BltDeliveredBy: val.bltDeliveredBy
                      ? val.bltDeliveredBy
                      : "Not Assigned",
                    BltReceivingDate: val.bltReceivingDate
                      ? moment(val.bltReceivingDate).format("L")
                      : "Not Assigned",
                    costOfLabours: val.costOfLabour ? val.costOfLabour : 0,
                    addDescription: val.additionalDescription
                      ? val.additionalDescription
                      : "Not Assigned",
                    startMeter: val.tripStartingMeter
                      ? val.tripStartingMeter
                      : 0,
                    endMeter: val.tripEndingMeter ? val.tripEndingMeter : 0,
                    totalMileage:
                      val.tripStartingMeter && val.tripEndingMeter
                        ? val.tripEndingMeter - val.tripStartingMeter
                        : 0,
                    amount: val.totalPayment ? val.totalPayment : 0,
                    advance: val.advancePaid ? val.advancePaid : 0
                  });
                });
              } else {
                this.state.reports.length = 0;
                message.info("No Reports Found");
              }
              this.setState({ loading: false });
            }
          };
          hello();
        } else {
          message.warn("Please Select Vehicle");
        }
      } else if (this.state.selection === "client") {
        if (this.state.selection && this.state.id) {
          this.setState({ loading: true });
          let data = {
            from: moment(this.state.fromto[0]).toISOString(),
            to: moment(this.state.fromto[1]).toISOString(),
            userId: userInfo.user._id,
            type: "Client",
            localId: this.state.id
          };
          const hello = async () => {
            let response = await onReportsGetRequest(data);
            if (response.payload.data !== "") {
              console.log(response.payload.data);
              if (response.payload.data.length !== 0) {
                this.state.reports.length = 0;
                response.payload.data.map((val, ind) => {
                  let truckTypes = val.assignedTo.map(
                    (v, i) => v.vehicleTypeTitle + "/ "
                  );
                  let vehicles = val.assignedTo.map((v, i) => {
                    if (v.type === "Driver") {
                      return v.vehiclePlateNo + "/ ";
                    } else {
                      return "Broker(" + v.brokerVehicle + "), ";
                    }
                  });
                  let brokers = val.assignedTo.map((v, i) => {
                    if (v.type === "Driver") {
                      return "Not Assigned, ";
                    } else {
                      return v.brokerName + "/ ";
                    }
                  });
                  let drivers = val.assignedTo.map((v, i) => {
                    if (v.type === "Driver") {
                      return v.driverName + "/ ";
                    } else {
                      return "Broker(" + v.brokerDriver + ")";
                    }
                  });
                  var now = moment(val.time.started);
                  var end = moment(val.time.ended);
                  var diff = moment.duration(end.diff(now));
                  var tripHours = parseFloat(diff.asHours()).toFixed(1);
                  var goodsType = val.goodsTypeInformation
                    ? val.goodsTypeInformation.map((val, ind) => {
                      return val.name;
                    })
                    : "";

                  this.state.reports.push({
                    key: ind,
                    date: moment(val.time.ended).format("L"),
                    tripLog: val.tripId,
                    client: val.clientInformation? val.clientInformation.name:val.userInformation.name,
                    truckType: truckTypes,
                    licensePlate: vehicles,
                    broker: brokers,
                    driver: drivers,
                    flocation: val.tripRequestInformation.pickupLocation.street,
                    tlocation:
                      val.tripRequestInformation.dropoffLocation.street,
                    fcity: val.tripRequestInformation.pickupLocation.city,
                    tcity: val.tripRequestInformation.dropoffLocation.city,
                    goodsType: goodsType,
                    weight: val.goodsInformation.weight
                      ? val.goodsInformation.weight.quantity
                      : 0,
                    unit: val.goodsInformation.weight.unit,
                    description: val.goodsInformation.goodsDescription,
                    tripStartTime: moment(val.time.started).format("L"),
                    tripEndTime: moment(val.time.ended).format("L"),
                    totalTripHours: tripHours,
                    bltNo: val.blt ? val.blt : 0,
                    BltDeliveredBy: val.bltDeliveredBy
                      ? val.bltDeliveredBy
                      : "Not Assigned",
                    BltReceivingDate: val.bltReceivingDate
                      ? moment(val.bltReceivingDate).format("L")
                      : "Not Assigned",
                    costOfLabours: val.costOfLabour ? val.costOfLabour : 0,
                    addDescription: val.additionalDescription
                      ? val.additionalDescription
                      : "Not Assigned",
                    startMeter: val.tripStartingMeter
                      ? val.tripStartingMeter
                      : 0,
                    endMeter: val.tripEndingMeter ? val.tripEndingMeter : 0,
                    totalMileage:
                      val.tripStartingMeter && val.tripEndingMeter
                        ? val.tripEndingMeter - val.tripStartingMeter
                        : 0,
                    amount: val.totalPayment ? val.totalPayment : 0,
                    advance: val.advancePaid ? val.advancePaid : 0
                  });
                });
              } else {
                this.state.reports.length = 0;
                message.info("No Reports Found");
              }
              this.setState({ loading: false });
            }
          };
          hello();
        } else {
          message.warn("Please Select Client");
        }
      } else {
        this.setState({ loading: true });
        let data = {
          from: moment(this.state.fromto[0]).toISOString(),
          to: moment(this.state.fromto[1]).toISOString(),
          userId: userInfo.user._id,
          type: "User"
        };
        const hello = async () => {
          let response = await onReportsGetRequest(data);
          if (response.payload.data !== "") {
            console.log(response.payload.data);
            if (response.payload.data.length !== 0) {
              this.state.reports.length = 0;
              response.payload.data.map((val, ind) => {
                let truckTypes = val.assignedTo.map(
                  (v, i) => v.vehicleTypeTitle + " "
                );
                let vehicles = val.assignedTo.map((v, i) => {
                  if (v.type === "Driver") {
                    return v.vehiclePlateNo + " ";
                  } else {
                    return "Broker(" + v.brokerVehicle + ")  ";
                  }
                });
                let brokers = val.assignedTo.map((v, i) => {
                  if (v.type === "Driver") {
                    return "Not Assigned ";
                  } else {
                    return v.brokerName + " ";
                  }
                });
                let drivers = val.assignedTo.map((v, i) => {
                  if (v.type === "Driver") {
                    return v.driverName + " ";
                  } else {
                    return "Broker(" + v.brokerDriver + ") ";
                  }
                });
                var now = moment(val.time.started);
                var end = moment(val.time.ended);
                var diff = moment.duration(end.diff(now));
                var tripHours = parseFloat(diff.asHours()).toFixed(1);
                var goodsType = val.goodsTypeInformation
                  ? val.goodsTypeInformation.map((val, ind) => {
                    return val.name + " ";
                  })
                  : "";
                this.state.reports.push({
                  key: ind,
                  date: moment(val.time.ended).format("L"),
                  tripLog: val.tripId,
                  client: val.clientInformation? val.clientInformation.name:val.userInformation.name,
                  truckType: truckTypes,
                  licensePlate: vehicles,
                  broker: brokers,
                  driver: drivers,
                  flocation: val.tripRequestInformation.pickupLocation.street,
                  tlocation: val.tripRequestInformation.dropoffLocation.street,
                  fcity: val.tripRequestInformation.pickupLocation.city,
                  tcity: val.tripRequestInformation.dropoffLocation.city,
                  goodsType: goodsType,
                  weight: val.goodsInformation.weight
                    ? val.goodsInformation.weight.quantity
                    : 0,
                  unit: val.goodsInformation.weight.unit,
                  description: val.goodsInformation.goodsDescription,
                  tripStartTime: moment(val.time.started).format("L"),
                  tripEndTime: moment(val.time.ended).format("L"),
                  totalTripHours: tripHours,
                  bltNo: val.blt ? val.blt : 0,
                  BltDeliveredBy: val.bltDeliveredBy
                    ? val.bltDeliveredBy
                    : "Not Assigned",
                  BltReceivingDate: val.bltReceivingDate
                    ? moment(val.bltReceivingDate).format("L")
                    : undefined,
                  costOfLabours: val.costOfLabour ? val.costOfLabour : 0,
                  addDescription: val.additionalDescription
                    ? val.additionalDescription
                    : "Not Assigned",
                  startMeter: val.tripStartingMeter ? val.tripStartingMeter : 0,
                  endMeter: val.tripEndingMeter ? val.tripEndingMeter : 0,
                  totalMileage:
                    val.tripStartingMeter && val.tripEndingMeter
                      ? val.tripEndingMeter - val.tripStartingMeter
                      : 0,
                  amount: val.totalPayment ? val.totalPayment : 0,
                  advance: val.advancePaid ? val.advancePaid : 0
                });
              });
            } else {
              this.state.reports.length = 0;
              message.info("No Reports Found");
            }
            this.setState({
              loading: false,
              reports: _.uniqBy(this.state.reports, "tripLog")
            });
          }
        };
        hello();
      }
    }
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <Card>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              span={12}
              style={{ paddingRight: 20 }}
            >
              <Row>
                <Col xs={24} sm={24} md={24} lg={5} xl={5} span={5}>
                  <h4>Select Range:</h4>
                </Col>
                <Col xs={24} sm={24} md={24} lg={19} xl={19} span={19}>
                  <RangePicker
                    format="DD-MM-YYYY"
                    placeholder={["From", "To"]}
                    onChange={(v, sadsa) => {
                      console.log("Formatted Selected Time: ", v);
                      this.setState({ fromto: v });
                    }}
                    onOk={value => {
                      console.log("onOk: ", value);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={6} xl={3} span={3}>
                  <h4>Filter:</h4>
                </Col>
                <Col xs={24} sm={24} md={24} lg={21} xl={21} span={21}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%"
                    }}
                  >
                    <Select
                      showSearch
                      style={{ width: 200, paddingRight: 20 }}
                      placeholder="Select category"
                      optionFilterProp="children"
                      defaultValue="none"
                      onChange={e => {
                        this.setState({ selection: e, id: "" });
                      }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="none">none</Option>
                      <Option value="driver">Driver</Option>
                      <Option value="vehicle">Vehicle</Option>
                      <Option value="client">Client</Option>
                      <Option value="broker">Transporter</Option>
                    </Select>
                    {this.renderSelection()}
                    <Button
                      loading={this.state.loading}
                      disabled={this.state.loading}
                      type="primary"
                      icon="filter"
                      onClick={() => {
                        this.submit();
                      }}
                    >
                      Go
                    </Button>
                    <div style={{ width: "100%" }}>
                      <div style={{ float: "right" }}>
                        <JsonToExcel
                          data={this.state.reports ? this.state.reports : []}
                          className="ant-btn-ghost ant-btn-danger ant-btn"
                          filename={"Excel-file"}
                          fields={fields}
                          style={{ color: "white", backgroundColor: "red" }}
                          fileformat={"xls"}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <div style={{ height: "100%" }}>
          <Card style={{ height: "100%" }}>
            <div>
              <Table
                loading={this.state.loading}
                columns={this.columns2}
                pagination={{ pageSize: 10 }}
                bordered
                dataSource={this.state.reports}
                size="small"
                scroll={{ x: 3530, y: false }}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
const Reprot = Form.create()(EditableTable);
const mapStateToProps = state => ({
  userInfo: state.signup.content,
  allClients: state.global.allClients,
  allBrokers: state.global.allBrokers,
  allDrivers: state.global.allDrivers,
  allVehicles: state.global.allVehicles
});
const mapDispatchToProps = dispatch => ({
  // onChangeDriverModalVisible: visible =>
  //   dispatch(Actions.ON_CHANGE_DRIVER_MODAL_VISIBLE(visible)),
  onReportsGetRequest: data => dispatch(Actions.onReportsGetRequest(data)),
  onReportUpdateRequest: data => dispatch(Actions.onReportUpdateRequest(data))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reprot);
