import React, { Component } from "react";
import {
  Modal,
  Col,
  Form,
  Select,
  Input,
  Button,
  Table,
  Row,
  Popconfirm,
  message,
  InputNumber
} from "antd";
import { connect } from "react-redux";
import * as Actions from "../../../modules/loggedin/brokermanagement/action/actionCreater";
import * as ActionsGlobal from "../../../modules/loggedin/sidebar/action/actionCreater";
import * as ActionsDetail from "../../../modules/loggedin/detail/action/actionCreater";
import ModalCompoentLeftComtent from "../../modalComponentLeftContent/modalComponentLeftViewContent";
import { Images } from "../../../public/asset/index";
import ApnaDivider from "../../apnaDivider/apnaDivider";
import { sizes, textColor } from "../../../constants/constants";
import cities from "../../../constants/jsons/cities.json";
import UploadSingleImage from "../../uploadImage/uploadImageSingle";
import UploadImageMultiple from "../../uploadImage/uploadImageMultiple";
import moment from "moment";
import { array } from "prop-types";
const Option = Select.Option;
const childrenCities = [];
childrenCities.length = 0;
if (cities["Pakistan"] === undefined) {
} else {
  cities["Pakistan"].forEach(element => {
    childrenCities.push(<Option key={element}>{element}</Option>);
  });
}

class BrokerModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      BFData: [],
      vCat: "",
      vType: "",
      vTypeId: "",
      qty: 1,
      count1: 1,
      profilePic: false,
      multiUrl1: [],
      picArra:["https://elasticbeanstalk-us-east-1-435177095658.s3.amazonaws.com/2ab0e240-6e5d-11e9-9a6e-93292be1f397","https://elasticbeanstalk-us-east-1-435177095658.s3.amazonaws.com/ebf42250-6e5d-11e9-9a6e-93292be1f397","https://elasticbeanstalk-us-east-1-435177095658.s3.amazonaws.com/d314a430-6e5d-11e9-9a6e-93292be1f397","https://elasticbeanstalk-us-east-1-435177095658.s3.amazonaws.com/597c7d30-6e5f-11e9-9a6e-93292be1f397"]
    };
    this.BFcolumns = [
      {
        title: (
          <span style={{ color: textColor.text_light, fontSize: sizes.h3 }}>
            Vehicle
          </span>
        ),
        dataIndex: "vCat"
      },
      {
        title: (
          <span style={{ color: textColor.text_light, fontSize: sizes.h3 }}>
            Driver
          </span>
        ),
        dataIndex: "vType"
      },
      {
        title: (
          <span style={{ color: textColor.text_light, fontSize: sizes.h3 }}>
            Qty
          </span>
        ),
        dataIndex: "qty"
      },
      {
        title: "",
        dataIndex: "",
        key: "x",
        render: (text, record) =>
          this.state.BFData.length >= 1 ? (
            <Popconfirm
              title="Sure to remove?"
              onConfirm={() => this.handleDelete(record.key)}
            >
              <Button
                shape="circle"
                size="small"
                icon="cross"
                type="ghost"
                style={{ color: "red", borderColor: "red" }}
              />
            </Popconfirm>
          ) : null
      }
    ];
  }

  getImageUrlFromChild = imageUrl => {
    if (imageUrl === false) {
      this.setState({ profilePic: false });
    } else {
      this.setState({ profilePic: imageUrl });
    }
  };
  getMultiImageUrlFromChild1 = urls => {
    this.setState({ multiUrl1: urls });
  };
  handleDelete = key => {
    const dataSource = [...this.state.BFData];
    this.setState({ BFData: dataSource.filter(item => item.key !== key) });
  };

  handleAdd = key => {
    if (
      this.state.vCat !== "" &&
      this.state.vCat !== undefined &&
      this.state.vType !== "" &&
      this.state.vType !== undefined &&
      this.state.qty > 0 &&
      this.state.qty !== "" &&
      this.state.qty !== undefined
    ) {
      const newData = {
        key: this.state.count1,
        vCat: this.state.vCat,
        vType: this.state.vType,
        vTypeId: this.state.vTypeId,
        qty: this.state.qty
      };
      this.setState({
        BFData: [...this.state.BFData, newData],
        count1: this.state.count1 + 1,
        vCat: "",
        vType: "",
        qty: 1
      });
    } else {
      message.error("You have to Select Cat then and Type");
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        console.log("Received values of form: ", values);
        let vTypeA = [];
        this.state.BFData.map((val, ind) => {
          vTypeA.push({
            vehicleType: {
              id: val.vTypeId,
              quantity: val.qty
            },
            driverName: "Test",
            vehicleName: "Test"
          });
        });
        const {
          onBrokerAddRequest,
          userInfo,
          onChangeBrokerModalVisible,
          onGetLogData,
          onAddBrokerPush,
          onBrokerEditRequest,
          onAllBrokersInfoGet,
          onChangeNavName,
          onGetDetailBrokerData
        } = this.props;
        console.log(vTypeA);

        if (userInfo !== {}) {
          const data = {
            name: values.fullName,
            companyName: values.company ? values.company : null,
            email: null,
            phoneNumber: "+92" + values.mobileNumber,
            cnicNo: values.cnic ? values.cnic : null,
            secondaryNumber: null,
            address: {
              street: values.streetAddress ? values.streetAddress : null,
              city: values.city ? values.city : null
            },
            documentPictures: this.state.multiUrl1.length !== 0 ? this.state.multiUrl1 : null,
            dedicatedFleet: this.state.BFData.length > 0 ? vTypeA : null,
            companyNumber: null,
            accountCreatedDate: moment().toISOString(),
            profilePicture: this.state.profilePic
              ? this.state.profilePic
              : this.state.picArra[Math.floor(Math.random() * Math.floor(3))],
            userId: userInfo.user._id
          };
          const data2 = {
            brokerId: this.props.data ? this.props.data._id : "",
            name: values.fullName,
            companyName: values.company ? values.company : null,
            email: null,
            phoneNumber: "+92" + values.mobileNumber,
            cnicNo: values.cnic ? values.cnic : null,
            secondaryNumber: null,
            address: {
              street: values.streetAddress ? values.streetAddress : null,
              city: values.city ? values.city : null
            },
            documentPictures:this.state.multiUrl1.length !== 0 ? this.state.multiUrl1 : null,
            dedicatedFleet: this.state.BFData.length > 0 ? vTypeA : null,
            companyNumber: null,
            profilePicture: this.state.profilePic
              ? this.state.profilePic
              : this.state.picArra[Math.floor(Math.random() * Math.floor(3))],
            userId: userInfo.user._id
          };
          if (this.props.data) {
            const hello2 = async () => {
              let response = await onBrokerEditRequest(data2);
              if (response.payload.data !== "") {
                onChangeBrokerModalVisible(false);
                message.success("Client Updated");
                onChangeNavName({
                  first: "Brokers",
                  second: "Detail",
                  third: values.fullName
                });
                onAllBrokersInfoGet({ id: userInfo.user._id });
                onGetDetailBrokerData({
                  id: this.props.data._id,
                  userId: userInfo.user._id
                });
              }
            };
            hello2();
          } else {
            const hello = async () => {
              let response = await onBrokerAddRequest(data);
              if (response.payload.data !== "") {
                onAddBrokerPush(response.payload.data);
                onChangeBrokerModalVisible(false);
                onGetLogData({ id: userInfo.user._id });
                message.success("Broker Added");
              }
            };
            hello();
          }
        }
      }
    });
  };
  render() {
    const {
      brokerModalVisible,
      onChangeBrokerModalVisible,
      loading,
      vehicalCat,
      vehicleType
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={null}
        footer={null}
        header={null}
        visible={brokerModalVisible}
        onOk={() => {
          alert("OK");
        }}
        maskClosable={false}
        onCancel={() => {
          onChangeBrokerModalVisible(false);
        }}
        width={900}
        bodyStyle={{ padding: 0, borderRadius: 0 }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            className="hideSideModal"
            style={{ height: 550, width: 400, backgroundColor: "#F9690E" }}
          >
            <ModalCompoentLeftComtent
              mainHeading={"Add Transporters"}
              extraText={"Add Transporter Details"}
              Image={Images.BROKER}
            />
          </div>
          <div
            style={{
              width: "100%",
              height: 550,
              overflowY: "scroll",
              paddingTop: 45,
              paddingBottom: 45,
              paddingLeft: 45,
              paddingRight: 45
            }}
          >
            <Form onSubmit={this.handleSubmit} spellCheck={true}>
              <ApnaDivider text={"Information"} />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "70%"
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Col span={9} style={{ paddingTop: 5 }}>
                      <span
                        style={{
                          fontSize: sizes.h3,
                          color: textColor.text_dark
                        }}
                      >
                       * Name :
                      </span>
                    </Col>
                    <Col span={15}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Form.Item style={{ lineHeight: "15px" }}>
                          {getFieldDecorator("fullName", {
                            rules: [
                              {
                                required: true,
                                message: "Please Enter Name",
                                min: 3,
                                max: 50
                              }
                            ]
                          })(
                            <Input
                              style={{
                                width: 230,
                                paddingRight: 10,
                                marginLeft: -8
                              }}
                              placeholder="Enter Full Name"
                              type="text"
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Col span={9} style={{ paddingTop: 5 }}>
                      <span
                        style={{
                          fontSize: sizes.h3,
                          color: textColor.text_dark
                        }}
                      >
                        Company :
                      </span>
                    </Col>
                    <Col span={15}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Form.Item>
                          {getFieldDecorator("company", {
                            rules: [
                              {
                                min: 3,
                                max: 50
                              }
                            ]
                          })(
                            <Input
                              style={{
                                width: 230,
                                paddingRight: 10,
                                marginLeft: -8
                              }}
                              placeholder="Enter Company (Optional)"
                              type="text"
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "30%",
                    paddingLeft: 30
                  }}
                >
                  <UploadSingleImage
                    getImageUrlFromChild={this.getImageUrlFromChild}
                    profile={this.state.profilePic}
                  />
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Col span={6} style={{ paddingTop: 5 }}>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_dark }}
                  >
                   * Mobile :
                  </span>
                </Col>
                <Col span={18}>
                  <Input.Group>
                    <Col span={4}>
                      <Input
                        style={{
                          borderRadius: 0
                        }}
                        defaultValue="+92"
                        disabled
                      />
                    </Col>
                    <Col span={20}>
                      <Form.Item>
                        {getFieldDecorator("mobileNumber", {
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Mobile No"
                            }
                          ]
                        })(
                          <Input
                            pattern="^()-{0,1}3{1}\d{2}-{0,1}\d{7}$|^0{0,1}3{1}\d{10}$|^0{0,1}3{1}\d{2}-\d{7}$"
                            style={{ width: "103%", marginLeft: -8 }}
                            placeholder="Enter Mobile No"
                            type="text"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Input.Group>
                </Col>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Col span={6} style={{ paddingTop: 5 }}>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_dark }}
                  >
                   * Address :
                  </span>
                </Col>
                <Col span={18}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item>
                      {getFieldDecorator("streetAddress", {})(
                        <Input
                          style={{ width: 230, marginRight: 10 }}
                          placeholder="Enter Street Address"
                          type="text"
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("city", {})(
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: 154, marginLeft: 10 }}
                          placeholder="Select City"
                        >
                          {childrenCities}
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </div>
              <ApnaDivider text={"Documents"} />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Col span={6} style={{ paddingTop: 5 }}>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_dark }}
                  >
                    CNIC Number :
                  </span>
                </Col>
                <Col span={18}>
                  <Form.Item>
                    {getFieldDecorator("cnic", {
                      rules: [
                        {
                          max: 13
                        }
                      ]
                    })(
                      <Input
                        pattern="^\d{5}[- .]?\d{7}[- .]?\d{1}$"
                        style={{ width: "100%", marginRight: 10 }}
                        placeholder="Enter CNIC Number (Optional) i.e 4410377409332"
                        type="number"
                      />
                    )}
                  </Form.Item>
                </Col>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10
                }}
              >
                <Col span={6} style={{ paddingTop: 5 }}>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_dark }}
                  >
                    Documents :
                  </span>
                </Col>
                <Col span={18}>
                  <UploadImageMultiple
                    getMultiImageUrlFromChild={this.getMultiImageUrlFromChild1}
                    picsArray={this.state.multiUrl1}
                  />
                </Col>
              </div>
              <ApnaDivider text={"* Broker's Fleet"} />
              <Row style={{ marginBottom: 20,  height:'200px',
                overflow:'scroll' }}>
                <Col span={24}>
                  <Table
                    pagination={false}
                    columns={this.BFcolumns}
                    dataSource={this.state.BFData}
                    size="middle"
                  />
                </Col>
              </Row>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 20
                }}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value= {this.state.vCat?this.state.vCat:"Vehicle Category" }
                  onChange={(e) => {
                    this.setState({ vCat: e, });
                  }}
                  style={{ width: 200, paddingRight: 10 }}
               
                >
                  {vehicalCat
                    ? vehicalCat.map((val, ind) => {
                      return (
                        <Option key={val._id} value={val.title}>
                          {val.title}
                        </Option>
                      );
                    })
                    : ""}
                </Select>
                <Select
                  showSearch
                  optionFilterProp="children"
           
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(e,v) => {
                    this.setState({ vType:v.props.children ,vTypeId:e });
                  }}
                  style={{ width: 200, paddingRight: 10 }}
                  value= {this.state.vType?this.state.vType:"Vehicle Type" }
                >
                  {vehicleType
                    ? vehicleType.map((val, ind) => {
                      return (
                        <Option key={val._id} value={val._id}>
                          {val.title}
                        </Option>
                      );
                    })
                    : ""}
                </Select>
                <InputNumber
                  min={1}
                  max={10}
                  style={{ width: 80, marginRight: 5 }}
                  placeholder="Qty"
                  onChange={e => {
                    e ? this.setState({ qty: e }) : this.setState({ qty: 1 });
                  }}
                  value={this.state.qty}
                />
                <Button
                  icon="plus"
                  type="primary"
                  shape="circle"
                  size="small"
                  style={{ marginLeft: 10, marginTop: 5 }}
                  onClick={this.handleAdd}
                />
              </div>
              <div>
                <div style={{ float: "right" }}>
                  <Button
                    type="default"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      onChangeBrokerModalVisible(false);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  }
  componentWillMount() {
    const { data } = this.props;
    if (data) {
      let arr1 = [];
      let count1 = 1;
      if (
        data.dedicatedFleet !== null &&
        data.dedicatedFleet !== "" &&
        data.dedicatedFleet !== undefined
      ) {
        data.dedicatedFleet.map((val, ind) => {
          arr1.push({
            key: count1,
            vCat: val.vehicleCategory.title,
            vType: val.vehicleType.title,
            vTypeId: val.vehicleType.id,
            qty: val.vehicleType.quantity
          });
          count1 = count1 + 1;
        });
        this.setState({ BFData: arr1, count1: count1 });
      }
    }
  }

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      if (
        data.documentPictures !== null &&
        data.documentPictures !== undefined
      ) {
        let vPics = [];
        data.documentPictures.map((val, ind) => {
          vPics.push(val);
        });
        this.setState({ multiUrl1: vPics });
      }
      this.setState({ profilePic: data.profilePicture });
      this.props.form.setFields({
        fullName: {
          value: this.props.data.name ? this.props.data.name : ""
        },
        mobileNumber: {
          value: this.props.data.phoneNumber
            ? this.props.data.phoneNumber.substring(3)
            : ""
        },
        email: {
          value: this.props.data.email ? this.props.data.email : ""
        },
        streetAddress: {
          value: this.props.data.address ? this.props.data.address.street : ""
        },
        city: {
          value: this.props.data.address ? this.props.data.address.city : ""
        },
        cnic: {
          value: this.props.data.cnicNo ? this.props.data.cnicNo : ""
        },
        company: {
          value: this.props.data.companyName ? this.props.data.companyName : ""
        }
      });
    }
  }
}
const BrokerModal = Form.create()(BrokerModalForm);

const mapStateToProps = ({ brokers, signup, global }) => ({
  brokerModalVisible: brokers.brokerModalVisible,
  userInfo: signup.content,
  loading: brokers.loading,
  vehicalCat: global.globalData.vehicleCategory,
  vehicleType: global.globalData.vehicleType
});
const mapDispatchToProps = dispatch => ({
  onChangeBrokerModalVisible: visible =>
    dispatch(Actions.ON_CHANGE_BROKER_MODAL_VISIBLE(visible)),
  onBrokerAddRequest: data => dispatch(Actions.onBrokerAddRequest(data)),
  onAddBrokerPush: data =>
    dispatch(ActionsGlobal.ON_BROKER_ADDED_PUSH_TO_ALL(data)),
  onGetLogData: data => dispatch(ActionsGlobal.onGetLogData(data)),

  onBrokerEditRequest: data => dispatch(Actions.onBrokerEditRequest(data)),
  onAllBrokersInfoGet: data =>
    dispatch(ActionsGlobal.onAllBrokersInfoGet(data)),
  onChangeNavName: navName =>
    dispatch(ActionsGlobal.ON_CHANGE_NAV_NAME(navName)),

  onGetDetailBrokerData: data =>
    dispatch(ActionsDetail.onGetDetailBrokerData(data))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrokerModal);
