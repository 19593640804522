import React from "react";
import {
  Steps,
  Button,
  Input,
  Row,
  Col,
  Checkbox,
  Select,
  Layout,
  message
} from "antd";
import countries from "../../constants/jsons/countries.json";
import cities from "../../constants/jsons/cities.json";
import { isMobile } from "../../constants/constants.js";
import { connect } from "react-redux";
import * as Actions from "./action/actionCreater";
import moment from "moment";
const Option = Select.Option;
const InputGroup = Input.Group;
// this code is for to select the country form an array/json imported above
const childrenCountry = [];
countries.forEach(element => {
  childrenCountry.push(<Option key={element.name}>{element.name}</Option>);
});
const childrenCities = [];
childrenCities.length = 0;
if (cities["Pakistan"] === undefined) {
} else {
  cities["Pakistan"].forEach(element => {
    childrenCities.push(<Option key={element}>{element}</Option>);
  });
}
class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
      address: "",
      country: "Pakistan",
      city: undefined,
      companyRegisteredName: "",
      companyEmailAddress: "",
      companyPhoneNumber: "",
      companyCity: undefined,
      companyCountry: "Pakistan",
      companyAddress: "",
      fleetSize: undefined,
      check: true,
      check2: true,
      signupText: "Signup",
      current: 0,
      err: "",
      hide: false,
      hide2: false,
      disabled: true
    };
  }

  componentWillMount(){
    const { SET_LOADING_FALSE } = this.props;
    SET_LOADING_FALSE(false)
  }


  next() {
    const namec = /^([a-zA-Z])*$/.test(this.state.firstName);
    if (namec === false && this.state.firstName !== "" || this.state.firstName.length<3) {
      this.setState({ err: "Please input correct Name / Name should be greater than three words" });
    } else if (
      !/^([a-zA-Z])*$/.test(this.state.lastName) &&
      this.state.lastName !== ""
    ) {
      this.setState({ err: "Please input correct Last Name" });
    } else if (this.state.password.length < 6 && this.state.password !== "") {
      this.setState({ err: "Please input Valid length Password" });
    } else if (
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
        this.state.email
      ) &&
      this.state.email !== ""
    ) {
      this.setState({ err: "Please input correct Email Address" });
    } else if (
      this.state.password !== this.state.confirmPassword &&
      this.state.confirmPassword !== ""
    ) {
      this.setState({ err: "Confrim password Does not Match" });
    } else if (
      !/^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/.test(
        "+92" + this.state.phoneNumber
      ) &&
      this.state.phoneNumber !== ""
    ) {
      this.setState({ err: "Please input correct Mobile Number" });
    } else {
      const current = this.state.current + 1;
      this.setState({
        current: current,
        err: ""
      });
    }
  }
  next2() {
    if (
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
        this.state.companyEmailAddress
      ) &&
      this.state.companyEmailAddress !== ""
    ) {
      this.setState({ err: "Please input correct company email address" });
    }
    else if (this.state.companyRegisteredName.length<3) {
      this.setState({ err: "Company Name Should not be less than three words" });
    }
    else if (
      !/^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/.test(
        "+92"+this.state.companyPhoneNumber
      ) &&
      this.state.companyPhoneNumber !== ""
    ) {
      this.setState({ err: "Please input correct company Phone Number" });
    } else {
      const current = this.state.current + 1;
      this.setState({
        current: current,
        err: ""
      });
    }
  }
 
  prev() {
    const current = this.state.current - 1;
    this.setState({ current: current });
  }
  onSubmit() {
    const {onUserSignUp} = this.props;
    this.setState({signupText: "Signing In" });
    const data = {
      name: this.state.firstName + " " + this.state.lastName,
      password: this.state.password,
      email: this.state.email,
      companyName: this.state.companyRegisteredName,
      phoneNumber: "+92" + this.state.phoneNumber,
      address: {street:this.state.address, city:this.state.city},
      companyNumber: this.state.companyPhoneNumber?this.state.companyPhoneNumber:null,
      estimatedFleetSize:{min:1,max:5},
      profilePic:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1Gevu4Tk803Ydc4VywH_ANoJSf3B6rnuI64IChMJSdw9qfR7s",
      accountCreatedDate:moment().toISOString(),
      type:"Transporter"
    };
  
    const hello = async () => {
      let response  = await onUserSignUp(data);
      if (response.payload.data!=='') {
        localStorage.setItem('AUTH_TOKEN',response.payload.data.jwtToken);
        this.props.history.push('/auth')
        message.success('Account Created')
      }
      console.log(response)
    }
    hello()
    
  }
 
  //  this portion is for step no1 validation
  renderError = hide => {
    if (hide === false) {
      return <h4 style={{ color: "red" }}>{this.state.err}</h4>;
    } else {
    }
  };
  renderError2 = hide2 => {
    if (hide2 === false || hide2 === undefined) {
      return <h4 style={{ color: "red" }}>{this.state.err}</h4>;
    } else {
    }
  };

  // this will return err in step 2
  render() {
    const {loading} = this.props;
    const Step = Steps.Step;
  
    const steps = [
      {
        title: "Step 1",
        content: (
          <div style={{height:"100%"}}>
            <h3 style={{marginTop:10,color:"white"}}>Personal Information</h3>
            {this.renderError(this.state.hide)}

            <Input
              className="ant-inputmy"
              type="text"
              placeholder="First Name"
              style={{
                padding: "20px",
                width: isMobile ? "100%" : "49%",
                marginRight: "10px",
                marginTop: "10px"
              }}
              onChange={e => {
                this.setState({
                  firstName: e.target.value
                });
              }}
              value={this.state.firstName}
            />
            <Input
              type="text"
              placeholder="Last Name"
              style={{
                padding: "20px",
                width: isMobile ? "100%" : "49%",
                marginTop: "10px"
              }}
              onChange={e => this.setState({ lastName: e.target.value })}
              value={this.state.lastName}
            />
            <Input
              type="email"
              placeholder="Email Address"
              style={{ padding: "20px", marginTop: "10px" }}
              onChange={e => this.setState({ email: e.target.value })}
              value={this.state.email}
            />
            <Input.Password
              size="large"
              type="password"
              placeholder="Password"
              style={{
                width: isMobile ? "100%" : "49%",
                marginRight: "10px",
                marginTop: "10px"
              }}
              onChange={e => this.setState({ password: e.target.value })}
              value={this.state.password}
            />
            <Input.Password
              size="large"
              type="password"
              placeholder="Confirm Password"
              style={{
                width: isMobile ? "100%" : "49%",
                marginTop: "10px"
              }}
              onChange={e =>
                this.setState({
                  confirmPassword: e.target.value
                })
              }
              value={this.state.confirmPassword}
            />
            <InputGroup size="large">
              <Col span={5}>
                <Input
                  defaultValue="+92"
                  disabled
                  style={{ padding: "20px", marginTop: "10px" }}
                />
              </Col>
              <Col span={19}>
                <Input
                  type="text"
                  placeholder="Mobile Number (eg :3033354715)"
                  style={{ padding: "20px", marginTop: "10px" }}
                  onChange={e => this.setState({ phoneNumber: e.target.value })}
                  value={this.state.phoneNumber}
                />
              </Col>
            </InputGroup>
            <Input
              type="text"
              placeholder="Address (Optional)"
              style={{ padding: "20px", marginTop: "10px" }}
              onChange={e => this.setState({ address: e.target.value })}
              value={this.state.address}
            />
            <Select
              showArrow={true}
              showSearch
              size="large"
              disabled
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={e => {
                this.setState({ country: e }, () => {
                  childrenCities.length = 0;
                  if (cities[e] === undefined) {
                  } else {
                    cities[e].forEach(element => {
                      childrenCities.push(
                        <Option key={element}>{element}</Option>
                      );
                    });
                  }
                });
              }}
              placeholder="Please select country"
              style={{ width: "100%", paddingTop: "10px" }}
              value={this.state.country}
            >
              {childrenCountry}
            </Select>
            <Select
              showArrow={true}
              showSearch
              size="large"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Please select city"
              onChange={e => this.setState({ city: e })}
              style={{ width: "100%", paddingTop: "10px" }}
              value={this.state.city}
            >
              {childrenCities}
            </Select>
            <div className="steps-action" style={{ textAlign:"end",marginTop:20 }}>
              <Button
                type="primary"
                onClick={() => this.next()}
                disabled={
                  !this.state.firstName ||
                  !this.state.lastName ||
                  !this.state.email ||
                  !this.state.password ||
                  !this.state.confirmPassword ||
                  !this.state.phoneNumber ||
                  !this.state.city ||
                  !this.state.country
                }
              >
                Next
              </Button>
            </div>
          </div>
        )
      },
      {
        title: "Step 2",
        content: (
          <main>
            <h3 style={{marginTop:10,color:"white"}}>Company Information</h3>
            {this.renderError2(this.state.hide2)}
            <Input
              type="text"
              placeholder="Company Registered Name"
              style={{
                padding: "20px",
                width: isMobile ? "100%" : "49%",
                marginRight: "10px"
              }}
              onChange={e =>
                this.setState({ companyRegisteredName: e.target.value })
              }
              value={this.state.companyRegisteredName}
            />
            <Input
              type="email"
              placeholder="Company Email Address"
              style={{ padding: "20px", width: isMobile ? "100%" : "49%" }}
              onChange={e => {
                this.setState({ companyEmailAddress: e.target.value });
              }}
              value={this.state.companyEmailAddress}
            />
            <InputGroup size="large">
              <Col span={5}>
                <Input
                  defaultValue="+92"
                  disabled
                  style={{ padding: "20px", marginTop: "10px" }}
                />
              </Col>
              <Col span={19}>
                <Input
                  type="text"
                  placeholder="Company Phone Number (Optional)"
                  style={{ padding: "20px", marginTop: "10px" }}
                  onChange={e =>
                    this.setState({ companyPhoneNumber: e.target.value })
                  }
                  value={this.state.companyPhoneNumber}
                />
              </Col>
            </InputGroup>
            
            <Input
              type="text"
              placeholder="Company Address (Optional)"
              style={{ padding: "20px", marginTop: "10px" }}
              onChange={e => this.setState({ companyAddress: e.target.value })}
              value={this.state.companyAddress}
            />
            <Select
              showSearch
              showArrow={true}
              size="large"
              disabled
              onChange={e => {
                this.setState({ companyCountry: e }, () => {
                  childrenCities.length = 0;
                  if (cities[e] === undefined) {
                  } else {
                    cities[e].forEach(element => {
                      childrenCities.push(
                        <Option key={element}>{element}</Option>
                      );
                    });
                  }
                });
              }}
              placeholder="Please select country"
              style={{ width: "100%", paddingTop: "10px", marginTop: "10px" }}
              value={this.state.companyCountry}
            >
              {childrenCountry}
            </Select>
            <Select
              showSearch
              showArrow={true}
              size="large"
              placeholder="Please select city"
              onChange={e => this.setState({ companyCity: e })}
              style={{ width: "100%", paddingTop: "10px", marginTop: "10px" }}
              value={this.state.companyCity}
            >
              {childrenCities}
            </Select>
            <Select
              showSearch
              showArrow={true}
              size="large"
              placeholder="Please select Fleet Size"
              onChange={e => this.setState({ fleetSize: e })}
              style={{ width: "100%", paddingTop: "10px", marginTop: "10px" }}
              value={this.state.fleetSize}
            >
              <Option key="0-5">0-5</Option>
              <Option key="5-10">5-10</Option>
              <Option key="10">10</Option>
              <Option key="20">20</Option>
              <Option key="20+">20+</Option>
            </Select>

            <div className="steps-action" style={{ textAlign: "end",marginTop:15 }}>
              <Button style={{ marginRight: 8 }} onClick={() => this.prev()}>
                Return
              </Button>
              <Button
                type="primary"
                onClick={() => this.next2()}
                disabled={
                  !this.state.companyRegisteredName ||
                  !this.state.companyEmailAddress ||
                  !this.state.companyEmailAddress ||
                  !this.state.companyCity ||
                  !this.state.companyCountry ||
                  !this.state.fleetSize
                }
              >
                Next
              </Button>
            </div>
          </main>
        )
      },
      {
        title: "Step 3",
        content: (
          <main>
            <h3 style={{marginTop:10,color:"white"}}>Confirmation and Agreement</h3>
            <div
              onScrollCapture={() => {
                this.setState({ check2: false });
              }}
              className="signup_agreement"
            >
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est
                laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.nim
                id est laborum.Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              </p>
            </div>
            <Row>
              <div className="signup_agreement_checkbox">
                <Checkbox
                  disabled={this.state.check2}
                  onChange={e => {
                    if (e.target.checked === true) {
                      this.setState({ check: false });
                    } else {
                      this.setState({ check: true });
                    }
                  }}
                >
                  I agree with term and conditions
                </Checkbox>
              </div>
            </Row>
            <div className="steps-action" style={{ textAlign: "end",marginTop:15 }}>
              <Button
                style={{ marginRight: 8 }}
                onClick={() => this.prev()}
                disabled={loading}
              >
                Return
              </Button>
              <Button
                type="primary"
                onClick={this.onSubmit.bind(this)}
                loading={loading}
                disabled={this.state.check}
              >
                {this.state.signupText}
              </Button>
            </div>
          </main>
        )
      }
    ];
    const { Footer } = Layout;
    return (
      <main>
        <div className="signup_view">
          <Row>
            <Col>
              <div className="signup_container">
                <h5 className="signup_texted">Create an Account</h5>
                <Steps current={this.state.current} size="small">
                  {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
                <div className="steps-content">
                  {steps[this.state.current].content}
                </div>
              </div>
            </Col>
          </Row>
          <Footer
            className="login_footer_main"
            style={{
              backgroundColor: "rgba(11,11,11,0.3)",
              position: "fixed",
              bottom: "0",
              width: "100%"
            }}
          >
            <div>
              <h4 className="login_footer_text">
                Trukkr. All Rights Reserved © 2019 | Developed by{" "}
                <b>Trukkr Team</b>
              </h4>
            </div>
          </Footer>
        </div>
      </main>
    );
  }
}
const mapStateToProps = ({ signup }) => ({
  loading: signup.loading,
  err:signup.err,
  success:signup.success
});
const mapDispatchToProps = dispatch => ({
  onUserSignUp: (data) =>
    dispatch(Actions.onUserSignUp(data)),
  SET_LOADING_FALSE: (data) =>
    dispatch(Actions.SET_LOADING_FALSE(data)),
    
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);