import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

const columns = [
  {
    title: 'Name',
    width: '25%',
    dataIndex: 'Name',
    key: 'Name',
  },
  {
    title: 'Mobile',
    dataIndex: 'Mobile',
    width: '25%',
    key: 'Mobile',
  },
  {
    title: 'Email',
    dataIndex: 'Email',
    width: '25%',
    key: 'Email',
  },
  {
    title: 'Designation',
    dataIndex: 'Desig',
    width: '25%',
    key: 'Desig',
  },
];

const CPtable = (props) => {
  let data2 = [];
  props.data.map((val,ind)=>{
    data2.push({
      key: ind,
      Name: val.name,
      Email: val.email,
      Mobile: val.mobileNumber,
      Desig:val.designation 
    })
  }) 
  return(
    <Table
      dataSource={data2}
      columns={columns}
      pagination={{ pageSize: 5 }}
      style={{ minheight: 250, borderRadius: 2, backgroundColor: 'white' }}
      size="middle"
      scroll={{ x: '100%', y: false }}
    />

  );
}
export default CPtable;
CPtable.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
};
CPtable.defaultProps = {
  data: [],
  type: 'Trips',
};