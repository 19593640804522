import {
  ON_DRIVER_DETAIL_GET_REQUEST,ON_VEHICLE_DETAIL_GET_REQUEST,ON_CLIENT_DETAIL_GET_REQUEST,ON_BROKER_DETAIL_GET_REQUEST,ON_DELETE_REQUEST_DVCB
} from "../action/actionTypes";
import { message } from "antd";
export default (
  state = {
    loading:false,
    detailData:""
  },
  action
) => {
  switch (action.type) {  
    case ON_DRIVER_DETAIL_GET_REQUEST[0]:
      return { ...state, loading: true };
    case ON_DRIVER_DETAIL_GET_REQUEST[1]:
      return { ...state, loading: false, detailData: action.payload.data };
    case ON_DRIVER_DETAIL_GET_REQUEST[2]:
      message.error(
        action.error.response
          ? action.error.response.data.message
          : "server is not responding"
      );
      return { ...state, loading: false, detailData: "" };

    case ON_VEHICLE_DETAIL_GET_REQUEST[0]:
      return { ...state, loading: true };
    case ON_VEHICLE_DETAIL_GET_REQUEST[1]:
      return { ...state, loading: false, detailData: action.payload.data };
    case ON_VEHICLE_DETAIL_GET_REQUEST[2]:
      message.error(
        action.error.response
          ? action.error.response.data.message
          : "server is not responding"
      );
      return { ...state, loading: false, detailData: "" };

    case ON_CLIENT_DETAIL_GET_REQUEST[0]:
      return { ...state, loading: true };
    case ON_CLIENT_DETAIL_GET_REQUEST[1]:
      return { ...state, loading: false, detailData: action.payload.data };
    case ON_CLIENT_DETAIL_GET_REQUEST[2]:
      message.error(
        action.error.response
          ? action.error.response.data.message
          : "server is not responding"
      );
      return { ...state, loading: false, detailData: "" };

    case ON_BROKER_DETAIL_GET_REQUEST[0]:
      return { ...state, loading: true };
    case ON_BROKER_DETAIL_GET_REQUEST[1]:
      return { ...state, loading: false, detailData: action.payload.data };
    case ON_BROKER_DETAIL_GET_REQUEST[2]:
      message.error(
        action.error.response
          ? action.error.response.data.message
          : "server is not responding"
      );
      return { ...state, loading: false, detailData: "" };

    case ON_DELETE_REQUEST_DVCB[0]:
      return { ...state, loading: true };
    case ON_DELETE_REQUEST_DVCB[1]:
      return { ...state, loading: false, detailData:""};
    case ON_DELETE_REQUEST_DVCB[2]:
      // message.error(
      //   action.error.response
      //     ? action.error.response.data.message
      //     : "server is not responding"
      // );
      return { ...state, loading: false, detailData:"" };

    default:
      return state;
  }
};
  