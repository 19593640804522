import React, { Component } from "react";
import { Card, Icon, Avatar, Col, Row } from "antd";
import { sizes, textColor } from "../../../constants/constants";
import * as Actions from "../sidebar/action/actionCreater";
import { connect } from "react-redux";

class Profile extends Component {
  render() {
    const { userInfo,onChangeDetailData,onChangeNavName } = this.props;
    return (
      <div className="dashboardMain">
        <Card
          style={{ width: "100%" }}
          actions={[
            <Icon type="setting" />,
            <Icon type="edit" />,
            <Icon
              type="export"
              onClick={() => {
                onChangeNavName({
                first: "Dashboard",
                second: ""
              });
              onChangeDetailData({
                  type: "Dashboard",
                  id: ""
              });
                localStorage.removeItem("AUTH_TOKEN");
                this.props.history.push("/");
                window.location.reload();
              }}
            />
          ]}
        >
          <Avatar
            src="https://cdn.pixabay.com/photo/2015/03/04/22/35/head-659652__340.png"
            style={{ height: 70, width: 70 }}
            size="large"
          />
          <span
            style={{
              fontSize: sizes.headingMiddle,
              color: textColor.text_dark
            }}
          >
            {userInfo.user ? userInfo.user.name : "No Name"}
          </span>
          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <span style={{ fontSize: sizes.h2, color: textColor.text_dark }}>
                Full Name:
              </span>
            </Col>
            <Col span={18}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_midle }}>
                {userInfo.user ? userInfo.user.name : "No Name"}
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <span style={{ fontSize: sizes.h2, color: textColor.text_dark }}>
                Mobile Number:
              </span>
            </Col>
            <Col span={18}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_midle }}>
                {userInfo.user ? userInfo.user.phoneNumber : "Not Assigned"}
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <span style={{ fontSize: sizes.h2, color: textColor.text_dark }}>
                Account Status:
              </span>
            </Col>
            <Col span={18}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_midle }}>
                {userInfo.user ? userInfo.user.accountStatus : "Not Assigned"}
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <span style={{ fontSize: sizes.h2, color: textColor.text_dark }}>
                Address:
              </span>
            </Col>
            <Col span={18}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_midle }}>
                {userInfo.user
                  ? userInfo.user.address.street + userInfo.user.address.city
                  : "Not Assigned"}
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <span style={{ fontSize: sizes.h2, color: textColor.text_dark }}>
                Company Name:
              </span>
            </Col>
            <Col span={18}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_midle }}>
                {userInfo.user ? userInfo.user.companyName : "Not Assigned"}
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <span style={{ fontSize: sizes.h2, color: textColor.text_dark }}>
                Email Address:
              </span>
            </Col>
            <Col span={18}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_midle }}>
                {userInfo.user ? userInfo.user.email : "Not Assigned"}
              </span>
            </Col>
          </Row>
          <Row style={{ paddingTop: 90 }}>
            <Col span={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: -45
                }}
              >
                <span
                  style={{
                    fontSize: sizes.h2,
                    color: textColor.text_dark,
                    paddingBottom: 10
                  }}
                >
                  Two Way Authentication
                </span>
                <Icon type="arrow-down" style={{ marginBottom: -10 }} />
              </div>
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: -10
                }}
              >
                <span
                  style={{
                    fontSize: sizes.h2,
                    color: textColor.text_dark,
                    paddingBottom: 10
                  }}
                >
                  Edit Profile
                </span>
                <Icon type="arrow-down" style={{ marginBottom: -10 }} />
              </div>
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{ fontSize: sizes.h2, color: textColor.text_dark }}
                >
                  {/* Log out */}
                </span>
                {/* <Icon type="arrow-down" style={{marginBottom:-10}}></Icon> */}
              </div>
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 40
                }}
              >
                <span
                  style={{
                    fontSize: sizes.h2,
                    color: textColor.text_dark,
                    paddingBottom: 10
                  }}
                >
                  Signout
                </span>
                <Icon type="arrow-down" style={{ marginBottom: -10 }} />
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  userInfo: state.signup.content
});

const mapDispatchToProps = dispatch => ({
  onChangeActiveNav: activeNav =>
    dispatch(Actions.ON_CHANGE_ACTIVE_NAV(activeNav)),
  onChangeNavName: navName => dispatch(Actions.ON_CHANGE_NAV_NAME(navName)),
  onChangeDetailData: data => dispatch(Actions.ON_CHANGE_DETAIL_DATA(data)),
  onChangeFilterData: filter => dispatch(Actions.ON_CHANGE_FILTER_DATA(filter)),
  
});

  
export default connect(mapStateToProps,mapDispatchToProps)(Profile);
