import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  BarChart,
  Tooltip,
  Bar,
  XAxis,
  YAxis,
} from 'recharts';

const dataCharts = [
  {
    x: 'M',
    y: '10',
    trips: 12,
  },
  {
    x: 'T',
    y: '20',
    trips: 26,
  },
  {
    x: 'W',
    y: '40',
    trips: 35,
  },
  {
    x: 'T',
    y: '60',
    trips: 80,
  },
  {
    x: 'S',
    y: '75',
    trips: 60,
  },
  {
    x: 'S',
    y: '90',
    trips: 33,
  },
  {
    x: 'F',
    y: '25',
    trips: 44,
  },
  {
    x: 'M',
    y: '100',
    trips: 95,
  },
  {
    x: 'T',
    y: '65',
    trips: 65,
  },
  {
    x: 'W',
    y: '95',
    trips: 95,
  },
  {
    x: 'T',
    y: '44',
    trips: 44,
  },
  {
    x: 'F',
    y: '25',
    trips: 25,
  },
  {
    x: 'S',
    y: '88',
    trips: 88,
  },
  {
    x: 'S',
    y: '45',
    trips: 45,
  },
];
const GraphComponent = () => (
  <div style={{ height: 115 }} className="ant-card">
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        barCategoryGap="20%"
        margin={{
          top: 20,
          right: 10,
          bottom: 1,
          left: -20,
        }}
        data={dataCharts}
      >
        {/* <CartesianGrid /> */}
        <XAxis dataKey="x" axisLine={false} tickLine={false} />
        <YAxis dataKey="y" axisLine={false} tickLine={false} />
        {/* <Legend /> */}
        <Tooltip
          separator=":"
          offset={20}
          cursor={{ backgroundColor: 'red' }}
        />
        <Bar dataKey="trips" fill="#33abfb" fillOpacity={0.85} />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default GraphComponent;

GraphComponent.propTypes = {
  type2: PropTypes.string,
  type: PropTypes.string,
  value1: PropTypes.string,
  value2: PropTypes.string,
  status: PropTypes.string,
};
GraphComponent.defaultProps = {
  type: 'Type',
  type2: 'Type',
  value1: '0',
  value2: '0',
  status: '0',
};
