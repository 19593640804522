import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Col, Icon, Avatar, Badge, Divider,
} from 'antd';
import { Images } from '../../public/asset/index';
import { sizes, textColor } from '../../constants/constants';

const MainCard = props => {
  const {
    id,
    name,
    picture,
    status,
    mobile,
    emailOrTruck,
    addressOrLocation,
    type,
  } = props;
  if (type === 'Driver') {
    return (
      <Card
        className="box"
        key={id}
        size="small"
        style={{cursor:'pointer',
          minheight: '150px',
          padding: 10,
          borderRadius:"6px"
        }}
      >
        <div style={{  display: 'flex', flexDirection: 'row' }}>
          <Avatar
            size="large"
            style={{
             
              borderWidth: 1,
              marginTop: 1,
              marginRight: 10,
              
              verticalAlign: 'middle',
            }}
            src={picture}
          >
            <span style={{ fontWeight: 'bold', fontSize: sizes.h1 }}>
              {name.charAt(0)}
            </span>
          </Avatar>
          <div
            style={{ 
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '10px',
            }}
          >
            <span style={{ fontSize: sizes.h1, fontWeight: 500 }}>
              {name}
              <Badge
                dot
                style={{
                  backgroundColor:status==='Connected'?'#52c41a':"red",
                  color: '#52c41a',
                  width: '8px',
                  height: '8px',
                  marginLeft: 5,
                  marginTop: 10,
                }}
              />
            </span>
            <span style={{ fontSize: sizes.h5, color: textColor.text_light }}>
              {status}
            </span>
          </div>
        </div>
        <Divider style={{ marginBottom: 10, marginTop: 10 }} />
        <div style={{ display: 'flex', flexDirection: 'row',marginTop:'20px' }}>
          <Col span={11}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span
                style={{
                  fontSize: sizes.h4,
                  color: textColor.text_midle,
                  marginBottom: '8px',
                }}
              >
                <i class="material-icons" style={{fontSize:'13px',marginRight:'5px'}}>
stay_current_portrait
                </i>
                {' '}
                Mobile:
              </span>
              <span
                style={{
                  fontSize: sizes.h4,
                  color: textColor.text_midle,
                  marginBottom: '8px',
                }}
              >
                <i class="material-icons" style={{fontSize:'13px',marginRight:'5px'}}>
location_on  
                </i>


                {' '}

            Current Location:
              </span>
              <span
                style={{
                  fontSize: sizes.h4,
                  color: textColor.text_midle,
                  marginBottom: '2px',
                }}
              >
                <i class="material-icons" style={{fontSize:'13px',marginRight:'5px'}}>
directions_bus
                </i>
                {' '}
   Assigned Truck:
              </span>
            </div>
          </Col>
         
          <Col span={13}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '5px',
              }}
            >
              <span
                style={{
                  fontSize: sizes.h4,
                  color: textColor.text_light,
                  marginBottom: '8px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: 170,
                  whiteSpace: 'nowrap',
                }}
              >
                {mobile}
              </span>
              <span
                style={{
                  fontSize: sizes.h4,
                  color: textColor.text_light,
                  marginBottom: '8px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: 170,
                  whiteSpace: 'nowrap',
                }}
              >
                {addressOrLocation}
              </span>
              <span
                style={{
                  fontSize: sizes.h4,
                  color: textColor.text_light,
                  marginBottom: '2px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: 170,
                  whiteSpace: 'nowrap',
                }}
              >
                {emailOrTruck}
              </span>
            </div>
          </Col>
        </div>
      </Card>
    );
  }else{
    return (
      <Card
        className='box'
        key={id}
        hoverable
        size="small"
        style={{
          minheight: '150px',
       
          borderRadius: '6px',
          padding: 10,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Avatar
            size="large"
            style={{
           
              marginTop: 1,
              marginRight: 10,
              
              verticalAlign: 'middle',
            }}
            src={picture}
          >
            <span style={{ fontWeight: 'bold', fontSize: sizes.h1 }}>
              {name.charAt(0)}
            </span>
          </Avatar>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '10px',
             
            }}
          >
            <span style={{ fontSize: sizes.h1, fontWeight: 500 }}>{name}</span>
            <span style={{ fontSize: sizes.h5, color: textColor.text_light }}>
              {status}
            </span>
          </div>
        </div>
        <Divider style={{ marginBottom: 10, marginTop: 10 }} />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Col span={10}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span
                style={{
                  fontSize: sizes.h4,
                  color: textColor.text_midle,
                  marginBottom: '8px',
                }}
              >
                <Icon type="phone" theme="filled" />
                {' '}

          Mobile:
              </span>
              <span
                style={{
                  fontSize: sizes.h4,
                  color: textColor.text_midle,
                  marginBottom: '8px',
                }}
              >
                <Icon type="pushpin" theme="filled" />
                {' '}

            Address:
              </span>
              <span
                style={{
                  fontSize: sizes.h4,
                  color: textColor.text_midle,
                  marginBottom: '2px',
                }}
              >
                <Icon type="mail" theme="filled" />
                {' '}

          Email:
              </span>
            </div>
          </Col>
          <Col span={14}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '5px',
              }}
            >
              <span
                style={{
                  fontSize: sizes.h4,
                  color: textColor.text_light,
                  marginBottom: '8px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: 170,
                  whiteSpace: 'nowrap',
                }}
              >
                {mobile}
              </span>
              <span
                style={{
                  fontSize: sizes.h4,
                  color: textColor.text_light,
                  marginBottom: '8px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: 170,
                  whiteSpace: 'nowrap',
                }}
              >
                {addressOrLocation}
              </span>
              <span
                style={{
                  fontSize: sizes.h4,
                  color: textColor.text_light,
                  marginBottom: '2px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: 170,
                  whiteSpace: 'nowrap',
                }}
              >
                {emailOrTruck}
              </span>
            </div>
          </Col>
        </div>
      </Card>
    );
  }
};
export default MainCard;

MainCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  picture: PropTypes.string,
  status: PropTypes.string,
  mobile: PropTypes.string,
  emailOrTruck: PropTypes.string,
  addressOrLocation: PropTypes.string,
  type: PropTypes.string,
};

MainCard.defaultProps = {
  name: 'Kasra',
  picture: Images.ICON_TWO,
  status: 'Not Assigned',
  mobile: 'Not Assigned',
  emailOrTruck: 'Not Assigned',
  addressOrLocation: 'Not Assigned',
  type: 'Driver',
};
