import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Icon } from 'antd';
import { Images } from '../../public/asset/index';
import { sizes, textColor } from '../../constants/constants';

const VehicleCard = props => {
  const {
    id, name, picture, status, driver, location, type,
  } = props;
  return (
  
    <Card
      className='box'
      key={id}
      
      size="small"
      style={{cursor:'pointer',
        minHeight: '180px',
       
      
        borderRadius: '6px',
        padding: 10,
      }}
      cover={( 
        <div>
          <img
            height="130px"
            width="100%"
            alt={picture} 
            src={picture}
            style={{ objectFit: 'cover' }}
          />
        </div>
      )}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: -70,
          color: 'white',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10px',
            backgroundColor: 'rgba(0,0,0,0.4)',
            padding: 5,
            borderRadius: 6,
          }}
        >
          <span style={{ fontSize: sizes.h1, fontWeight: 500 }}>{name}</span>
          <span style={{ fontSize: sizes.h5 }}>{status}</span>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
        <Col span={11}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span
              style={{
                fontSize: sizes.h4,
                color: textColor.text_midle,
                marginBottom: '8px',
              }}
            >
              <i class="material-icons" style={{fontSize:'13px',marginRight:'5px'}}>
directions_bus
              </i>
              {' '}

Truck Type:
            </span>
            <span
              style={{
                fontSize: sizes.h4,
                color: textColor.text_midle,
                marginBottom: '8px',
              }}
            >
              <i class="material-icons" style={{fontSize:'13px',marginRight:'5px'}}>
location_on  
              </i>

              {' '}

Current Location:
            </span>
            <span
              style={{
                fontSize: sizes.h4,
                color: textColor.text_midle,
                marginBottom: '2px',
              }}
            >
              <i class="material-icons" style={{fontSize:'13px',marginRight:'5px'}}>
person
              </i>
              {' '}

Assigned Driver:
            </span>
          </div>
        </Col>
        <Col span={13}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '5px',
            }}
          >
            <span
              style={{
                fontSize: sizes.h4,
                color: textColor.text_light,
                marginBottom: '8px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 170,
                whiteSpace: 'nowrap',
              }}
            >
              {type}
            </span>
            <span
              style={{
                fontSize: sizes.h4,
                color: textColor.text_light,
                marginBottom: '8px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 170,
                whiteSpace: 'nowrap',
              }}
            >
              {location}
            </span>
            <span
              style={{
                fontSize: sizes.h4,
                color: textColor.text_light,
                marginBottom: '2px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 170,
                whiteSpace: 'nowrap',
              }}
            >
              {driver}
            </span>
          </div>
        </Col>
      </div>
    </Card>
  );
};
export default VehicleCard;

VehicleCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  picture: PropTypes.string,
  status: PropTypes.string,
  driver: PropTypes.string,
  location: PropTypes.string,
  type: PropTypes.string,
};

VehicleCard.defaultProps = {
  name: 'Kasra',
  picture: Images.BACKGROUND_DARK,
  status: 'Not Assigned',
  driver: 'Not Assigned',
  location: 'Not Assigned',
  type: 'Not Assigned',
};
