import { combineReducers } from "redux";
import dashboardReducer from "../modules/loggedin/dashboard/reducer/index";
import driverReducer from "../modules/loggedin/driverManagement/reducer/index";
import vehicleReducer from "../modules/loggedin/vehicleManagement/reducer/index";
import clientReducer from "../modules/loggedin/clientmanagement/reducer/index";
import brokerReducer from "../modules/loggedin/brokermanagement/reducer/index";
import reportsReducer from "../modules/loggedin/reportmanagement/reducer/index";
import detailReducer from "../modules/loggedin/detail/reducer/index";
import globalReducer from '../modules/loggedin/sidebar/reducer/index';
import signupReducer from '../modules/signup/reducer/index';
import tripsReducers from "../modules/loggedin/trips/reducer/index";


export default combineReducers({
  dashboard: dashboardReducer,
  drivers: driverReducer,
  vehicles: vehicleReducer,
  clients: clientReducer,
  brokers: brokerReducer,
  global: globalReducer,
  signup: signupReducer,
  detail:detailReducer,
  reports:reportsReducer,
  trips:tripsReducers
});
