import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Popover, Icon, Avatar,Badge } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import * as Actions from "../../modules/loggedin/dashboard/action/actionCreater";
import * as ActionsTrips from "../../modules/loggedin/trips/action/actionCreater";
import * as ActionsGlobal from "../../modules/loggedin/sidebar/action/actionCreater";

import { Images } from "../../public/asset/index";
import { isMobile, sizes, textColor } from "../../constants/constants";

const TripRequestComponent = props => {
  const {
    clientName,
    tripId,
    date,
    tripPickUpLocation,
    tripDropOffLocation,
    pickUpCity,
    dropOffCity, 
    id,
    onChangeRequestModalVisible,
    onGetDetailTripRequestData,
    userInfo,
    type,
    onChangeDetailData,
    onChangeFilterData,
    ON_TRIP_UPON_PUSH_TO_ARRAY,
    ON_TRIP_REQUEST_PUSH_TO_ARRAY,
    onChangeNavName
  } = props;
  let getTripRequestData = () => {
    let data = { id: id, userId: userInfo.user._id };
    const hello = async () => {
      let response = await onGetDetailTripRequestData(data);
      console.log('response',response)
      if (response.payload.data !== ""){
        onChangeRequestModalVisible(true);
        props.handleTR(type==="Local"?true:false)
        // onGetLogData({ id: userInfo.user._id });
        // message.success("Trip Request Added");
      }
    };
    hello();
  };
  let getTripRequestData2 = () => {
    if (type==="Local") {
    let data = { id: id, userId: userInfo.user._id };
    const hello = async () => {
      let response = await onGetDetailTripRequestData(data);
      console.log('response',response)
      if (response.payload.data !== ""){
          onChangeRequestModalVisible(true);
          props.handleTR(type==="Local"?true:false)
      }
    };
    hello();
  }else{
    let data = []
    data.push(
      {
        _id:id,
        type:"Market",
        tripRequestId:tripId,
        name:clientName,
        pickupLocation:{street:tripPickUpLocation,city:pickUpCity},
        dropoffLocation:{street:tripDropOffLocation,city:dropOffCity},
        requestedTime:date,
        clientPicture:false,
      }
    )
    ON_TRIP_REQUEST_PUSH_TO_ARRAY(data)
     onChangeFilterData({status:"All",open:false,type:"Requests"})
     ON_TRIP_UPON_PUSH_TO_ARRAY(false)                              
     onChangeNavName({
       first: "Trips",
       second: "Requests"
     });
     onChangeDetailData({
       type: "Trips",
       id: ""
     });
     props.history.push("/loggedIn/trips");
      // alert("Navigate")  
  }
  };
  return (
    <Card 
    className="box2"
      hoverable
      size="small"
      title={
        <span style={{ fontSize: sizes.h4, color: textColor.text_midle }}>
          TRP#{tripId}  {type==='Market'?' (Market)':" (Local)"}
        </span>
      }
      style={{
        width: isMobile ? "99%" : "100%",
        marginTop: 10,
        marginBottom: 10,
        minheight: "150px",
        boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.1)",
        borderRadius: "2px"
      }}
      extra={
        <Popover
          placement="bottom"
          trigger="hover"
          content={
            <div
              style={{
                width: 65,
                textAlign: "center",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <span
                style={{
                  color: "red",
                  fontSize: 13,
                  marginBottom: "5px",
                  cursor: "pointer"
                }}
                onClick={getTripRequestData}
              >
                Edit Trip
              </span>
            </div>
          }
        >
          <Icon type="ellipsis" />
        </Popover>
      }
    >
      <Col span={24} onClick={getTripRequestData2}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Col
            span={7}
            style={{
              borderRightStyle: "solid",
              borderRightWidth: 1,
              borderRightColor: "rgba(35,25,55,0.1)",
              paddingRight: "2px"
            }}
          >
            {/* date */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                marginTop: "5px",
                marginRight: 10
              }}
            >
              <span
                style={{
                  fontSize: sizes.h3,
                  color: textColor.text_light
                }}
              >
                {moment(date).format("MMM")}
              </span>
              <span
                style={{
                  fontSize: sizes.heading,
                  color: textColor.text_midle,
                  marginTop: 10
                }}
              >
                {moment(date).format("DD")}
              </span>
              <span
                style={{
                  marginTop: 10,
                  fontSize: sizes.h3,
                  color: textColor.text_light
                }}
              >
                {moment(date).format("h:mm A")}
              </span>
            </div>
          </Col>
          <Col span={17} style={{ marginLeft: "2px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* avatar price */}
              <Col span={24}>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:10 }}>
                  <span
                    style={{
                      fontSize: sizes.h1,
                      color: textColor.text_midle,
                      marginLeft: "2px",
                      marginTop: "2px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      width: 170,
                      height: "1.5em",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {clientName}
                  </span>
                </div>
              </Col>
              {/* <Col span={8}>
                <span
                  style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.85)" }}
                />
              </Col> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "5px"
              }}
            >
              <Col span={2}>
                <img
                  style={{ marginTop: 11, color: textColor.text_midle }}
                  alt=""
                  height={45}
                  src={Images.LINER}
                />
              </Col>
              <Col span={22}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "5px"
                  }}
                >
                  <span
                    style={{
                      fontSize: sizes.h3,
                      color: textColor.text_light,
                      marginTop: 4,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      width: 150,
                      height: "1.5em",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {`${tripPickUpLocation} ${pickUpCity}`}
                  </span>
                  <span
                    style={{
                      fontSize: sizes.h3,
                      color: textColor.text_light,
                      marginTop: 10,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      width: 150,
                      height: "1.5em",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {`${tripDropOffLocation} ${dropOffCity}`}
                  </span>
                </div>
              </Col>
            </div>
          </Col>
        </div>
      </Col>
    </Card>
  );
};
const mapStateToProps = ({ dashboard, global, signup }) => ({
  requestModalVisible: dashboard.requestModalVisible,
  logData: global.logData,
  userInfo: signup.content,
  allTripRequests: global.allTripRequests
});
const mapDispatchToProps = dispatch => ({
  onChangeRequestModalVisible: visible =>
    dispatch(Actions.ON_CHANGE_REQUEST_MODAL_VISIBLE(visible)),
  onGetDetailTripRequestData: data =>
    dispatch(Actions.onGetDetailTripRequestData(data)),
    
    onChangeDetailData: data =>
    dispatch(ActionsGlobal.ON_CHANGE_DETAIL_DATA(data)),
  onChangeFilterData: filter =>
    dispatch(ActionsGlobal.ON_CHANGE_FILTER_DATA(filter)),
  ON_TRIP_UPON_PUSH_TO_ARRAY: data =>
    dispatch(ActionsTrips.ON_TRIP_UPON_PUSH_TO_ARRAY(data)),
    ON_TRIP_REQUEST_PUSH_TO_ARRAY: data =>
    dispatch(ActionsTrips.ON_TRIP_REQUEST_PUSH_TO_ARRAY(data)),
  onChangeNavName: navName => dispatch(ActionsGlobal.ON_CHANGE_NAV_NAME(navName)),

});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TripRequestComponent);
TripRequestComponent.propTypes = {
  clientName: PropTypes.string,
  tripPickUpLocation: PropTypes.string,
  tripDropOffLocation: PropTypes.string,
  pickUpCity: PropTypes.string,
  dropOffCity: PropTypes.string
};

TripRequestComponent.defaultProps = {
  clientName: "No Client",
  tripPickUpLocation: "not found",
  tripDropOffLocation: "not found",
  pickUpCity: "not found",
  dropOffCity: "not found"
};
