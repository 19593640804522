import React, { Component } from 'react';
import { Col, Icon, Divider } from 'antd';
import { Images } from '../../public/asset/index';
import { textColor, sizes } from '../../constants/constants';

class VehicleInfo extends Component {
  render() {
    const {data} = this.props;
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Col span={14}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img
                style={{
                  marginRight: 10,
                  color: textColor.text_midle,
                }}
                alt=""
                height={33}
                src={Images.ICON_ONE}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span
                  style={{
                    fontSize: sizes.h5,
                    color: textColor.text_midle,
                    marginBottom: 2,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: 130,
                    height: '1.32em',
                    whiteSpace: 'nowrap',
                  }}
                >

                  {data?(data.type==="Driver"?data.driverName:"Broker("+data.brokerName+")"):"Not Assigned"}
                </span>
                <span
                  style={{
                    fontSize: sizes.h5,
                    color: textColor.text_light,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: 130,
                    height: '1.32em',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {data?(data.type==="Driver"?data.vehiclePlateNo:data.brokerVehicle):"Not Assigned"}

                </span>
              </div>
            </div>
          </Col>
          <Col span={10}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span
                style={{
                  fontSize: sizes.h5,
                  color: textColor.text_midle,
                  marginBottom: 2,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: 130,
                  height: '1.32em',
                  whiteSpace: 'nowrap',
                }}
              >
                <Icon type="info-circle" style={{ marginRight: 5 }}>
                </Icon>
                {data?(data.type==="Driver"?data.vehicleTypeTitle:data.brokerVehicleTypeTitle):"Not Assigned"}
              </span>
              <span
                style={{
                  marginLeft: 20,
                  fontSize: sizes.h5,
                  color: textColor.text_light,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: 130,
                  height: '1.32em',
                  whiteSpace: 'nowrap',
                }}
              >
                {data?(data.type==="Driver"?data.driverName:data.brokerDriver):"Not Assigned"}

               
              </span>
            </div>
          </Col>
        </div>
        <Divider style={{ marginTop: 8 }} />
      </div>
    );
  }
}
export default VehicleInfo;
