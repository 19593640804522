import React, { Component } from "react";
import { Col, Steps } from "antd";
import { textColor, sizes } from "../../constants/constants";
import moment from "moment";

const Step = Steps.Step;
class TripHistoryCard extends Component {
  constructor(props) {
    super(props);
    console.log("ddddd", this.props.data.time);
  }
  render() {
    return (
      <div style={{ minWidth: 250, maxWidth: 250 }}>
        <h2 style={{ color: textColor.text_light, fontSize: sizes.h1 }}>
          Trip History
        </h2>
        <div className="ant-card" style={{ padding: 20, maxHeight: 320 }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Col span={2}>
              <Steps size="small" progressDot direction="vertical" current={3}>
                <Step />
                <Step />
                <Step />
                <Step />
                <Step />
              </Steps>
            </Col>
            <Col span={22}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 42
                  }}
                >
                  <Col span={8}>
                    <span
                      style={{
                        fontSize: sizes.h5,
                        color: textColor.text_midle
                      }}
                    >
                      Requested
                    </span>
                  </Col>
                  <Col span={16}>
                    <span
                      style={{
                        fontSize: sizes.h6,
                        color: textColor.text_light,
                        paddingLeft:4
                      }}
                    >
                      {this.props.data.tripCreatedDate
                        ? moment(this.props.data.tripCreatedDate).format(
                          "DD MM, YYYY : hh:mm:ss"
                        )
                        : " "}
                    </span>
                  </Col>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 43
                  }}
                >
                  <Col span={8}>
                    <span
                      style={{
                        fontSize: sizes.h5,
                        color: textColor.text_midle
                      }}
                    >
                      Arived
                    </span>
                  </Col>
                  <Col span={16}>
                    <span
                      style={{
                        fontSize: sizes.h6,
                        color: textColor.text_light,
                        marginLeft: 5
                      }}
                    >
                      {this.props.data.time
                        ? moment(this.props.data.time.started).format(
                          "DD MM, YYYY : hh:mm:ss"
                        )
                        : " "}
                    </span>
                  </Col>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 43
                  }}
                >
                  <Col span={8}>
                    <span
                      style={{
                        fontSize: sizes.h5,
                        color: textColor.text_midle
                      }}
                    >
                      Started
                    </span>
                  </Col>
                  <Col span={16}>
                    <span
                      style={{
                        fontSize: sizes.h6,
                        color: textColor.text_light,
                        marginLeft: 5
                      }}
                    >
                      {this.props.data.time
                        ? moment(this.props.data.time.started).format(
                          "DD MM, YYYY : hh:mm:ss"
                        )
                        : " "}
                    </span>
                  </Col>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 45
                  }}
                >
                  <Col span={8}>
                    <span
                      style={{
                        fontSize: sizes.h5,
                        color: textColor.text_midle
                      }}
                    >
                      Reached
                    </span>
                  </Col>
                  <Col span={16}>
                    <span
                      style={{
                        fontSize: sizes.h6,
                        color: textColor.text_light,
                        marginLeft: 5
                      }}
                    >
                      {this.props.data.time
                        ? this.props.data.time.ended
                          ? moment(this.props.data.time.ended).format(
                            "DD MM, YYYY : hh:mm:ss"
                          )
                          : " "
                        : " "}
                    </span>
                  </Col>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Col span={8}>
                    <span
                      style={{
                        fontSize: sizes.h5,
                        color: textColor.text_midle
                      }}
                    >
                      Finished
                    </span>
                  </Col>
                  <Col span={16}>
                    <span
                      style={{
                        fontSize: sizes.h6,
                        color: textColor.text_light,
                        marginLeft: 5
                      }}
                    >
                      {this.props.data.time
                        ? this.props.data.time.ended
                          ? moment(this.props.data.time.ended).format(
                            "DD MM, YYYY : hh:mm:ss"
                          )
                          : " "
                        : " "}
                    </span>
                  </Col>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    );
  }
}
export default TripHistoryCard;
