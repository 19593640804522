import React, { Component } from "react";
import FullInfoViewLayout from "../../../components/fullInfoViewLayout/fullInfoViewlayout";
import { connect } from "react-redux";
import * as ActionsGlobal from "../sidebar/action/actionCreater";
import * as ActionsDriver from "../driverManagement/action/actionCreater";
import * as ActionsVehicle from "../vehicleManagement/action/actionCreater";
import * as ActionsClient from "../clientmanagement/action/actionCreater";
import * as ActionsBroker from "../brokermanagement/action/actionCreater";
import * as Actions from "./action/actionCreater";
import * as ActionsTrips from "../trips/action/actionCreater";

import { Row, Col, Button, message, Popconfirm, Icon } from "antd";
import MainModal from "../../../components/modals/mainModal";
class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      nextModal: null
    };
  }
  componentDidMount() {
    const { onChangeNavName, onChangeDetailData,detail } = this.props;
    if (detail.type === "Driver") {
      window.onpopstate = e => {
        onChangeNavName({
          first: "Drivers",
          second: ""
        });
        onChangeDetailData({
          id: "",
          type: "Drivers"
        });
        // this.props.history.push("/nav")
        this.props.history.push("/loggedin/drivers");
      };
    } else if (detail.type === "Vehicle") {
      window.onpopstate = e => {
        onChangeNavName({
          first: "Vehicles",
          second: ""
        });
        onChangeDetailData({
          id: "",
          type: "Vehicles"
        });
        // this.props.history.push("/nav")
        this.props.history.push("/loggedin/vehicles");
      };
    } else if (detail.type === "Client") {
      window.onpopstate = e => {
        onChangeNavName({
          first: "Clients",
          second: ""
        });
        onChangeDetailData({
          id: "",
          type: "Client"
        });
        // this.props.history.push("/nav")
        this.props.history.push("/loggedin/clientManagement");
      };
    } else if (detail.type === "Broker") {
      window.onpopstate = e => {
        onChangeNavName({
          first: "Transporters",
          second: ""
        });
        onChangeDetailData({
          id: "",
          type: "Broker"
        });
        // this.props.history.push("/nav")
        this.props.history.push("/loggedin/brokerManagement");
      };
    }
    
  }
  componentWillMount() {
    const {
      detail,
      userInfo,
      onGetDetailDriverData,
      onGetDetailVehicleData,
      onGetDetailClientData,
      onGetDetailBrokerData
    } = this.props;
    if (userInfo !== "") {
      if (detail.type === "Driver") {
        onGetDetailDriverData({ id: detail.id, userId: userInfo.user._id });
      } else if (detail.type === "Vehicle") {
        onGetDetailVehicleData({ id: detail.id, userId: userInfo.user._id });
      } else if (detail.type === "Client") {
        onGetDetailClientData({ id: detail.id, userId: userInfo.user._id });
      } else if (detail.type === "Broker") {
        onGetDetailBrokerData({ id: detail.id, userId: userInfo.user._id });
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.detail !== nextProps.detail) {
      if (nextProps.detail !== "") {
        // here api cals and start loading till data come and goes towards its own components
        const {
          detail,
          userInfo,
          onGetDetailDriverData,
          onGetDetailVehicleData,
          onGetDetailClientData,
          onGetDetailBrokerData
        } = nextProps;
        if (detail.type === "Driver") {
          if (userInfo !== "") {
            onGetDetailDriverData({ id: detail.id, userId: userInfo.user._id });
          }
        } else if (detail.type === "Vehicle") {
          if (userInfo !== "") {
            onGetDetailVehicleData({
              id: detail.id,
              userId: userInfo.user._id
            });
          }
        } else if (detail.type === "Client") {
          if (userInfo !== "") {
            onGetDetailClientData({ id: detail.id, userId: userInfo.user._id });
          }
        } else if (detail.type === "Broker") {
          if (userInfo !== "") {
            onGetDetailBrokerData({ id: detail.id, userId: userInfo.user._id });
          }
        }
      }
    }
  }

  toConnect = e => {
    const {
      onChangeDriverModalVisible,
      onDissConnectDriver,
      userInfo,
      onAllDriversInfoGet,
      onGetLogData,
      DATA
    } = this.props;

    if (e === "Connected") {
      if (userInfo !== "") {
        const hello = async () => {
          let data = {
            userId: userInfo.user._id,
            remoteDriverId: DATA.driverId,
            localDriverId: DATA._id
          };
          let response = await onDissConnectDriver(data);
          if (response.payload.data !== "") {
            onGetLogData({ id: userInfo.user._id });
            onAllDriversInfoGet({ id: userInfo.user._id });
            message.success("Disconnected");
            this.props.history.push("/LoggedIn/drivers");
          }
        };
        hello();
      }
    } else {
      this.setState({ nextModal: false });
      onChangeDriverModalVisible(true);
    }
  };

  toVisibleEditModal = () => {
    const {
      detail,
      onChangeDriverModalVisible,
      onChangeVehicleModalVisible,
      onChangeClientModalVisible,
      onChangeBrokerModalVisible
    } = this.props;
    if (detail.type === "Driver") {
      this.setState({ id: detail.id, nextModal: true });
      onChangeDriverModalVisible(true);
    } else if (detail.type === "Vehicle") {
      onChangeVehicleModalVisible(true);
    } else if (detail.type === "Client") {
      onChangeClientModalVisible(true);
    } else if (detail.type === "Broker") {
      onChangeBrokerModalVisible(true);
    }
  };
  clicked = () => {
    const {
      detail,
      ON_TRIP_UPON_PUSH_TO_ARRAY,
      onChangeDetailData,
      ON_SET_DETAIL_DATA_FALSE,
      userInfo,
      onChangeFilterData,
      onChangeNavName
    } = this.props;
    if (userInfo !== "") {
      if (detail.type === "Driver") {
        onChangeFilterData({ open: true, driverId: detail.id, status: "All" });
        onChangeNavName({ first: "Trips", second: "Drivers" });
        ON_TRIP_UPON_PUSH_TO_ARRAY(false);
        ON_SET_DETAIL_DATA_FALSE();
        onChangeDetailData({
          type: "Trips",
          id: ""
        });
        this.props.history.push("/LoggedIn/trips");
      }
      if (detail.type === "Vehicle") {
        onChangeFilterData({ open: true, vehicleId: detail.id, status: "All" });
        onChangeNavName({ first: "Trips", second: "Vehicles" });
        ON_TRIP_UPON_PUSH_TO_ARRAY(false);
        ON_SET_DETAIL_DATA_FALSE();
        onChangeDetailData({
          type: "Trips",
          id: ""
        });
        this.props.history.push("/LoggedIn/trips");
      }
      if (detail.type === "Client") {
        onChangeFilterData({ open: true, clientId: detail.id, status: "All" });
        onChangeNavName({ first: "Trips", second: "Clients" });
        ON_TRIP_UPON_PUSH_TO_ARRAY(false);
        ON_SET_DETAIL_DATA_FALSE();
        onChangeDetailData({
          type: "Trips",
          id: ""
        });
        this.props.history.push("/LoggedIn/trips");
      }
      if (detail.type === "Broker") {
        onChangeFilterData({ open: true, brokerId: detail.id, status: "All" });
        onChangeNavName({ first: "Trips", second: "Brokers" });
        ON_TRIP_UPON_PUSH_TO_ARRAY(false);
        ON_SET_DETAIL_DATA_FALSE();
        onChangeDetailData({
          type: "Trips",
          id: ""
        });
        this.props.history.push("/LoggedIn/trips");
      }
    }
  };
  onDeleteConfrim = () => {
    const {
      detail,
      onDeleteDVCB,
      userInfo,
      onAllDriversInfoGet,
      onAllVehiclesInfoGet,
      onAllClientsInfoGet,
      onAllBrokersInfoGet,
      onGetLogData
    } = this.props;
    if (userInfo !== "") {
      if (detail.type === "Driver") {
        const hello = async () => {
          let data = {
            url: "/portal/driver",
            body: { userId: userInfo.user._id, driverId: detail.id }
          };
          let response = await onDeleteDVCB(data);
          if (response.payload.data !== "") {
            onGetLogData({ id: userInfo.user._id });
            onAllDriversInfoGet({ id: userInfo.user._id });
            message.success("deleted");
            this.props.history.push("/LoggedIn/drivers");
          }
        };
        hello();
      } else if (detail.type === "Vehicle") {
        const hello = async () => {
          let data = {
            url: "/vehicle",
            body: {
              userId: userInfo.user._id,
              vehicleId: detail.id,
              accountType: "Transporter"
            }
          };
          let response = await onDeleteDVCB(data);
          if (response.payload.data !== "") {
            onGetLogData({ id: userInfo.user._id });
            onAllVehiclesInfoGet({ id: userInfo.user._id });
            message.success("deleted");
            this.props.history.push("/LoggedIn/vehicles");
          }
        };
        hello();
      } else if (detail.type === "Client") {
        const hello = async () => {
          let data = {
            url: "portal/client",
            body: { userId: userInfo.user._id, clientId: detail.id }
          };
          let response = await onDeleteDVCB(data);
          if (response.payload.data !== "") {
            onGetLogData({ id: userInfo.user._id });
            onAllClientsInfoGet({ id: userInfo.user._id });
            message.success("deleted");
            this.props.history.push("/LoggedIn/clientManagement");
          }
        };
        hello();
      } else if (detail.type === "Broker") {
        const hello = async () => {
          let data = {
            url: "/portal/broker",
            body: { userId: userInfo.user._id, brokerId: detail.id }
          };
          let response = await onDeleteDVCB(data);
          if (response.payload.data !== "") {
            onDeleteDVCB(data);
            onAllBrokersInfoGet({ id: userInfo.user._id });
            this.props.history.push("/LoggedIn/brokerManagement");
            message.success("deleted");
          }
        };
        hello();
      }
    }
  };

  selectModal = () => {
    if (this.state.nextModal === true) {
      const { driverModalVisible, DATA } = this.props;

      return (
        <MainModal
          visible={driverModalVisible}
          modalType={"driver"}
          data={DATA}
        />
      );
    } else if (this.state.nextModal === false) {
      const { driverModalVisible } = this.props;
      return (
        <MainModal
          visible={driverModalVisible}
          modalType={"driverConnect"}
          data={false}
          type={"local"}
        />
      );
    }
  };
  render() {
    const {
      navName,
      detail,
      vehicleModalVisible,
      clientModalVisible,
      brokerModalVisible,
      DATA,
      loading,
      loading2
    } = this.props;
    return (
      <div>
        {!loading && !loading2 ? (
          <div>
            {this.selectModal()}
            <MainModal
              visible={vehicleModalVisible}
              modalType={"vehicle"}
              data={DATA}
            />
            <MainModal
              visible={clientModalVisible}
              modalType={"client"}
              data={DATA}
            />
            <MainModal
              visible={brokerModalVisible}
              modalType={"broker"}
              data={DATA}
            />

            <div className="ant-card" style={{ height: "100%" }}>
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  span={12}
                  style={{ padding: 20 }}
                >
                  <h3>{navName["third"]}</h3>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  xxl={12}
                  span={12}
                  style={{ padding: 20 }}
                >
                  <div style={{ float: "right" }}>
                    <Button
                      size="default"
                      type="primary"
                      icon="solution"
                      style={{ marginRight: 12 }}
                      onClick={this.toVisibleEditModal}
                    >
                      Edit
                    </Button>
                    <Popconfirm
                      placement="bottomRight"
                      title={"Are you Sure You want to Delete"}
                      onConfirm={this.onDeleteConfrim}
                      okText="Yes"
                      cancelText="No"
                      icon={
                        <Icon
                          type="question-circle-o"
                          style={{ color: "red" }}
                        />
                      }
                    >
                      <Button
                        size="default"
                        icon="rest"
                        type={"ghost"}
                        style={{ color: "red", borderColor: "red" }}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </div>
                </Col>
              </Row>
            </div>
            <FullInfoViewLayout
              type={detail["type"]}
              data={DATA !== "" ? DATA : false}
              toConnect={this.toConnect}
              clicked={this.clicked}
            />
          </div>
        ) : (
          <Row type="flex" justify="center" align="middle">
            <Icon
              size="large"
              type="loading"
              style={{ fontSize: "50px", marginTop: "25%" }}
            />
          </Row>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  detail: state.global.detail,
  activeNav: state.global.activeNav,
  navName: state.global.navName,
  driverModalVisible: state.drivers.driverModalVisible,
  vehicleModalVisible: state.vehicles.vehicleModalVisible,
  clientModalVisible: state.clients.clientModalVisible,
  brokerModalVisible: state.brokers.brokerModalVisible,
  userInfo: state.signup.content,
  DATA: state.detail.detailData,
  loading: state.detail.loading,
  loading2: state.drivers.loading,

  filters: state.global.filters
});
const mapDispatchToProps = dispatch => ({
  onChangeActiveNav: activeNav =>
    dispatch(ActionsGlobal.ON_CHANGE_ACTIVE_NAV(activeNav)),
  onChangeNavName: navName =>
    dispatch(ActionsGlobal.ON_CHANGE_NAV_NAME(navName)),

  // to visible the modal
  onChangeDriverModalVisible: visible =>
    dispatch(ActionsDriver.ON_CHANGE_DRIVER_MODAL_VISIBLE(visible)),
  onDissConnectDriver: data =>
    dispatch(ActionsDriver.onDissConnectDriver(data)),

  onChangeVehicleModalVisible: visible =>
    dispatch(ActionsVehicle.ON_CHANGE_VEHICLE_MODAL_VISIBLE(visible)),
  onChangeClientModalVisible: visible =>
    dispatch(ActionsClient.ON_CHANGE_CLIENT_MODAL_VISIBLE(visible)),
  onChangeBrokerModalVisible: visible =>
    dispatch(ActionsBroker.ON_CHANGE_BROKER_MODAL_VISIBLE(visible)),

  // to get data actions
  onGetDetailDriverData: data => dispatch(Actions.onGetDetailDriverData(data)),
  onGetDetailVehicleData: data =>
    dispatch(Actions.onGetDetailVehicleData(data)),
  onGetDetailClientData: data => dispatch(Actions.onGetDetailClientData(data)),
  onGetDetailBrokerData: data => dispatch(Actions.onGetDetailBrokerData(data)),

  onDeleteDVCB: data => dispatch(Actions.onDeleteDVCB(data)),
  onGetLogData: data => dispatch(ActionsGlobal.onGetLogData(data)),

  onAllDriversInfoGet: data =>
    dispatch(ActionsGlobal.onAllDriversInfoGet(data)),
  onAllVehiclesInfoGet: data =>
    dispatch(ActionsGlobal.onAllVehiclesInfoGet(data)),
  onAllClientsInfoGet: data =>
    dispatch(ActionsGlobal.onAllClientsInfoGet(data)),
  onAllBrokersInfoGet: data =>
    dispatch(ActionsGlobal.onAllBrokersInfoGet(data)),

  onChangeFilterData: filter =>
    dispatch(ActionsGlobal.ON_CHANGE_FILTER_DATA(filter)),
  onChangeDetailData: data =>
    dispatch(ActionsGlobal.ON_CHANGE_DETAIL_DATA(data)),
  ON_TRIP_UPON_PUSH_TO_ARRAY: data =>
    dispatch(ActionsTrips.ON_TRIP_UPON_PUSH_TO_ARRAY(data)),
  ON_SET_DETAIL_DATA_FALSE: data =>
    dispatch(ActionsTrips.ON_SET_DETAIL_DATA_FALSE(data))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Detail);
