import { SET_DRIVER_MODAL_VISIBLE,ON_DRIVER_ADD_REQUEST,ON_DRIVER_EDIT_REQUEST,ON_GET_CONNECT_DRIVER_INFO,ON_CONNECT_DRIVER,ON_DISCONNECT_DRIVER,ON_CONNECT_DRIVER_LOCAL } from "../action/actionType";
import { message } from "antd";

export default (state = { driverModalVisible: false,loading:false, driverData:"" }, action) => {
  switch (action.type) {
    case SET_DRIVER_MODAL_VISIBLE:
      return { ...state, driverModalVisible: action.payload,loading:false };

    case ON_DRIVER_ADD_REQUEST[0]:
      return { ...state, loading: true };
    case ON_DRIVER_ADD_REQUEST[1]:
      return { ...state, loading: false, driverData: action.payload.data };
    case ON_DRIVER_ADD_REQUEST[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false, err:'' };

    case ON_DRIVER_EDIT_REQUEST[0]:
      return { ...state, loading: true };
    case ON_DRIVER_EDIT_REQUEST[1]:
      return { ...state, loading: false, driverData: action.payload.data };
    case ON_DRIVER_EDIT_REQUEST[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false, err:'' };

    case ON_GET_CONNECT_DRIVER_INFO[0]:
      return { ...state, loading: true };
    case ON_GET_CONNECT_DRIVER_INFO[1]:
      return { ...state, loading: false};
    case ON_GET_CONNECT_DRIVER_INFO[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false,};

    case ON_CONNECT_DRIVER[0]:
      return { ...state, loading: true };
    case ON_CONNECT_DRIVER[1]:
      return { ...state, loading: false};
    case ON_CONNECT_DRIVER[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false,};

    case ON_CONNECT_DRIVER_LOCAL[0]:
      return { ...state, loading: true };
    case ON_CONNECT_DRIVER_LOCAL[1]:
      return { ...state, loading: false};
    case ON_CONNECT_DRIVER_LOCAL[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false,};


    case ON_DISCONNECT_DRIVER[0]:
      return { ...state, loading: true };
    case ON_DISCONNECT_DRIVER[1]:
      return { ...state, loading: false};
    case ON_DISCONNECT_DRIVER[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false,};
      
      

    default:
      return state;
  }
};
