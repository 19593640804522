import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

const columns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Bid', dataIndex: 'bid', key: 'bid' },
        { title: 'Type', dataIndex: 'type', key: 'type' },
        { title: 'Desc', dataIndex: 'desc', key: 'desc' },

        { title: ' ', key: 'operation', render: () => <a href="javascript:;">Accept</a> },
];

const BidsTable = (props) => {
    const data = [];
      if(props.data.bidInformation){
           props.data.bidInformation.map((val,i)=>{
              data.push({
                key: i,
                name: val.name,
                bid: val.value,
                type: val.type,
                desc:val.description,
                bider:val.bidder
              });
         })
      }
  return(
    <Table
      dataSource={data}
      columns={columns}
      onRow={(record, index) => {
                return {
                  onClick: () => {
                      props.makeTrip({_id:props.data._id,name:record.name,totalPayment:record.bid,})
                    // window.location.reload();
                  }
                };
              }}
      pagination={{ pageSize: 5 }}
      style={{ minheight: 250, borderRadius: 2, backgroundColor: 'white' }}
      size="middle"
      scroll={{ x: '100%', y: false }}
    />

  );
}
export default BidsTable;
BidsTable.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
};
BidsTable.defaultProps = {
  data: [],
  type: 'Trips',
};