import { ON_REPORTS_GET_REQUEST, ON_REPORT_UPDATE_REQUEST } from "../action/actionType";
import { message } from "antd";

export default (state = { loading:false, newReports:"" }, action) => {
  switch (action.type) {
    // case SET_DRIVER_MODAL_VISIBLE:
    //   return { ...state, driverModalVisible: action.payload,loading:false };

    case ON_REPORTS_GET_REQUEST[0]:
      return { ...state, loading: true };
    case ON_REPORTS_GET_REQUEST[1]:
      return { ...state, loading: false, newReports: action.payload.data };
    case ON_REPORTS_GET_REQUEST[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false };

    case ON_REPORT_UPDATE_REQUEST[0]:
      return { ...state, loading: true };
    case ON_REPORT_UPDATE_REQUEST[1]:
      return { ...state, loading: false,  };
    case ON_REPORT_UPDATE_REQUEST[2]:
      message.error(action.error.response?action.error.response.data.message:"Server is not Responding")
      return { ...state, loading: false };

    default:
      return state;
  }
};