import { createApiActionTypes } from "../../../../constants/constants";

let main = "DETAILS";

// export const SET_NAV_NAME = `${main}SET_NAV_NAME`;

export const ON_DRIVER_DETAIL_GET_REQUEST = createApiActionTypes(main, "ON_DRIVER_DETAIL_GET_REQUEST");
export const ON_VEHICLE_DETAIL_GET_REQUEST = createApiActionTypes(main, "ON_VEHICLE_DETAIL_GET_REQUEST");
export const ON_CLIENT_DETAIL_GET_REQUEST = createApiActionTypes(main, "ON_CLIENT_DETAIL_GET_REQUEST");
export const ON_BROKER_DETAIL_GET_REQUEST = createApiActionTypes(main, "ON_BROKER_DETAIL_GET_REQUEST");

export const ON_DELETE_REQUEST_DVCB = createApiActionTypes(main, "ON_DELETE_REQUEST_DVCB");



