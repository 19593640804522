import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Progress } from "antd";
import { sizes, textColor } from "../../constants/constants";

const StatusComponent = props => {
  const { type, type2, value1, value2, status } = props;
  return (
    <div>
      <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8} span={8}>
        <Row>
          <Col
            span={12}
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 15
              }}
            >
              <span style={{ color: textColor.text_light, fontSize: sizes.h3 }}>
                {type}
              </span>
              <span
                style={{
                  color: textColor.text_midle,
                  fontSize: sizes.headingLarge,
                  marginTop:10
                }}
              >
                {value1}
              </span>
            </div>
          </Col>
          <Col span={12} style={{ justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 10
              }}
            >
              <Progress
                type="circle"
                size="small"
                format={() => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        color: textColor.text_light,
                        fontSize: sizes.h6
                      }}
                    >
                      {type2}
                    </span>
                    <span
                      style={{
                        color: textColor.text_dark,
                        fontSize: sizes.h2,
                        marginTop: 5
                      }}
                    >
                      {value2}
                    </span>
                  </div>
                )}
                percent={value2}
                width={80}
                status={status}
                strokeWidth={12}
                strokeLinecap="square"
              />
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default StatusComponent;

StatusComponent.propTypes = {
  type2: PropTypes.string,
  type: PropTypes.string,
  value1: PropTypes.number,
  value2: PropTypes.number,
  status: PropTypes.string
};
StatusComponent.defaultProps = {
  type: "Type",
  type2: "Type",
  value1: 0,
  value2: 0,
  status: "0"
};
