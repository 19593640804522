import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Popover, Icon, Avatar,Badge } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import * as Actions from "../../modules/loggedin/dashboard/action/actionCreater";
import * as ActionsTrips from "../../modules/loggedin/trips/action/actionCreater";
import * as ActionsGlobal from "../../modules/loggedin/sidebar/action/actionCreater";

import { Images } from "../../public/asset/index";
import { isMobile, sizes, textColor, colors } from "../../constants/constants";

const RequestCard = props => {
  const {
    clientName,
    tripId,
    date,
    tripPickUpLocation,
    tripDropOffLocation,
    pickUpCity,
    dropOffCity, 
    type,
  } = props;
 
  return (
    
    <div className="box2"
        style={{
          borderBottomColor: 'rgba(0,0,0,0.12)',
          borderBottomWidth: 1,
          borderBottomStyle: 'groove',
          borderTopColor: 'rgba(0,0,0,0.12)',
          borderTopWidth: 1,
          borderTopStyle: 'groove',
          padding: 20,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Col span={16}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span
                style={{
                  fontSize: sizes.h1,
                  color: colors.primaryColor,
                  marginBottom: 2,
                }}
              >

              
                #TRP{tripId}  {type==='Market'?' (Market)':" (Local)"}
              </span>
              <span
                style={{
                  fontSize: sizes.h5,
                  color: textColor.text_light,
                  marginBottom: 10,
                }}
              >
                <Icon type="calendar" />
                {moment(date).format("L")}
              </span>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span
                style={{
                  fontSize: sizes.h3,
                  marginBottom: 10,
                  marginRight: 20,
                  float: 'right',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: 130,
                  height: '1.32em',
                  whiteSpace: 'nowrap',
                }}
              >
                    {clientName}
              </span>
            </div>
          </Col>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            style={{
              marginTop: 8,
              marginRight: 12,
              color: textColor.text_midle,
            }}
            alt=""
            height={40}
            src={Images.LINER}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span
              style={{
                fontSize: sizes.h3,
                color: textColor.text_light,
                marginBottom: 10,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 180,
                height: '1.32em',
                whiteSpace: 'nowrap',
              }}
            >
            {`${tripPickUpLocation} ${pickUpCity}`}

            </span>
            <span
              style={{
                fontSize: sizes.h3,
                color: textColor.text_light,
                marginBottom: 10,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 180,
                height: '1.32em',
                whiteSpace: 'nowrap',
              }}
            >
            {`${tripDropOffLocation} ${dropOffCity}`}

            </span>
          </div>
        </div>
      </div>
  );
};
const mapStateToProps = ({ dashboard, global, signup }) => ({
  requestModalVisible: dashboard.requestModalVisible,
  logData: global.logData,
  userInfo: signup.content,
  allTripRequests: global.allTripRequests
});
const mapDispatchToProps = dispatch => ({
  

});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestCard);
RequestCard.propTypes = {
  clientName: PropTypes.string,
  tripPickUpLocation: PropTypes.string,
  tripDropOffLocation: PropTypes.string,
  pickUpCity: PropTypes.string,
  dropOffCity: PropTypes.string
};

RequestCard.defaultProps = {
  clientName: "No Client",
  tripPickUpLocation: "not found",
  tripDropOffLocation: "not found",
  pickUpCity: "not found",
  dropOffCity: "not found"
};
