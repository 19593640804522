import { SET_DRIVER_MODAL_VISIBLE, ON_DRIVER_ADD_REQUEST, ON_DRIVER_EDIT_REQUEST,ON_GET_CONNECT_DRIVER_INFO,ON_CONNECT_DRIVER,ON_DISCONNECT_DRIVER, ON_CONNECT_DRIVER_LOCAL } from "./actionType";

export const ON_CHANGE_DRIVER_MODAL_VISIBLE = visible => ({
  type: SET_DRIVER_MODAL_VISIBLE,
  payload: visible
});

export const onDriverAddRequest = data => dispatch =>
  dispatch({
    types: ON_DRIVER_ADD_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/portal/driver",
        method: "post",
        data: {
          ...data
        }
      }
    }
  });

export const onDriverEditRequest = data => dispatch =>
  dispatch({
    types: ON_DRIVER_EDIT_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/portal/driver",
        method: "put",
        data: {
          ...data
        }
      }
    }
  });
  
export const onGetConnectDriverInfo = data => dispatch =>
  dispatch({
    types: ON_GET_CONNECT_DRIVER_INFO,
    payload: {
      client: "default",
      request: {
        url: "/portal/driver",
        method: "get",
        data: {
          ...data
        }
      }
    }
  });
export const onConnectDriver = data => dispatch =>
  dispatch({
    types: ON_CONNECT_DRIVER,
    payload: {
      client: "default",
      request: {
        url: "/portal/driver/connect/existing",
        method: "post",
        data: {
          ...data
        }
      }
    }
  });

export const onConnectDriverLocal = data => dispatch =>
  dispatch({
    types: ON_CONNECT_DRIVER_LOCAL,
    payload: {
      client: "default",
      request: {
        url: "/portal/driver/connect",
        method: "post",
        data: {
          ...data
        }
      }
    }
  });
export const onDissConnectDriver = data => dispatch =>
  dispatch({
    types: ON_DISCONNECT_DRIVER,
    payload: {
      client: "default",
      request: {
        url: "/portal/driver/disconnect",
        method: "post",
        data: {
          ...data
        }
      }
    }
  });