import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Icon,
  Input,
  Select,
  Radio,
  Spin,
  Avatar
} from "antd";
import { sizes, colors } from "../../../constants/constants";
import MainCard from "../../../components/mainCard/mainCard";
import MainModal from "../../../components/modals/mainModal";
import { connect } from "react-redux";
import * as Actions from "./action/actionCreater";
import * as ActionsGlobal from "../sidebar/action/actionCreater";
import { Images } from "../../../public/asset";

const Search = Input.Search;
const Option = Select.Option;
const InputGroup = Input.Group;
const ButtonGroup = Button.Group;

class Brokers extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, isCard: true, allBrokersState: [] };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.allBrokers !== this.props.allBrokers) {
      const { allBrokers } = nextProps;
      this.setState({ allBrokersState: allBrokers });
    } else {
      const { allBrokers } = this.props;
      this.setState({ allBrokersState: allBrokers });
    }
  }
  componentWillMount() {
    const { allBrokers } = this.props;
    this.setState({ allBrokersState: allBrokers });
  }
  Search = text => {
    const { allBrokers } = this.props;
    if (allBrokers !== 0) {
      if (text === "" || text === undefined) {
        this.setState({ allBrokersState: allBrokers });
      } else {
        this.setState({
          allBrokersState: allBrokers.filter(
            val =>
              val.name === text ||
              val.phoneNumber === text ||
              (val.address ? val.address.city : "") === text
          )
        });
      }
    }
  };
  render() {
    const {
      brokerModalVisible,
      onChangeBrokerModalVisible,
      onChangeDetailData,
      onChangeNavName
    } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <MainModal visible={brokerModalVisible} modalType={"broker"} />
        <div className="ant-card" style={{ height: "100%" }}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              span={12}
              style={{ padding: 20 }}
            >
              <InputGroup compact style={{ marginTop: 8, marginLeft: 20 }}>
                <Select placeholder="Category" style={{ width: 120 }}>
                  <Option value="Driver">Driver</Option>
                  <Option value="Location">Location</Option>
                  <Option value="Vehicle">Vehicle</Option>
                </Select>
                <Search
                  style={{ width: 300 }}
                  placeholder="Search"
                  onChange={text => {
                    this.Search(text.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              span={12}
              style={{ padding: 20 }}
            >
              <div style={{ float: "right" }}>
                <Button
                  size="default"
                  type="primary"
                  icon="plus"
                  style={{ marginRight: 12 }}
                  onClick={() => {
                    onChangeBrokerModalVisible(true);
                  }}
                >
                  Add Broker
                </Button>
                {/*
                <Radio.Group>
                  <span style={{ paddingRight: 10, fontSize: 16 }}>view :</span>
                  <ButtonGroup>
                    <Button
                      size="default"
                      type={this.state.isCard ? "primary" : "ghost"}
                      icon="credit-card"
                      onClick={() => {
                        this.setState({ isCard: true });
                      }}
                    >
                      Card
                    </Button>
                    <Button
                      size="default"
                      type={!this.state.isCard ? "primary" : "ghost"}
                      icon="ordered-list"
                      onClick={() => {
                        this.setState({ isCard: false });
                      }}
                    >
                      List
                    </Button>
                  </ButtonGroup>
                </Radio.Group>
                                */}
              </div>
            </Col>
          </Row>
        </div>
        <Spin
          style={{ minHeight: "65vh",padding:0 }}
          indicator={
            <Icon type="chrome" style={{ fontSize: sizes.headingLarge }} spin />
          }
          spinning={!loading}
        >
          <div
            // className="dashboardMain"
            style={{ height: "100%", overflowY: "scroll" ,marginRight:"3%",marginLeft:"3%",marginTop:"2%"}}
          >
          <Row type={"flex"} style={{justifyContent:"center"}}>
            {this.state.allBrokersState.length !== 0 ? (
              this.state.allBrokersState.map((value, key) => {
                return (
                  <Col
                    key={key}
                    style={{ padding: 10, minWidth:350}}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={6}
                    xxl={6}
                    span={6}
                    onClick={() => {
                      onChangeDetailData({
                        type: "Broker",
                        id: value._id
                      });
                      onChangeNavName({
                        first: "Transporters",
                        second: value.name
                      });
                      this.props.history.push("/loggedin/detail");
                    }}
                  >
                    <MainCard
                      picture={value.profilePicture}
                      id={value._id}
                      type="Broker"
                      name={value.name}
                      status="Not Assigned"
                      mobile={value.phoneNumber}
                      emailOrTruck="Not Assigned"
                      addressOrLocation={
                        (value.address.street ? value.address.street : "") +
                        " " +
                        (value.address.city
                          ? value.address.city
                          : " Not Assigned")
                      }
                    />
                  </Col>
                );
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Avatar
                  size="large"
                  style={{
                    width: 110,
                    height: 90,
                    marginTop: "20%",
                    opacity: 0.55
                  }}
                  src={Images.NO_DATA}
                />
                <h1
                  style={{
                    color: colors.black,
                    fontSize: sizes.heading,
                    fontWeight: 340,
                    opacity: 0.5,
                    marginTop: 10
                  }}
                >
                  You Have No Broker added in Your Fleet
                </h1>
                <h1
                  style={{
                    color: colors.black,
                    fontSize: sizes.h2,
                    fontWeight: 300,
                    opacity: 0.4
                  }}
                >
                  Use add Transporters button above to add your first Broker
                </h1>
              </div>
            )}
            </Row>
            </div>
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = ({ brokers, global }) => ({
  brokerModalVisible: brokers.brokerModalVisible,
  allBrokers: global.allBrokers
});
const mapDispatchToProps = dispatch => ({
  onChangeBrokerModalVisible: visible =>
    dispatch(Actions.ON_CHANGE_BROKER_MODAL_VISIBLE(visible)),
  onChangeDetailData: data =>
    dispatch(ActionsGlobal.ON_CHANGE_DETAIL_DATA(data)),
  onChangeNavName: navName =>
    dispatch(ActionsGlobal.ON_CHANGE_NAV_NAME(navName))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Brokers);
