import {
  ON_TRIPS_DETAIL_GET_REQUEST,
  ON_TRIP_STATUS_CHANGE,
  SET_TRIP_UPON_PUSH_TO_ARRAY,
  ON_TRIPS_GET_REQUEST,
  SET_DETAIL_DATA_FALSE,
  ON_TRIPS_GET_REQUEST_SEARCH,
  SET_TRIP_REQUEST_PUSH_TO_ARRAY,
  ON_TRIPS_REQUEST_GET,
  ON_TRIPS_REQUEST_DETAIL_GET,
  ON_TRIPS_REQUEST_GET_SEARCH,
  ON_TRIP_REQUEST_CHANGE_TO_TRIP
} from "../action/actionType";
import { message } from "antd";
export default (
  state = {
    loading: false,
    loading2: false,
    loading3: false,
    tripDetailData: false,
    tripRequestDetailData:false,
    tripsCardArray: [],
    tripRequestsCardArray: []
  },
  action
) => {
  switch (action.type) {
    // this case is for updating the array of all Trips in Array section,
    case SET_TRIP_UPON_PUSH_TO_ARRAY:
      return { ...state, tripsCardArray: action.payload ? action.payload : [] };

    case SET_DETAIL_DATA_FALSE:
      return { ...state, tripDetailData: false };

    case SET_TRIP_REQUEST_PUSH_TO_ARRAY:
      return {
        ...state,
        tripRequestsCardArray: action.payload ? action.payload : []
      };

    // this case is for getting detail  data of the trips which is selected,

    case ON_TRIPS_DETAIL_GET_REQUEST[0]:
      return { ...state, loading2: true };
    case ON_TRIPS_DETAIL_GET_REQUEST[1]:
      return { ...state, loading2: false, tripDetailData: action.payload.data };
    case ON_TRIPS_DETAIL_GET_REQUEST[2]:
      message.error(
        action.error.response
          ? action.error.response.data.message
          : "server is not responding"
      );
      return { ...state, loading2: false, tripDetailData: false };


      case ON_TRIPS_REQUEST_DETAIL_GET[0]:
      return { ...state, loading2: true };
    case ON_TRIPS_REQUEST_DETAIL_GET[1]:
      return { ...state, loading2: false, tripRequestDetailData: action.payload.data };
    case ON_TRIPS_REQUEST_DETAIL_GET[2]:
      message.error(
        action.error.response
          ? action.error.response.data.message
          : "server is not responding"
      );
      return { ...state, loading2: false, tripRequestDetailData: false };
      
    // this case is for getting Trips  data of user  acording to filer the trips which is selected,
    case ON_TRIPS_GET_REQUEST[0]:
      return { ...state, loading: true };
    case ON_TRIPS_GET_REQUEST[1]:
      return { ...state, loading: false, tripsCardArray: action.payload.data };
    case ON_TRIPS_GET_REQUEST[2]:
      message.error(
        action.error.response
          ? action.error.response.data.message
          : "server is not responding"
      );
      return {
        ...state,
        loading: false,
        tripDetailData: false,
        tripsCardArray: []
      };
      

      case ON_TRIPS_REQUEST_GET[0]:
      return { ...state, loading: true };
    case ON_TRIPS_REQUEST_GET[1]:
      return { ...state, loading: false, tripRequestsCardArray: action.payload.data };
    case ON_TRIPS_REQUEST_GET[2]:
      message.error(
        action.error.response
          ? action.error.response.data.message
          : "server is not responding"
      );
      return {
        ...state,
        loading: false,
        tripDetailData: false,
        tripRequestsCardArray: []
      };
    // when we search or filter
    case ON_TRIPS_GET_REQUEST_SEARCH[0]:
      return { ...state, loading3: true };
    case ON_TRIPS_GET_REQUEST_SEARCH[1]:
      return { ...state, loading3: false, tripsCardArray: action.payload.data };
    case ON_TRIPS_GET_REQUEST_SEARCH[2]:
      message.error(
        action.error.response
          ? action.error.response.data.message
          : "server is not responding"
      );
      return {
        ...state,
        loading3: false,
        tripDetailData: false,
        tripsCardArray: []
      };


      case ON_TRIPS_REQUEST_GET_SEARCH[0]:
      return { ...state, loading3: true };
    case ON_TRIPS_REQUEST_GET_SEARCH[1]:
      return { ...state, loading3: false, tripRequestsCardArray: action.payload.data };
    case ON_TRIPS_REQUEST_GET_SEARCH[2]:
      message.error(
        action.error.response
          ? action.error.response.data.message
          : "server is not responding"
      );
      return {
        ...state,
        loading3: false,
        tripRequestDetailData: false,
        tripRequestsCardArray: []
      };
      
    // this case is for changing status of that Trip,

    case ON_TRIP_STATUS_CHANGE[0]:
      return { ...state, loading2: true };
    case ON_TRIP_STATUS_CHANGE[1]:
      return { ...state, loading2: false };
    case ON_TRIP_STATUS_CHANGE[2]:
      message.error(
        action.error.response
          ? action.error.response.data.message
          : "server is not responding"
      );
      return { ...state, loading2: false };


      case ON_TRIP_REQUEST_CHANGE_TO_TRIP[0]:
      return { ...state, loading2: true };
    case ON_TRIP_REQUEST_CHANGE_TO_TRIP[1]:
      return { ...state, loading2: false,tripsCardArray:action.payload.data };
    case ON_TRIP_REQUEST_CHANGE_TO_TRIP[2]:
      message.error(
        action.error.response
          ? action.error.response.data.message
          : "server is not responding"
      );
      return { ...state, loading2: false,
        tripDetailData: false,
        tripsCardArray: [] };
      
    default:
      return state;
  }
};
