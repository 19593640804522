import { SET_VEHICLE_MODAL_VISIBLE, ON_VEHICLE_ADD_REQUEST, ON_VEHICLE_EDIT_REQUEST } from "./actionType";

export const ON_CHANGE_VEHICLE_MODAL_VISIBLE = visible => ({
  type: SET_VEHICLE_MODAL_VISIBLE,
  payload: visible
});

export const onVehicleAddRequest = data => dispatch =>
  dispatch({
    types: ON_VEHICLE_ADD_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/vehicle",
        method: "post",
        data: {
          ...data
        }
      }
    }
  });
export const onVehicleEditRequest = data => dispatch =>
  dispatch({
    types: ON_VEHICLE_EDIT_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/vehicle",
        method: "put",
        data: {
          ...data
        }
      }
    }
  });