import React, { Component } from "react";
import { Modal, Col, Form, Select, Input, Button, message } from "antd";
import { connect } from "react-redux";
import * as Actions from "../../../modules/loggedin/vehicleManagement/action/actionCreater";
import * as ActionsGlobal from "../../../modules/loggedin/sidebar/action/actionCreater";
import * as ActionsDetail from "../../../modules/loggedin/detail/action/actionCreater";
import ModalCompoentLeftComtent from "../../modalComponentLeftContent/modalComponentLeftViewContent";
import { Images } from "../../../public/asset/index";
import { sizes, textColor } from "../../../constants/constants";
import ApnaDivider from "../../apnaDivider/apnaDivider";
import UploadImageMultiple from "../../uploadImage/uploadImageMultiple";
import UploadImageMultiple2 from "../../uploadImage/uploadImageMultiple2";

import moment from "moment";
const Option = Select.Option;
class VehicleModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multiUrl1: [],
      multiUrl2: [],
      picArra:[
        "https://elasticbeanstalk-us-east-1-435177095658.s3.amazonaws.com/1a81d470-6e61-11e9-9a6e-93292be1f397"
        ,"https://elasticbeanstalk-us-east-1-435177095658.s3.amazonaws.com/1ce259b0-6e61-11e9-9a6e-93292be1f397"
        ,"https://elasticbeanstalk-us-east-1-435177095658.s3.amazonaws.com/1ea602b0-6e61-11e9-9a6e-93292be1f397"
        ,"https://elasticbeanstalk-us-east-1-435177095658.s3.amazonaws.com/20d3b820-6e61-11e9-9a6e-93292be1f397"
      ]
    };
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        const {
          onVehicleAddRequest,
          userInfo,
          onChangeVehicleModalVisible,
          onAddVehiclePush,
          onGetLogData,
          onVehicleEditRequest,
          onAllVehiclesInfoGet,
          onChangeNavName,
          onAllDriversInfoGet,
          onGetDetailVehicleData
        } = this.props;

        if (userInfo !== "") {
          const data = {
            plateNo: values.registrationNumber,
            chassisNumber: values.chasisNo ? values.chasisNo : null,
            engineNumber: values.engineNo ? values.engineNo : null,
            vehicleType: values.vehicalType,
            vehicleOwnershipType: "Transporters Owned",
            vehicleCreatedDate: moment().toISOString(),
            accountType: "Transporter",
            documentPictures:
              this.state.multiUrl2.length !== 0
                ? this.state.multiUrl2
                : [
                  this.state.picArra[Math.floor(Math.random() * Math.floor(3))]
                ], //could be ["dasdsadassa","adasdasdasd"]
            vehiclePictures:
              this.state.multiUrl1.length !== 0
                ? this.state.multiUrl1
                : [
                  this.state.picArra[Math.floor(Math.random() * Math.floor(3))]
                ],
            description: "this is vehicle",
            currentAssignDriver: values.selctDriver ? values.selctDriver : null,
            userId: userInfo.user._id // this field is required and should be mongoose id
          };
          const data2 = {
            vehicleMongooseId: this.props.data ? this.props.data._id : "",
            plateNo: values.registrationNumber,
            chassisNumber: values.chasisNo ? values.chasisNo : null,
            engineNumber: values.engineNo ? values.engineNo : null,
            vehicleType: values.vehicalType,
            vehicleOwnershipType: "Transporters Owned",
            accountType: "Transporter",
            documentPictures:
              this.state.multiUrl2.length !== 0
                ? this.state.multiUrl2
                : [
                  this.state.picArra[Math.floor(Math.random() * Math.floor(3))]
                ], //could be ["dasdsadassa","adasdasdasd"]
            vehiclePictures:
              this.state.multiUrl1.length !== 0
                ? this.state.multiUrl1
                : [
                  this.state.picArra[Math.floor(Math.random() * Math.floor(3))]
                ],
            description: "this is vehicle",
            currentAssignDriver: values.selctDriver ? values.selctDriver : null,
            userId: userInfo.user._id // this field is required and should be mongoose id
          };

          if (this.props.data) {
            const hello = async () => {
              let response = await onVehicleEditRequest(data2);
              if (response.payload.data !== "") {
                onChangeVehicleModalVisible(false);
                onAllDriversInfoGet({ id: userInfo.user._id });
                message.success("Vehicle Updated");
                onChangeNavName({
                  first: "Vehicles",
                  second: "Detail",
                  third: values.registrationNumber
                });
                onAllVehiclesInfoGet({ id: userInfo.user._id });
                onGetDetailVehicleData({
                  id: this.props.data._id,
                  userId: userInfo.user._id
                });
              }
            };
            hello();
          } else {
            const hello = async () => {
              let response = await onVehicleAddRequest(data);
              if (response.payload.data !== "") {
                onChangeVehicleModalVisible(false);
                onAddVehiclePush(response.payload.data);
                onAllDriversInfoGet({ id: userInfo.user._id });
                onGetLogData({ id: userInfo.user._id });
                message.success("Vehical Saved");
              }
            };
            hello();
          }
        }
      }
    });
  };

  getMultiImageUrlFromChild1 = urls => {
    this.setState({ multiUrl1: urls });
  };
  getMultiImageUrlFromChild2 = urls => {
    this.setState({ multiUrl2: urls });
  };
  render() {
    const {
      vehicleModalVisible,
      onChangeVehicleModalVisible,
      allDrivers,
      vehicalCat,
      vehicleType,
      loading
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title={null}
        footer={null}
        header={null}
        visible={vehicleModalVisible}
        maskClosable={false}
        onCancel={() => {
          onChangeVehicleModalVisible(false);
        }}
        width={900}
        bodyStyle={{ padding: 0, borderRadius: 0 }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            className="hideSideModal"
            style={{ height: 550, width: 400, backgroundColor: "#F62459" }}
          >
            <ModalCompoentLeftComtent
              mainHeading={"Add Vehicle"}
              extraText={"Enter Vehicle Details"}
              Image={Images.TRUCK_ONE}
            />
          </div>
          <div
            style={{
              width: "100%",
              height: 550,
              overflowY: "scroll",
              paddingTop: 45,
              paddingBottom: 45,
              paddingLeft: 45,
              paddingRight: 45
            }}
          >
            <Form onSubmit={this.handleSubmit} spellCheck={true}>
              <ApnaDivider text={"Information"} />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Col span={6} style={{ paddingTop: 5 }}>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_dark }}
                  >
                    * Vehicle :
                  </span>
                </Col>
                <Col span={18}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item>
                      {getFieldDecorator("category", {})(
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: 200, paddingRight: 10 }}
                          placeholder="Select a Category"
                        >
                          {vehicalCat
                            ? vehicalCat.map((val, ind) => {
                              return (
                                <Option key={val._id} value={val._id}>
                                  {val.title}
                                </Option>
                              );
                            })
                            : ""}
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("vehicalType", {
                        rules: [
                          {
                            required: true,
                            message: "Please Select Vehicle Type"
                          }
                        ]
                      })(
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: 200, paddingLeft: 10 }}
                          placeholder="Select a type"
                        >
                          {vehicleType
                            ? vehicleType.map((val, ind) => {
                              return (
                                <Option key={val._id} value={val._id}>
                                  {val.title}
                                </Option>
                              );
                            })
                            : ""}
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Col span={6} style={{ paddingTop: 5 }}>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_dark }}
                  >
                    * Registration No :
                  </span>
                </Col>
                <Col span={18}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item>
                      {getFieldDecorator("registrationNumber", {
                        rules: [
                          {
                            required: true,
                            message: "Please Enter Registration No"
                          }
                        ]
                      })(
                        <Input
                          style={{ width: 190, paddingRight: 10 }}
                          placeholder="Enter registration No"
                          type="text"
                        />
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Col span={6} style={{ paddingTop: 5 }}>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_dark }}
                  >
                    Others :
                  </span>
                </Col>
                <Col span={18}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item>
                      {getFieldDecorator("chasisNo", {})(
                        <Input
                          style={{ width: 190, marginRight: 10 }}
                          placeholder="Chasis No (optional)"
                          type="text"
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("engineNo", {})(
                        <Input
                          style={{ width: 190, marginLeft: 10 }}
                          placeholder="Engine No (optional)"
                          type="text"
                        />
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Col span={6} style={{ paddingTop: 5 }}>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_dark }}
                  >
                    Vehicle Images :
                  </span>
                </Col>
                <Col span={18}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <UploadImageMultiple
                      getMultiImageUrlFromChild={
                        this.getMultiImageUrlFromChild1
                      }
                      picsArray={this.state.multiUrl1}
                    />
                  </div>
                </Col>
              </div>
              <ApnaDivider text={"Documents"} />

              <div style={{ display: "flex", flexDirection: "row" }}>
                <Col span={6} style={{ paddingTop: 5 }}>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_dark }}
                  >
                    Document Images :
                  </span>
                </Col>
                <Col span={18}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <UploadImageMultiple2
                      getMultiImageUrlFromChild={
                        this.getMultiImageUrlFromChild2
                      }
                      picsArray={this.state.multiUrl2}
                    />
                  </div>
                </Col>
              </div>
              <ApnaDivider text={"Chose Driver"} />

              <div style={{ display: "flex", flexDirection: "row" }}>
                <Col span={6} style={{ paddingTop: 5 }}>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_dark }}
                  >
                    Assign Driver :
                  </span>
                </Col>
                <Col span={18}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item>
                      {getFieldDecorator("selctDriver", {})(
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: 200, paddingRight: 10 }}
                          placeholder="Select a Driver"
                        >
                          <Option key={1} value={null}>
                            none
                          </Option>
                          {allDrivers
                            ? allDrivers.map((val, ind) => {
                              return (
                                <Option key={val._id} value={val._id}>
                                  {val.name}
                                </Option>
                              );
                            })
                            : ""}
                        </Select>
                      )}
                    </Form.Item>
                    <span
                      style={{
                        marginLeft: 20,
                        paddingTop: 5,
                        color: textColor.text_light
                      }}
                    >
                      You can always assign later
                    </span>
                  </div>
                </Col>
              </div>
              <div>
                <div style={{ float: "right" }}>
                  <Button
                    type="default"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      onChangeVehicleModalVisible(false);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  }
  componentDidMount() {
    const { data } = this.props;
    if (data) {
      this.props.form.setFields({
        registrationNumber: {
          value: this.props.data.plateNo ? this.props.data.plateNo : ""
        },
        vehicalType: {
          value: this.props.data.vehicleTypeInformation
            ? this.props.data.vehicleTypeInformation._id
            : ""
        },
        engineNo: {
          value: this.props.data.engineNumber
            ? this.props.data.engineNumber
            : ""
        },
        chasisNo: {
          value: this.props.data.chassisNumber
            ? this.props.data.chassisNumber
            : ""
        },
        selctDriver: {
          value: this.props.data.driverInformation
            ? this.props.data.driverInformation._id
            : ""
        }
      });
      if (data.vehiclePictures !== null && data.vehiclePictures !== undefined) {
        let vPics = [];
        data.vehiclePictures.map((val, ind) => {
          vPics.push(val);
        });
        this.setState({ multiUrl1: vPics });
      }
      if (
        data.documentPictures !== null &&
        data.documentPictures !== undefined
      ) {
        let vPics2 = [];
        data.documentPictures.map((val, ind) => {
          vPics2.push(val);
        });
        this.setState({ multiUrl2: vPics2 });
      }
    }
  }
}

const VehicleModal = Form.create()(VehicleModalForm);
const mapStateToProps = ({ vehicles, global, signup }) => ({
  vehicleModalVisible: vehicles.vehicleModalVisible,
  vehicalCat: global.globalData.vehicleCategory,
  vehicleType: global.globalData.vehicleType,
  allDrivers: global.allDrivers,
  userInfo: signup.content,
  loading: vehicles.loading
});
const mapDispatchToProps = dispatch => ({
  onChangeVehicleModalVisible: visible =>
    dispatch(Actions.ON_CHANGE_VEHICLE_MODAL_VISIBLE(visible)),
  onVehicleAddRequest: data => dispatch(Actions.onVehicleAddRequest(data)),
  onAddVehiclePush: data =>
    dispatch(ActionsGlobal.ON_VEHICLE_ADDED_PUSH_TO_ALL(data)),
  onGetLogData: data => dispatch(ActionsGlobal.onGetLogData(data)),
  onVehicleEditRequest: data => dispatch(Actions.onVehicleEditRequest(data)),
  onAllVehiclesInfoGet: data =>
    dispatch(ActionsGlobal.onAllVehiclesInfoGet(data)),
  onAllDriversInfoGet: data =>
    dispatch(ActionsGlobal.onAllDriversInfoGet(data)),
  onChangeNavName: navName =>
    dispatch(ActionsGlobal.ON_CHANGE_NAV_NAME(navName)),
  onGetDetailVehicleData: data =>
    dispatch(ActionsDetail.onGetDetailVehicleData(data))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleModal);
