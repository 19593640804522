import React, { Component } from "react";
import {
  Modal,
  Col,
  Form,
  Select,
  Input,
  Button,
  message
} from "antd";
import { connect } from "react-redux";
import * as Actions from "../../../modules/loggedin/driverManagement/action/actionCreater";
import * as ActionsGlobal from "../../../modules/loggedin/sidebar/action/actionCreater";
import * as ActionsDetail from "../../../modules/loggedin/detail/action/actionCreater";

import ModalCompoentLeftComtent from "../../modalComponentLeftContent/modalComponentLeftViewContent";
import { Images } from "../../../public/asset/index";
import { sizes, textColor } from "../../../constants/constants";
import ApnaDivider from "../../apnaDivider/apnaDivider";
import cities from "../../../constants/jsons/cities.json";
import Axios from "axios";
import { driverAppBackendUrl } from "../../../constants/config";
const Option = Select.Option;
const childrenCities = [];
childrenCities.length = 0;
if (cities["Pakistan"] === undefined) {
} else {
  cities["Pakistan"].forEach(element => {
    childrenCities.push(<Option key={element}>{element}</Option>);
  });
}
class DriverModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = { driverInfo:false ,loading:false};
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loading:true})
        console.log("Received values of form: ", values);
        Axios.get(driverAppBackendUrl+"/api/webHandler/driverInfo?id="+values.ID).then((response)=>{
          if (response.data.data.message.data.length!==0) {
            this.setState({driverInfo:response.data.data.message.data[0],loading:false})
            console.log(response)
          }else{
            message.error("Driver not found with this Id")
            this.setState({loading:false,driverInfo:false})
          }
                
        }).catch((err)=>{
          console.log(err)
        })
        // const {
          
        //   userInfo,onGetConnectDriverInfo,
        //   // onChangeDriverModalVisible,onAddDriversPush,onGetLogData,onAllDriversInfoGet,onChangeNavName,onAllVehiclesInfoGet,onGetDetailDriverData
        // } = this.props;
        // if (userInfo !== "") {
        //   const data = {
            
        //   };
         
        //     const hello = async () => {
        //       let response = await onGetConnectDriverInfo(data);
        //       if (response.payload.data !== "") {
        //           this.setState({driverInfo:response.payload.data})
        //         // console.log(userInfo.user._id);
        //         // onChangeDriverModalVisible(false);
        //         //  onAllVehiclesInfoGet({ id: userInfo.user._id });
        //         // onAddDriversPush(response.payload.data)
        //         // onGetLogData({ id: userInfo.user._id })
        //         // message.success("Driver Added");
        //       }
        //     };
        //     hello();
          
        // }
      }
    });
  };

  onConenct=()=>{
    const {
      userInfo,onConnectDriver,
      onChangeDriverModalVisible,onGetLogData,onAddDriversPush,
      onGetDetailDriverData,onAllDriversInfoGet,onChangeNavName,
      type,DATA,onConnectDriverLocal
    } = this.props;
    if (userInfo !== "") {
      if (type==="local") {
        const data = {
          userId:userInfo.user._id,
          remoteDriverId:this.state.driverInfo.driverId,
          localDriverId:DATA._id
        };
     
        const hello = async () => {
          let response = await onConnectDriverLocal(data);
          if (response.payload.data !== "") {
            this.setState({driverInfo:false})
            onChangeDriverModalVisible(false);
            onAllDriversInfoGet({ id: userInfo.user._id });
            onGetDetailDriverData({ userId: userInfo.user._id,id : DATA._id })
            message.success("Driver Connected");
          }
        };
        hello();

      }else{

        const data = {
          userId:userInfo.user._id,
          remoteDriverId:this.state.driverInfo.driverId
        };
     
        const hello = async () => {
          let response = await onConnectDriver(data);
          if (response.payload.data !== "") {
            this.setState({driverInfo:false})
            console.log(userInfo.user._id);
            onChangeDriverModalVisible(false);
            //  onAllVehiclesInfoGet({ id: userInfo.user._id });
            onAddDriversPush(response.payload.data)
            onGetLogData({ id: userInfo.user._id })
            message.success("Driver Connected");
          }
        };
        hello();
      }
            
        
    }
  }
  renderDriverInfo=()=>{
    if (this.state.driverInfo) {
      const {
        onChangeDriverModalVisible,
        loading
      } = this.props;
      return(
        <div>
          <div style={{ display: "flex", flexDirection: "row",marginTop:15 }}>
            <Col span={6} >
              <span
                style={{
                  fontSize: sizes.h3,
                  color: textColor.text_dark
                }}
              >
                        Name :
              </span>
            </Col>
            <Col span={18}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    fontSize: sizes.h4,
                    color: textColor.text_midle
                  }}
                >
                  {this.state.driverInfo?this.state.driverInfo.name:"Not Applicable"}
                </span>
              </div>
            </Col>
          </div>
          <div style={{ display: "flex", flexDirection: "row",marginTop:15 }}>
            <Col span={6} >
              <span
                style={{
                  fontSize: sizes.h3,
                  color: textColor.text_dark
                }}
              >
                        Mobile Number :
              </span>
            </Col>
            <Col span={18}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    fontSize: sizes.h4,
                    color: textColor.text_midle
                  }}
                >
                  {this.state.driverInfo?this.state.driverInfo.phoneNumber:"Not Applicable"}
                </span>
              </div>
            </Col>
          </div>
          <div style={{ display: "flex", flexDirection: "row",marginTop:15 }}>
            <Col span={6} >
              <span
                style={{
                  fontSize: sizes.h3,
                  color: textColor.text_dark
                }}
              >
                        Address :
              </span>
            </Col>
            <Col span={18}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    fontSize: sizes.h4,
                    color: textColor.text_midle
                  }}
                >
                  {this.state.driverInfo?this.state.driverInfo.address.street+" "+this.state.driverInfo.address.city:"Not Applicable"}                       
                </span>
              </div>
            </Col>
          </div>
          <div style={{ display: "flex", flexDirection: "row",marginTop:15 }}>
            <Col span={6} >
              <span
                style={{
                  fontSize: sizes.h3,
                  color: textColor.text_dark
                }}
              >
                        CNIC :
              </span>
            </Col>
            <Col span={18}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    fontSize: sizes.h4,
                    color: textColor.text_midle
                  }}
                >
                  {this.state.driverInfo?this.state.driverInfo.cnicNo:"Not Applicable"}

                        
                </span>
              </div>
            </Col>
          </div>
          <div style={{ display: "flex", flexDirection: "row",marginTop:15 }}>
            <Col span={6} >
              <span
                style={{
                  fontSize: sizes.h3,
                  color: textColor.text_dark
                }}
              >
                        Driver Id :
              </span>
            </Col>
            <Col span={18}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    fontSize: sizes.h4,
                    color: textColor.text_midle
                  }}
                >
                  {this.state.driverInfo?this.state.driverInfo.driverId:"Not Applicable"}

                </span>
              </div>
            </Col>
          </div>
          <div>
            <div style={{ float: "right",marginTop:15 }}>
              <Button
                type="default"
                style={{ marginRight: "20px" }}
                onClick={() => {
                  onChangeDriverModalVisible(false);
                }}
              >
                    Back
              </Button>
              <Button
                type="primary"
                onClick={()=>{this.onConenct()}}
                loading={loading}
                disabled={loading}
              >
                    Connect
              </Button>
            </div>
          </div>
        </div>
      )
    }else{
      return(
        <div style={{flexDirection:"column",display:"flex",textAlign:"center"}}>
          <span>No data to Display</span>
        </div>
      )
    }
  }
  render() {
    const {
      driverModalVisible,
      onChangeDriverModalVisible,
    } = this.props;
    const {loading} = this.state
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title={null}
        footer={null}
        header={null}
        visible={driverModalVisible}
        onOk={() => {
          alert("OK");
        }}
        maskClosable={false}
        onCancel={() => {
          onChangeDriverModalVisible(false);
        }}
        width={900}
        bodyStyle={{ padding: 0, borderRadius: 0 }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            className="hideSideModal"
            style={{ height: 580, width: 400, backgroundColor: "#22A7F0" }}
          >
            <ModalCompoentLeftComtent
              mainHeading={"Connect Driver"}
              extraText={"Use the Driver's unique ID to Connect"}
              Image={Images.DRIVER}
            />
          </div>
          <div
            style={{
              width: "100%",
              height: 580,
              overflowY: "scroll",
              paddingTop: 30,
              paddingBottom: 45,
              paddingLeft: 45,
              paddingRight: 45
            }}
          >
            
            <ApnaDivider text={"Connect Driver"} />
            <Form onSubmit={this.handleSubmit} spellCheck={true}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Form.Item >
                  {getFieldDecorator("ID", {
                    rules: [
                      {
                        required: true,
                        message: "Please Enter Driver ID to Connect",
                               
                      }
                    ]
                  })(
                    <Input
                      style={{
                        width: 230,
                        paddingRight: 10,
                        marginLeft: 0,
                        marginRight:20 
                      }}
                      placeholder="Enter Driver ID here"
                      type="text"
                    />
                  )}
                </Form.Item>
                <Button
                  style={{marginTop:2}}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                            Get Info
                </Button>
              </div>
                    
            </Form>

            <ApnaDivider text={"Driver Information"} />
            {this.renderDriverInfo()}
             
              
          </div>
        </div>
      </Modal>
    );
  }

}
const ConnectDriverModal = Form.create()(DriverModalForm);

const mapStateToProps = ({ drivers, signup,global,detail }) => ({
  driverModalVisible: drivers.driverModalVisible,
  userInfo: signup.content,
  loading: drivers.loading,
  allVehicles:global.allVehicles,
  DATA:detail.detailData,
});
const mapDispatchToProps = dispatch => ({
  onChangeDriverModalVisible: visible =>
    dispatch(Actions.ON_CHANGE_DRIVER_MODAL_VISIBLE(visible)),
  onDriverAddRequest: data => dispatch(Actions.onDriverAddRequest(data)),
  onAddDriversPush: data => dispatch(ActionsGlobal.ON_DRIVER_ADDED_PUSH_TO_ALL(data)),
  onGetLogData: data => dispatch(ActionsGlobal.onGetLogData(data)),

  onDriverEditRequest: data => dispatch(Actions.onDriverEditRequest(data)),
  
  onAllDriversInfoGet: data => dispatch(ActionsGlobal.onAllDriversInfoGet(data)),
  onAllVehiclesInfoGet: data => dispatch(ActionsGlobal.onAllVehiclesInfoGet(data)),
  onChangeNavName: navName => dispatch(ActionsGlobal.ON_CHANGE_NAV_NAME(navName)),
  onGetDetailDriverData: data =>
    dispatch(ActionsDetail.onGetDetailDriverData(data)),
  
  onGetConnectDriverInfo: data => dispatch(Actions.onGetConnectDriverInfo(data)),
  onConnectDriver: data => dispatch(Actions.onConnectDriver(data)),
  onConnectDriverLocal: data => dispatch(Actions.onConnectDriverLocal(data)),


});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectDriverModal);
