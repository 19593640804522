import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { multiClientMiddleware } from "redux-axios-middleware";
import axios from "axios";
import { createStore, applyMiddleware } from "redux";
import appReducer from "./reducer";

const middleware = [];

const defaultClient = axios.create({
  baseURL:"https://umeed-1640891749.us-east-1.elb.amazonaws.com",
  responseType: "json"
});
//
//http://54.208.64.193:8000
defaultClient.interceptors.request.use(async config => {
  const authToken = await localStorage.getItem("AUTH_TOKEN");
  if (authToken) {
    Object.assign(config, {
      headers: {
        Authorization: `${authToken}`
      }
    });
  }
  return config;
});
// const defaultClient = axios.create({
//   baseURL: 'http://192.168.1.12:8000',
//   responseType: 'json',
// });
const clients = {
  default: {
    client: defaultClient
  }
};
const axiosMiddleware = multiClientMiddleware(clients, {
  returnRejectedPromiseOnError: true
});
middleware.push(thunk);
middleware.push(axiosMiddleware);

const persistConfig = {
  version: 0,
  key: "root",
  // whitelist: ["session", "permissions"],
  storage
};

const loggerConfig = {
  duration: true,
  diff: true
};

const loggerMiddleware = createLogger(loggerConfig);

middleware.push(loggerMiddleware);

const persistedReducer = persistReducer(persistConfig, appReducer);

const reduxStore = createStore(
  persistedReducer,
  applyMiddleware(...middleware)
);
const persistor = persistStore(reduxStore);

export default reduxStore;
export { persistor };
