import React from "react";
import RequestModal from "./requestModal/requestModal";
import PropTypes from "prop-types";
import DriverModal from "./driverModal/driverModal";
import VehicleModal from "./vehicleModal/vehicleModal";
import ClientModal from "./clientModal/clientModal";
import BrokerModal from "./brokerModal/brokerModal";
import ConnectDriverModal from "./driverModal/connectDriverModal";
import MarkeetRequestModal from "./requestModal/markeetRequestModal";

const MainModal = props => {
  const { modalType } = props;
  if (modalType === "request") {
    return <RequestModal {...props} />;

  } 
  else if (modalType === "markeet") {
    return <MarkeetRequestModal {...props} />;
  }
  else if (modalType === "driver") {

    return <DriverModal {...props} />;
  }else if (modalType === "driverConnect") {
    return <ConnectDriverModal {...props} />;
  }
  else if (modalType === "vehicle") {
    return <VehicleModal {...props} />;
  } else if (modalType === "client") {
    return <ClientModal {...props} />;
  } else if (modalType === "broker") {
    return <BrokerModal {...props} />;
  }
};
export default MainModal; 
MainModal.propTypes = {
  modalType: PropTypes.string.isRequired
};
