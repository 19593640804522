import React, { Component } from "react";
import {
  Row,
  Layout,
  Tabs,
  Input,
  Col,
  Button,
  Icon,
  DatePicker,
  Divider,
  Select,
  message,
  Modal,
  Spin,
  Card
} from "antd";
import {
  isMobile,
  textColor,
  sizes,
  colors
} from "../../../constants/constants";
import FullInfoViewLayout from "../../../components/fullInfoViewLayout/fullInfoViewlayout";
import TripCard from "../../../components/tripInforamation/TripCard";
import { connect } from "react-redux";
import * as ActionsGlobal from "../sidebar/action/actionCreater";
import * as Actions from "./action/actionCreater";
import * as ActionsDashboard from "../dashboard/action/actionCreater";


import moment from "moment";
import cities from "../../../constants/jsons/cities.json";
import RequestCard from "../../../components/tripRequestInfo/RequestCard";
import MainModal from "../../../components/modals/mainModal";
const { RangePicker } = DatePicker;
const { Sider } = Layout;
const { Option } = Select;
const TabPane = Tabs.TabPane;
const Search = Input.Search;
const confirm = Modal.confirm;

const childrenCities = [
  <Option value={null} key={"1"}>
    none
  </Option>
];
childrenCities.length = 1;
if (cities["Pakistan"] === undefined) {
} else {
  cities["Pakistan"].forEach(element => {
    childrenCities.push(<Option key={element}>{element}</Option>);
  });
}

class Trips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      modalStart: false,
      modalEnd: false,
      clicked: true,

      clientId: props.filters.clientId ? props.filters.clientId : null,
      vehicleId: props.filters.vehicleId ? props.filters.vehicleId : null,
      brokerId: props.filters.brokerId ? props.filters.brokerId : null,
      driverId: props.filters.driverId ? props.filters.driverId : null,
      from: props.filters.from ? props.filters.from : null,
      to: props.filters.to ? props.filters.to : null,
      input: null,
      dates: null,
      status: props.filters.status ? props.filters.status : "All",
      type: props.filters.type ? props.filters.type : "Trips",
      from2: props.filters.from ? props.filters.from : null,
      to2: props.filters.to ? props.filters.to : null,
      dates2: null,
    };
  }
  componentDidMount() {
    const { onChangeNavName, onChangeDetailData } = this.props;

    window.onpopstate = e => {
      onChangeNavName({
        first: "Dashboard",
        second: ""
      });
      onChangeDetailData({
        id: "",
        type: "Dashboard"
      });
      // this.props.history.push("/nav")
      this.props.history.push("/loggedIn");
    };
  }
  componentWillMount() {
    const {
      userInfo,
      onGetDetailTripData,
      tripsCardArray,
      onGetTripArrayData,
      ON_SET_DETAIL_DATA_FALSE,
      tripRequestsCardArray,
      filters,
      onGetTripRequestArrayData,
      onGetDetailTripRequestData
    } = this.props;

    if (userInfo !== "") {
      if (tripsCardArray.length !== 0) {
        if (filters.type == "Trips") {
          onGetDetailTripData({
            id: tripsCardArray[0]._id,
            userId: userInfo.user._id
          });
        } else {
          onGetDetailTripRequestData({
            id: tripRequestsCardArray[0]._id,
            userId: userInfo.user._id
          });
        }
      }

      if (filters.type == "Trips") {
        if (tripsCardArray.length === 0) {
          let data = {
            userId: userInfo.user._id,
            status: this.state.status,
            driverId: this.state.driverId,
            vehicleId: this.state.vehicleId,
            brokerId: this.state.brokerId,
            clientId: this.state.clientId,
            accountType: "Transporter",
            skip: "0",
            limit: "500"
          };
          const hello = async () => {
            let response = await onGetTripArrayData(data);
            if (response.payload.data.length !== 0) {
              onGetDetailTripData({
                id: response.payload.data[0]._id,
                userId: userInfo.user._id
              });
            } else {
              ON_SET_DETAIL_DATA_FALSE(false);
            }
          };
          hello();
        }
      } else {
        if (tripRequestsCardArray.length === 0) {
          let data = {
            id: userInfo.user._id
          };
          const hello = async () => {
            let response = await onGetTripRequestArrayData(data);
            if (response.payload.data.length !== 0) {
              onGetDetailTripRequestData({
                id: response.payload.data[0]._id,
                userId: userInfo.user._id
              });
            } else {
              ON_SET_DETAIL_DATA_FALSE(false);
            }
          };
          hello();
        } else {
          onGetDetailTripRequestData({
            id: tripRequestsCardArray[0]._id,
            userId: userInfo.user._id
          });
        }
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.filters !== nextProps.filters) {
      if (nextProps.detail !== "") {
        // here api cals and start loading till data come and goes towards its own components
        const {
          userInfo,
          onGetDetailTripData,
          tripsCardArray,
          onGetTripArrayData,
          ON_SET_DETAIL_DATA_FALSE,
          filters,
          tripRequestsCardArray,
          onGetTripRequestArrayData,
          onGetDetailTripRequestData
        } = nextProps;
        if (userInfo !== "") {
          if (filters.type == "Trips") {
            if (tripsCardArray.length === 0) {
              // ON_TRIP_UPON_PUSH_TO_ARRAY(false)
              let data = {
                userId: userInfo.user._id,
                status: this.state.status,
                driverId: this.state.driverId,
                vehicleId: this.state.vehicleId,
                brokerId: this.state.brokerId,
                clientId: this.state.clientId,
                accountType: "Transporter",
                skip: "0",
                limit: "500"
              };
              const hello = async () => {
                let response = await onGetTripArrayData(data);
                if (response.payload.data.length !== 0) {
                  onGetDetailTripData({
                    id: response.payload.data[0]._id,
                    userId: userInfo.user._id
                  });
                } else {
                  ON_SET_DETAIL_DATA_FALSE(false);
                }
              };
              hello();
            }
          } else {
            if (tripRequestsCardArray.length === 0) {
              // ON_TRIP_UPON_PUSH_TO_ARRAY(false)
              let data = {
                id: userInfo.user._id
              };
              const hello = async () => {
                let response = await onGetTripRequestArrayData(data);
                if (response.payload.data.length !== 0) {
                  onGetDetailTripRequestData({
                    id: response.payload.data[0]._id,
                    userId: userInfo.user._id
                  });
                } else {
                  ON_SET_DETAIL_DATA_FALSE(false);
                }
              };
              hello();
            } else {
              onGetDetailTripRequestData({
                id: tripRequestsCardArray[0]._id,
                userId: userInfo.user._id
              });
            }
          }
        }
      }
    }
  }
  onSearch(val) {
    const { userInfo, onGetTripArrayDataSearch } = this.props;
    if (userInfo !== "") {
      let data = {
        userId: userInfo.user._id,
        status: this.state.status,
        driverId: this.state.driverId,
        vehicleId: this.state.vehicleId,
        brokerId: this.state.brokerId,
        clientId: this.state.clientId,
        startFrom: this.state.from,
        startTo: this.state.to,
        keyword: val ? val : null,
        from: this.state.dates
          ? moment(this.state.dates[0]).toISOString()
          : null,
        to: this.state.dates ? moment(this.state.dates[1]).toISOString() : null,
        accountType: "Transporter",
        skip: "0",
        limit: "500"
      };
      const hello = async () => {
        let response = await onGetTripArrayDataSearch(data);
        if (response.payload.data.length !== 0) {
        } else {
        }
      };
      hello();
    }
  }


  onSearch2(val) {
    const { userInfo, onGetTripRequestArrayDataSearch } = this.props;
    if (userInfo !== "") {
      let data = {
        userId: userInfo.user._id,
        startFrom: this.state.from2,
        startTo: this.state.to2,
        keyword: val ? val : null,
        from: this.state.dates2
          ? moment(this.state.dates2[0]).toISOString()
          : null,
        to: this.state.dates2 ? moment(this.state.dates2[1]).toISOString() : null,
        accountType: "Transporter",
        skip: "0",
        limit: "500"
      };
      const hello = async () => {
        let response = await onGetTripRequestArrayDataSearch(data);
        if (response.payload.data.length !== 0) {
        } else {
        }
      };
      hello();
    }
  }
  getTripDetailData = id => {
    const {
      userInfo,
      onGetDetailTripData,
      filters,
      onGetDetailTripRequestData
    } = this.props;
    if (userInfo !== "") {
      if (filters.type === "Trips") {
        onGetDetailTripData({ id: id, userId: userInfo.user._id });
      } else {
        onGetDetailTripRequestData({ id: id, userId: userInfo.user._id });
        // onGetDetailTripData({ id: id, userId: userInfo.user._id });
      }
    }
  };

  showConfirmEnd = () => {
    confirm({
      title: "Please select the date when ride ended?",
      content: (
        <div style={{ flexDirection: "row", display: "flex" }}>
          <Col span={8}>
            <span style={{ paddingTop: 10 }}>Select Date:</span>
          </Col>
          <Col span={16}>
            <DatePicker
              onChange={date => {
                this.setState({ date: date });
              }}
            />
          </Col>
        </div>
      ),
      onOk: () => {
        this.onEnded();
      },
      onCancel() {}
    });
  };
  showConfirmStart = () => {
    confirm({
      title: "Please select the date when  the ride started?",
      content: (
        <div style={{ flexDirection: "row", display: "flex" }}>
          <Col span={8}>
            <span style={{ paddingTop: 10 }}>Select Date:</span>
          </Col>
          <Col span={16}>
            <DatePicker
              onChange={date => {
                this.setState({ date: date });
              }}
            />
          </Col>
        </div>
      ),
      onOk: () => {
        this.onGoing();
      },
      onCancel() {}
    });
  };

  onGoing = () => {
    const {
      userInfo,
      detail,
      onChangeTripStatus,
      tripsCardArray,
      onGetDetailTripData,
      onAllTripUcomingInfoGet,
      onAllTripOngoingInfoGet,
      onGetLogData,
      DATA
    } = this.props;
    if (userInfo !== "") {
      let data = {
        accountType: "Transporter",
        userId: userInfo.user._id,
        tripId: DATA._id,
        tripStatus: "Ongoing",
        ongoingTripStatus: "On The Way",
        time: {
          started:
            this.state.date !== ""
              ? moment(this.state.date).toISOString()
              : moment().toISOString()
        }
      };
      const hello = async () => {
        let response = await onChangeTripStatus(data);
        if (response.payload.data !== "") {
          onGetDetailTripData({
            id: tripsCardArray[0]._id,
            userId: userInfo.user._id
          });
         
          onAllTripUcomingInfoGet({ id: userInfo.user._id });
          onAllTripOngoingInfoGet({ id: userInfo.user._id });
          onGetLogData({ id: userInfo.user._id });

          // this.props.history.push("/nav")
          message.success("Trip Started");
          // this.props.history.push("/loggedIn")
        }
      };
      hello();
    }
  };
  onEnded = () => {
    const {
      userInfo,
      onChangeTripStatus,
      onGetDetailTripData,
      tripsCardArray,
      onAllTripUcomingInfoGet,
      onAllTripOngoingInfoGet,
      onGetLogData,
      DATA
    } = this.props;
    if (userInfo !== "") {
      let data = {
        accountType: "Transporter",
        userId: userInfo.user._id,
        tripId: DATA._id,
        tripStatus: "Ended",
        time: {
          ended:
            this.state.date !== ""
              ? moment(this.state.date).toISOString()
              : moment().toISOString()
        }
      };
      const hello = async () => {
        let response = await onChangeTripStatus(data);
        if (response.payload.data !== "") {
          onGetDetailTripData({
            id: tripsCardArray[0]._id,
            userId: userInfo.user._id
          });

        
          onAllTripUcomingInfoGet({ id: userInfo.user._id });
          onAllTripOngoingInfoGet({ id: userInfo.user._id });
          onGetLogData({ id: userInfo.user._id });

          // this.props.history.push("/nav")
          message.success("Trip Ended");
        }
      };
      hello();
    }
  };

  renderAdditionalFilters = () => {
    const {
      open,
      allDrivers,
      allBrokers,
      allClients,
      allVehicles
    } = this.props;
    if (open === true) {
      return (
        <div style={{ padding: 10, paddingTop: 0 }}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <span style={{ fontSize: sizes.h1, color: textColor.text_midle }}>
              Filters
            </span>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Button
              type={this.state.status === "All" ? "primary" : "default"}
              size="small"
              style={{ marginLeft: 0, marginRight: 3 }}
              onClick={() => {
                this.setState({ status: "All" }, () => {
                  this.onSearch(false);
                });
              }}
            >
              All
            </Button>
            <Button
              type={this.state.status === "Upcoming" ? "primary" : "default"}
              size="small"
              style={{ marginLeft: 3, marginRight: 3 }}
              onClick={() => {
                this.setState({ status: "Upcoming" }, () => {
                  this.onSearch(false);
                });
              }}
            >
              Upcoming
            </Button>
            <Button
              type={this.state.status === "Ongoing" ? "primary" : "default"}
              size="small"
              style={{ marginLeft: 3, marginRight: 5 }}
              onClick={() => {
                this.setState({ status: "Ongoing" }, () => {
                  this.onSearch(false);
                });
              }}
            >
              Ongoing
            </Button>
            <Button
              type={this.state.status === "Ended" ? "primary" : "default"}
              onClick={() => {
                this.setState({ status: "Ended" }, () => {
                  this.onSearch(false);
                });
              }}
              size="small"
              style={{ marginLeft: 3 }}
            >
              Ended
            </Button>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Col span={4}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_light }}>
                Dates:
              </span>
            </Col>
            <Col span={20}>
              <RangePicker
                size="small"
                format="DD-MM-YYYY"
                placeholder={["From", "To"]}
                onChange={(v, sadsa) => {
                  console.log("Formatted Selected Time: ", v);
                  this.setState({ dates: v }, () => {
                    this.onSearch(false);
                  });
                }}
              />
            </Col>
          </div>
          <Divider style={{ marginTop: 0, marginBottom: 5 }} />
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Col span={4}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_light }}>
                Driver:
              </span>
            </Col>
            <Col span={20} style={{ paddingLeft: 5 }}>
              <Select
                size="small"
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.driverId}
                onChange={val => {
                  this.setState({ driverId: val }, () => {
                    this.onSearch(false);
                  });
                }}
              >
                <Option value={null} key={"1"}>
                  none
                </Option>

                {allDrivers && allDrivers.length !== 0
                  ? allDrivers.map((val, ind) => {
                    return (
                      <Option key={val._id} value={val._id}>
                        {val.name}
                      </Option>
                    );
                  })
                  : ""}
              </Select>
            </Col>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Col span={4}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_light }}>
                Vehicle:
              </span>
            </Col>
            <Col span={20} style={{ paddingLeft: 5 }}>
              <Select
                size="small"
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.vehicleId}
                onChange={val => {
                  this.setState({ vehicleId: val }, () => {
                    this.onSearch(false);
                  });
                }}
              >
                <Option value={null} key={"1"}>
                  none
                </Option>

                {allVehicles && allVehicles.length !== 0
                  ? allVehicles.map((val, ind) => {
                    return (
                      <Option key={val._id} value={val._id}>
                        {val.plateNo}
                      </Option>
                    );
                  })
                  : ""}
              </Select>
            </Col>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Col span={4}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_light }}>
                Client:
              </span>
            </Col>
            <Col span={20} style={{ paddingLeft: 5 }}>
              <Select
                size="small"
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.clientId}
                onChange={val => {
                  this.setState({ clientId: val }, () => {
                    this.onSearch(false);
                  });
                }}
              >
                <Option value={null} key={"1"}>
                  none
                </Option>

                {allClients && allClients.length !== 0
                  ? allClients.map((val, ind) => {
                    return (
                      <Option key={val._id} value={val._id}>
                        {val.name}
                      </Option>
                    );
                  })
                  : ""}
              </Select>
            </Col>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Col span={4}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_light }}>
               Transporter:
              </span>
            </Col>
            <Col span={20} style={{ paddingLeft: 5 }}>
              <Select
                size="small"
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.brokerId}
                onChange={val => {
                  this.setState({ brokerId: val }, () => {
                    this.onSearch(false);
                  });
                }}
              >
                <Option value={null} key={"1"}>
                  none
                </Option>

                {allBrokers && allBrokers.length !== 0
                  ? allBrokers.map((val, ind) => {
                    return (
                      <Option key={val._id} value={val._id}>
                        {val.name}
                      </Option>
                    );
                  })
                  : ""}
              </Select>
            </Col>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Col span={4}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_light }}>
                From:
              </span>
            </Col>
            <Col span={20} style={{ paddingLeft: 5 }}>
              <Select
                size="small"
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.from}
                onChange={val => {
                  this.setState({ from: val }, () => {
                    this.onSearch(false);
                  });
                }}
              >
                {childrenCities}
              </Select>
            </Col>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Col span={4}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_light }}>
                To:
              </span>
            </Col>
            <Col span={20} style={{ paddingLeft: 5 }}>
              <Select
                size="small"
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.to}
                onChange={val => {
                  this.setState({ to: val }, () => {
                    this.onSearch(false);
                  });
                }}
              >
                {childrenCities}
              </Select>
            </Col>
          </div>
        </div>
      );
    } else if (open === false) {
      return <p>he</p>;
    }
  };


  renderAdditionalFiltersTab2 = () => {
    const {
      open,
    } = this.props;
    if (open === true) {
      return (
        <div style={{ padding: 10, paddingTop: 0 }}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <span style={{ fontSize: sizes.h1, color: textColor.text_midle }}>
              Filters
            </span>
          </div>
         
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Col span={4}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_light }}>
                Dates:
              </span>
            </Col>
            <Col span={20}>
              <RangePicker
                size="small"
                format="DD-MM-YYYY"
                placeholder={["From", "To"]}
                onChange={(v, sadsa) => {
                  console.log("Formatted Selected Time: ", v);
                  this.setState({ dates2: v }, () => {
                    this.onSearch2(false);
                  });
                }}
              />
            </Col>
          </div>
          <Divider style={{ marginTop: 0, marginBottom: 5 }} />
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Col span={4}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_light }}>
                From:
              </span>
            </Col>
            <Col span={20} style={{ paddingLeft: 5 }}>
              <Select
                size="small"
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.from2}
                onChange={val => {
                  this.setState({ from2: val }, () => {
                    this.onSearch2(false);
                  });
                }}
              >
                {childrenCities}
              </Select>
            </Col>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <Col span={4}>
              <span style={{ fontSize: sizes.h3, color: textColor.text_light }}>
                To:
              </span>
            </Col>
            <Col span={20} style={{ paddingLeft: 5 }}>
              <Select
                size="small"
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.to2}
                onChange={val => {
                  this.setState({ to2: val }, () => {
                    this.onSearch2(false);
                  });
                }}
              >
                {childrenCities}
              </Select>
            </Col>
          </div>
        </div>
      );
    } else if (open === false) {
      return <p>he</p>;
    }
  };

  onEdit=()=>{
    const {
      filters,
      onChangeRequestModalVisible
    } = this.props;
    if (filters.type==="Trips") {
      
    }else{
      onChangeRequestModalVisible(true)
    }
  }
  makeTrip=(val)=>{
    const {onChangeTripRequestToTrip,onGetLogData,onGetDetailTripData,tripsCardArray,userInfo,onChangeFilterData }= this.props;
    const hello = async () => {
      let data = {
        name:val.name,
        userId:userInfo.user._id,
        accountCreatedDate:moment().toISOString(),
        tripRequestId:val._id,
        type:"Market",
        totalPayment:val.totalPayment,
        tripCreatedDate:moment().toISOString(),
        accountType:"Transporter",
        tripStatus:"Upcoming"
      }
      let response = await onChangeTripRequestToTrip(data);
      if (response.payload.data !== "") {
        onChangeFilterData({
          type: "Trips",
          open: false,
          status:"All"
        });
        // onGetDetailTripData({
        //   id: tripsCardArray[0]._id,
        //   userId: userInfo.user._id
        // });
        
      
        // onAllTripUcomingInfoGet({ id: userInfo.user._id });
        // onAllTripOngoingInfoGet({ id: userInfo.user._id });
        onGetLogData({ id: userInfo.user._id });

        // this.props.history.push("/nav")
        message.success("Trip Added");
      }
    };
    hello();
  }
  render() {
    const {
      open,
      onChangeFilterData,
      filters,
      DATA,
      loading,
      loading3,
      tripsCardArray,
      tripRequestsCardArray,
      ON_TRIP_REQUEST_PUSH_TO_ARRAY,
      ON_TRIP_UPON_PUSH_TO_ARRAY,
      DATA2,
      requestModalVisible
    } = this.props;
    // console.log(filters);
    console.log(open);
    if (!loading) {
      return (
        <div style={{ height: "91vh" }}>
        
          <Row style={{ height: 70 }} className="ant-card">
            <Col span={12}>
              <div style={{ float: "left", display: DATA ? "" : "none" }}>
                <div
                  style={{ flexDirection: "row", display: "flex", padding: 10 }}
                >
                  <h2
                    style={{
                      color: textColor.text_midle,
                      fontSize: sizes.heading,
                      margin: 10
                    }}
                  >
                    TRP#
                    {filters.type === "Requests"
                      ? DATA2
                        ? DATA2.tripRequestId
                        : "N/A"
                      : DATA
                        ? DATA.tripId
                        : "N/A"}
                  </h2>
                  <Button
                    type="default"
                    style={{
                      backgroundColor: "rgba(35,29,31,0.1)",
                      margin: 10,
                      marginTop: 10
                    }}
                    size="small"
                  >
                    Dispatched
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ float: "right", display: DATA ? "" : "none" }}>
                <div
                  style={{ flexDirection: "row", display: "flex", padding: 5 }}
                >
                  <Button type="primary" style={{ margin: 10 }} onClick={()=>{this.onEdit()}}>
                    Edit
                  </Button>
                  {/* <Button
                    type="danger"
                    style={{
                      margin: 10
                    }}
                  >
                    Delete
                  </Button> */}
                </div>
              </div>
            </Col>
          </Row>
          <Layout>
            <Sider
              theme="light"
              style={{
                height: "85vh",
                borderColor: "rgba(0,0,0,0.12)",
                borderWidth: 1,
                borderStyle: "groove",
                paddingRight: -5
              }}
              width={isMobile ? window.innerWidth : 260}
            >
              <div>
                <Tabs
                  onChange={val => {
                    if (val === "1") {
                      onChangeFilterData({
                        type: "Trips",
                        open: false,
                        status: "All"
                      });
                      ON_TRIP_UPON_PUSH_TO_ARRAY(false);
                    } else {
                      ON_TRIP_REQUEST_PUSH_TO_ARRAY(false);
                      onChangeFilterData({
                        type: "Requests",
                        open: false,
                        status: "All"
                      });
                    }
                  }}
                  size="small"
                  // tabBarStyle={{justifyContent:"space-around"}}
                  style={{
                    fontSize: "1px",
                    color: "rgba(0, 0, 0, 0.45)"
                  }}
                  defaultActiveKey={filters.type === "Trips" ? "1" : "2"}
                >
                  <TabPane tab="Trips" key="1">
                    <div style={{ height: 30, marginBottom: 5 }}>
                      <Search
                        onSearch={val => {
                          this.onSearch(val);
                        }}
                        placeholder="input search text"
                        style={{ width: "90%", marginLeft: 10 }}
                      />
                    </div>
                    <div
                      style={{
                        height: "75vh",
                        overflowY: "scroll",
                        overflowX: "hidden"
                      }}
                    >
                      {this.renderAdditionalFilters()}
                      <span
                        style={{
                          fontSize: sizes.h3,
                          color: colors.primaryColor,
                          margin: 10,
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          onChangeFilterData({ ...filters, open: !open,status:"All",type:"Trips" });
                        }}
                      >
                        <Icon
                          type={!open ? "plus" : "minus"}
                          size="small"
                          shape="circle"
                        />{" "}
                        Additional Filters
                      </span>
                      {!loading3 ? (
                        <div>
                          {tripsCardArray.length !== 0 ? (
                            tripsCardArray.map((val, ind) => {
                              return (
                                <div
                                  key={ind}
                                  onClick={() => {
                                    this.getTripDetailData(val._id);
                                  }}
                                >
                                  <TripCard key={ind} data={val} />
                                </div>
                              );
                            })
                          ) : (
                            <div>No Trips</div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <Card loading={loading3} style={{ height: 150 }} />
                          <Card loading={loading3} style={{ height: 150 }} />
                          <Card loading={loading3} style={{ height: 150 }} />
                          <Card loading={loading3} style={{ height: 150 }} />
                        </div>
                      )}
                    </div>
                  </TabPane>
                  <TabPane tab="Requested" key="2">
                    <div style={{ height: 50 }}>
                      <Search
                        onSearch={val => {
                          this.onSearch2(val);
                        }}
                        placeholder="input search text"
                        style={{ width: "90%", marginLeft: 10 }}
                      />
                    </div>
                    <div
                      style={{
                        height: 700,
                        overflowY: "scroll",
                        overflowX: "hidden"
                      }}
                    >
                      {this.renderAdditionalFiltersTab2()}
                      <span
                        style={{
                          fontSize: sizes.h3,
                          color: colors.primaryColor,
                          margin: 10
                        }}
                        onClick={() => {
                          onChangeFilterData({ ...filters, open: !open,status:"All",type:"Requests" });
                        }}
                      >
                        <Icon
                          type={!open ? "plus" : "minus"}
                          size="small"
                          shape="circle"
                        />{" "}
                        Additional Filters
                      </span>
                      {!loading3 ? (
                        <div>
                          {tripRequestsCardArray.length !== 0 ? (
                            tripRequestsCardArray.map((val, ind) => {
                              let name =
                                val.type == "Local"
                                  ? "Local"
                                  : "Self";
                              return (
                                <div
                                  onClick={() => {
                                    this.getTripDetailData(val._id);
                                  }}
                                >
                                  <RequestCard
                                    key={ind}
                                    clientName={name}
                                    tripId={val.tripRequestId}
                                    date={val.requestedTime}
                                    tripPickUpLocation={
                                      val.pickupLocation
                                        ? val.pickupLocation.street
                                        : "N/A"
                                    }
                                    tripDropOffLocation={
                                      val.dropoffLocation
                                        ? val.dropoffLocation.street
                                        : "N/A"
                                    }
                                    pickUpCity={
                                      val.pickupLocation
                                        ? val.pickupLocation.city
                                        : "N/A"
                                    }
                                    dropOffCity={
                                      val.dropoffLocation
                                        ? val.dropoffLocation.city
                                        : "N/A"
                                    }
                                    id={val._id}
                                    type={val.type}
                                    history={this.props.history}
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <div>No Trips</div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <Card loading={loading3} style={{ height: 150 }} />
                          <Card loading={loading3} style={{ height: 150 }} />
                          <Card loading={loading3} style={{ height: 150 }} />
                          <Card loading={loading3} style={{ height: 150 }} />
                        </div>
                      )}
                      {/* <TripCard /> */}
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </Sider>
            {this.renderFullViewLayout()}
          </Layout>
        </div>
      );
    } else {
      return (
        <Row type="flex" justify="center" align="middle">
          <Icon
            size="large"
            type="loading"
            style={{ fontSize: "50px", marginTop: "25%" }}
          />
        </Row>
      );
    }
  }
  renderFullViewLayout = () => {
    const { loading2, DATA, DATA2, filters,requestModalVisible } = this.props;
    if (!loading2) {
      return (
        <Layout>
          {filters.type === "Trips" ? (
            <FullInfoViewLayout
              type="Trips"
              data={DATA ? DATA : false}
              showConfirmStart={this.showConfirmStart}
              showConfirmEnd={this.showConfirmEnd}
            />
          ) : (
            <div>
              <MainModal visible={requestModalVisible} modalType={"markeet"}  data={DATA2?DATA2:false} />
              <FullInfoViewLayout type="Requests" data={DATA2 ? DATA2 : false} makeTrip={this.makeTrip} />

            </div>
          )}
        </Layout>
      );
    } else {
      return (
        <div style={{ width: "100%" }}>
          <Spin
            style={{ marginTop: "10%" }}
            tip={"Getting Data..."}
            size="large"
            spinning={loading2}
            indicator={<Icon type="loading" style={{ fontSize: 25 }} spin />}
          >
            <Layout>
              {filters.type === "Trips" ? (
                <FullInfoViewLayout
                  type="Trips"
                  data={DATA ? DATA : false}
                  showConfirmStart={this.showConfirmStart}
                  showConfirmEnd={this.showConfirmEnd}
                />
              ) : (
                <FullInfoViewLayout type="Driver" data={DATA ? DATA : false} />
              )}
            </Layout>
          </Spin>
        </div>
      );
    }
  };
}
const mapStateToProps = state => ({
  open: state.global.filters.open,
  userInfo: state.signup.content,
  DATA: state.trips.tripDetailData,
  DATA2: state.trips.tripRequestDetailData,
  navName: state.global.navName,
  loading: state.trips.loading,
  loading2: state.trips.loading2,
  loading3: state.trips.loading3,
  detail: state.global.detail,
  filters: state.global.filters,
  tripsCardArray: state.trips.tripsCardArray?state.trips.tripsCardArray:[],
  tripRequestsCardArray: state.trips.tripRequestsCardArray?state.trips.tripRequestsCardArray:[],
  allDrivers: state.global.allDrivers,
  allBrokers: state.global.allBrokers,
  allClients: state.global.allClients,
  allVehicles: state.global.allVehicles,
  requestModalVisible: state.dashboard.requestModalVisilbe,

});
const mapDispatchToProps = dispatch => ({
  onChangeFilterData: filter =>
    dispatch(ActionsGlobal.ON_CHANGE_FILTER_DATA(filter)),
  onGetDetailTripData: data => dispatch(Actions.onGetDetailTripData(data)),
  onGetDetailTripRequestData: data =>
    dispatch(Actions.onGetDetailTripRequestData(data)),
    onChangeTripRequestToTrip: data =>
    dispatch(Actions.onChangeTripRequestToTrip(data)),

  onChangeTripStatus: data => dispatch(Actions.onChangeTripStatus(data)),
  onChangeDetailData: data =>
    dispatch(ActionsGlobal.ON_CHANGE_DETAIL_DATA(data)),
  onChangeNavName: navName =>
    dispatch(ActionsGlobal.ON_CHANGE_NAV_NAME(navName)),
  onAllTripUcomingInfoGet: data =>
    dispatch(ActionsGlobal.onAllTripUcomingInfoGet(data)),
  onAllTripOngoingInfoGet: data =>
    dispatch(ActionsGlobal.onAllTripOngoingInfoGet(data)),
  onGetLogData: data => dispatch(ActionsGlobal.onGetLogData(data)),
  onGetTripArrayData: data => dispatch(Actions.onGetTripArrayData(data)),
  onGetTripRequestArrayData: data =>
    dispatch(Actions.onGetTripRequestArrayData(data)),

  onGetTripArrayDataSearch: data =>
    dispatch(Actions.onGetTripArrayDataSearch(data)),
  onGetTripRequestArrayDataSearch: data =>
    dispatch(Actions.onGetTripRequestArrayDataSearch(data)),
  ON_TRIP_UPON_PUSH_TO_ARRAY: data =>
    dispatch(Actions.ON_TRIP_UPON_PUSH_TO_ARRAY(data)),
  ON_TRIP_REQUEST_PUSH_TO_ARRAY: data =>
    dispatch(Actions.ON_TRIP_REQUEST_PUSH_TO_ARRAY(data)),

  ON_SET_DETAIL_DATA_FALSE: data =>
    dispatch(Actions.ON_SET_DETAIL_DATA_FALSE(data)),
  onChangeRequestModalVisible: visible =>
    dispatch(ActionsDashboard.ON_CHANGE_REQUEST_MODAL_VISIBLE(visible)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Trips);
