import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

const columns = [
  {
    title: 'Type',
    width: '25%',
    dataIndex: 'Cat',
    key: 'Cat',
  },
  {
    title: 'Vehicle No',
    dataIndex: 'no',
    width: '25%',
    key: 'no',
  },
  {
    title: 'Driver Name',
    dataIndex: 'driver',
    width: '25%',
    key: 'driver',
  },
  {
    title: 'Quantity',
    dataIndex: 'qty',
    width: '25%',
    key: 'qty',
  },
];

const FLtable = (props) => {
  let data2 = [];
  props.data.map((val,ind)=>{
    data2.push({
      key: ind,
      Cat: val.vehicleType.title,
      no: val.vehicleName!=="Test"?val.vehicleName:"Not Assigned",
      driver: val.driverName!=="Test"?val.driverName:"Not Assigned",
      qty:val.vehicleType.quantity
    })
  }) 
  return(
    <Table
      dataSource={data2}
      columns={columns}
      pagination={{ pageSize: 5 }}
      style={{ minheight: 250, borderRadius: 2, backgroundColor: 'white' }}
      size="middle"
      scroll={{ x: '100%', y: false }}
    />

  );
}
export default FLtable;
FLtable.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
};
FLtable.defaultProps = {
  data: [],
  type: 'Trips',
};