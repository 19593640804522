import { SET_BROKER_MODAL_VISIBLE, ON_BROKER_ADD_REQUEST,ON_BROKER_EDIT_REQUEST } from "./actionType";

export const ON_CHANGE_BROKER_MODAL_VISIBLE = visible => ({
  type: SET_BROKER_MODAL_VISIBLE,
  payload: visible
});

export const onBrokerAddRequest = data => dispatch =>
  dispatch({
    types: ON_BROKER_ADD_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/portal/broker",
        method: "post",
        data: {
          ...data
        }
      }
    }
  });
  
export const onBrokerEditRequest = data => dispatch =>
  dispatch({
    types: ON_BROKER_EDIT_REQUEST,
    payload: {
      client: "default",
      request: {
        url: "/portal/broker",
        method: "put",
        data: {
          ...data
        }
      }
    }
  });