import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Icon,
  Input,
  Select,
  Radio,
  Spin,
  Avatar
} from "antd";
import { sizes, colors } from "../../../constants/constants";
import MainCard from "../../../components/mainCard/mainCard";
import { connect } from "react-redux";
import * as Actions from "./action/actionCreater";
import { Images } from "../../../public/asset";
import MainModal from "../../../components/modals/mainModal";
import * as ActionsGlobal from "../sidebar/action/actionCreater";

const Search = Input.Search;
const Option = Select.Option;
const InputGroup = Input.Group;
const ButtonGroup = Button.Group;

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, isCard: true, allClientsState: [] };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.allClients !== this.props.allClients) {
      const { allClients } = nextProps;
      this.setState({ allClientsState: allClients });
    } else {
      const { allClients } = this.props;
      this.setState({ allClientsState: allClients });
    }
  }
  componentWillMount() {
    const { allClients } = this.props;
    this.setState({ allClientsState: allClients });
  }
  Search = text => {
    const { allClients } = this.props;
    if (allClients !== 0) {
      if (text === "" || text === undefined) {
        this.setState({ allClientsState: allClients });
      } else {
        this.setState({
          allClientsState: allClients.filter(
            val =>
              val.name === text ||
              val.primaryNumber === text ||
              (val.email ? val.email : "") === text
          )
        });
      }
    }
  };
  render() {
    const {
      clientModalVisible,
      onChangeClientModalVisible,
      onChangeDetailData,
      onChangeNavName
    } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <MainModal visible={clientModalVisible} modalType={"client"} />
        <div className="ant-card" style={{ height: "100%" }}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              span={12}
              style={{ padding: 20 }}
            >
              <InputGroup compact style={{ marginTop: 8, marginLeft: 20 }}>
                <Select placeholder="Category" style={{ width: 120 }}>
                  <Option value="Driver">Driver</Option>
                  <Option value="Location">Location</Option>
                  <Option value="Vehicle">Vehicle</Option>
                </Select>
                <Search
                  style={{ width: 300 }}
                  placeholder="Search"
                  onChange={text => {
                    this.Search(text.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              span={12}
              style={{ padding: 20 }}
            >
              <div style={{ float: "right" }}>
                <Button
                  size="default"
                  type="primary"
                  icon="plus"
                  style={{ marginRight: 12 }}
                  onClick={() => {
                    onChangeClientModalVisible(true);
                  }}
                >
                  Add Client
                </Button>
                {/*
                <Radio.Group>
                  <span style={{ paddingRight: 10, fontSize: 16 }}>view :</span>
                  <ButtonGroup>
                    <Button
                      size="default"
                      type={this.state.isCard ? "primary" : "ghost"}
                      icon="credit-card"
                      onClick={() => {
                        this.setState({ isCard: true });
                      }}
                    >
                      Card
                    </Button>
                    <Button
                      size="default"
                      type={!this.state.isCard ? "primary" : "ghost"}
                      icon="ordered-list"
                      onClick={() => {
                        this.setState({ isCard: false });
                      }}
                    >
                      List
                    </Button>
                  </ButtonGroup>
                </Radio.Group>
                */}
              </div>
            </Col>
          </Row>
        </div>
        <Spin
          style={{ minHeight: "65vh" }}
          indicator={
            <Icon type="chrome" style={{ fontSize: sizes.headingLarge }} spin />
          }
          spinning={!loading}
        >
           <div
            // className="dashboardMain"
            style={{ height: "100%", overflowY: "scroll" ,marginRight:"3%",marginLeft:"3%",marginTop:"2%"}}
          >
          <Row type={"flex"} style={{justifyContent:"center"}}>
            {this.state.allClientsState.length !== 0 ? (
              this.state.allClientsState.map((value, key) => {
                return (
                  <Col
                    key={key}
                    style={{ padding: 10, minWidth:330 }}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={8}
                    xxl={6}
                    span={6}
                    onClick={() => {
                      onChangeDetailData({
                        type: "Client",
                        id: value._id
                      });
                      onChangeNavName({
                        first: "Clients",
                        second: value.name
                      });
                      this.props.history.push("/loggedin/detail");
                    }}
                  >
                    <MainCard
                      id={value._id}
                      type="Broker"
                      picture={
                        value.profilePicture
                          ? value.profilePicture
                          : Images.ICON_THREE
                      }
                      name={value.name}
                      status="Not Assigned"
                      mobile={value.primaryNumber}
                      emailOrTruck={value.email ? value.email : "Not Assigned"}
                      addressOrLocation={
                        (value.address.street ? value.address.street : "") +
                        " " +
                        (value.address.city
                          ? value.address.city
                          : "Not Assigned")
                      }
                    />
                  </Col>
                );
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Avatar
                  size="large"
                  style={{
                    width: 110,
                    height: 90,
                    marginTop: "20%",
                    opacity: 0.55
                  }}
                  src={Images.NO_DATA}
                />
                <h1
                  style={{
                    color: colors.black,
                    fontSize: sizes.heading,
                    fontWeight: 340,
                    opacity: 0.5,
                    marginTop: 10
                  }}
                >
                  You Have No Client added in Your Fleet
                </h1>
                <h1
                  style={{
                    color: colors.black,
                    fontSize: sizes.h2,
                    fontWeight: 300,
                    opacity: 0.4
                  }}
                >
                  Use add client button above to add your first Client
                </h1>
              </div>
            )}
            </Row>
          </div>
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = ({ clients, global }) => ({
  clientModalVisible: clients.clientModalVisible,
  allClients: global.allClients
});
const mapDispatchToProps = dispatch => ({
  onChangeClientModalVisible: visible =>
    dispatch(Actions.ON_CHANGE_CLIENT_MODAL_VISIBLE(visible)),
  onChangeDetailData: data =>
    dispatch(ActionsGlobal.ON_CHANGE_DETAIL_DATA(data)),
  onChangeNavName: navName =>
    dispatch(ActionsGlobal.ON_CHANGE_NAV_NAME(navName))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Clients);
