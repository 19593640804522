import { createApiActionTypes } from "../../../../constants/constants";

let main = "TRIPS";

// export const SET_NAV_NAME = `${main}SET_NAV_NAME`;

export const ON_TRIPS_DETAIL_GET_REQUEST = createApiActionTypes(main, "ON_DRIVER_DETAIL_GET_REQUEST");
export const ON_TRIPS_REQUEST_DETAIL_GET = createApiActionTypes(main, "ON_TRIPS_REQUEST_DETAIL_GET");

export const ON_TRIP_STATUS_CHANGE = createApiActionTypes(main, "ON_TRIP_STATUS_CHANGE");
export const ON_TRIP_REQUEST_CHANGE_TO_TRIP = createApiActionTypes(main, "ON_TRIP_REQUEST_CHANGE_TO_TRIP");


export const ON_TRIPS_GET_REQUEST = createApiActionTypes(main, "ON_TRIPS_GET_REQUEST");
export const ON_TRIPS_REQUEST_GET = createApiActionTypes(main, "ON_TRIPS_REQUEST_GET");

export const ON_TRIPS_GET_REQUEST_SEARCH = createApiActionTypes(main, "ON_TRIPS_GET_REQUEST_SEARCH");
export const ON_TRIPS_REQUEST_GET_SEARCH = createApiActionTypes(main, "ON_TRIPS_REQUEST_GET_SEARCH");





export const SET_DETAIL_DATA_FALSE = `${main}SET_DETAIL_DATA_FALSE`;
export const SET_TRIP_UPON_PUSH_TO_ARRAY = `${main}SET_TRIP_UPON_PUSH_TO_ARRAY`;
export const SET_TRIP_REQUEST_PUSH_TO_ARRAY = `${main}SET_TRIP_REQUEST_PUSH_TO_ARRAY`;




