import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Icon,
  Input,
  Select,
  Radio,
  Spin,
  Avatar,
  Modal
} from "antd";
import { connect } from "react-redux";
import openSocket from "socket.io-client";
import { sizes, colors, textColor } from "../../../constants/constants";
import MainCard from "../../../components/mainCard/mainCard";
import MapComponent from "../../../components/mapComponent/mapComponent";
import * as Actions from "./action/actionCreater";
import * as ActionsGlobal from "../sidebar/action/actionCreater";
import MainModal from "../../../components/modals/mainModal";
import { Images } from "../../../public/asset";
import { driverAppGpsSocket } from "../../../constants/config";

const Search = Input.Search;
const Option = Select.Option;
const InputGroup = Input.Group;
const ButtonGroup = Button.Group;

const socket = openSocket(driverAppGpsSocket,{
  transports: ['websocket'], 
  upgrade: false,
});
class Drivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isCard: false,
      allDriversState: [],
      nextModal: null,
      selectModalVisible: false,
      OnlineDrivers: []
    };
  }

  renderListView = () => {
    const { onChangeDetailData, onChangeNavName, allDrivers } = this.props;
    console.log("c", typeof allDrivers, allDrivers);
    const { isCard, loading } = this.state;
    if (isCard === true) {
      return (
        <Spin
          style={{ minHeight: "65vh" }}
          indicator={
            <Icon type="chrome" style={{ fontSize: sizes.headingLarge }} spin />
          }
          spinning={!loading}
        >
          <div
            // className="dashboardMain"
            style={{ height: "100%", overflowY: "scroll" ,marginRight:"3%",marginLeft:"3%",marginTop:"2%"}}
          >
          <Row type={"flex"} style={{justifyContent:"center"}}>
            {this.state.allDriversState.length !== 0 ? (
              this.state.allDriversState.map((value, key) => {
                return (
                  <Col
                    key={key}
                    style={{ padding: 10, minWidth: 350 }}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    xl={6}
                    xxl={6}
                    span={6}
                    onClick={() => {
                      console.log("clickeddddddd");
                      onChangeDetailData({
                        type: "Driver",
                        id: value._id
                      });
                      onChangeNavName({
                        first: "Drivers",
                        second: value.name,
                      });
                      this.props.history.push("/loggedin/detail");
                    }}
                  >
                    <MainCard
                      id={value._id}
                      type="Driver"
                      name={value.name}
                      picture={value.profilePicture}
                      status={
                        value.connectionStatus
                          ? value.connectionStatus
                          : "Not Assigned"
                      }
                      mobile={value.phoneNumber}
                      emailOrTruck={
                        value.vehicleInformation
                          ? value.vehicleInformation.plateNo
                          : "Not Assigned"
                      }
                      addressOrLocation={
                        value.currentLocation
                          ? value.currentLocation
                          : "Not Applicable"
                      }
                    />
                  </Col>
                );
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Avatar
                  size="large"
                  style={{
                    width: 110,
                    height: 90,
                    marginTop: "20%",
                    opacity: 0.55
                  }}
                  src={Images.NO_DATA}
                />
                <h1
                  style={{
                    color: colors.black,
                    fontSize: sizes.heading,
                    fontWeight: 340,
                    opacity: 0.5,
                    marginTop: 10
                  }}
                >
                  You Have No Driver added in Your Fleet
                </h1>
                <h1
                  style={{
                    color: colors.black,
                    fontSize: sizes.h2,
                    fontWeight: 300,
                    opacity: 0.4
                  }}
                >
                  Use add driver button above to add your first driver
                </h1>
              </div>
            )}
            </Row>
          </div>
        </Spin>
      );
    } else {
      return (
        <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                minWidth: 330,
                maxWidth: 330
              }}
            >
              <div
                style={{
                  height: window.innerHeight - 120,
                  backgroundColor: "white",
                  width: "100%",
                  overflowY: "scroll",
                  marginLeft: 5
                }}
              >
                {this.state.allDriversState.length !== 0 ? (
                  this.state.allDriversState.map((value, key) => {
                    return (
                      <div
                        className="list-card-box"
                        key={key}
                        onClick={() => {
                          onChangeDetailData({
                            type: "Driver",
                            id: value._id
                          });
                          onChangeNavName({
                            first: "Drivers",
                            second: value.name,
                          });
                          this.props.history.push("/loggedin/detail");
                        }}
                      >
                        <MainCard
                          id={value._id}
                          type="Driver"
                          name={value.name}
                          picture={value.profilePicture}
                          status={
                            value.connectionStatus
                              ? value.connectionStatus
                              : "Not Assigned"
                          }
                          mobile={value.phoneNumber}
                          emailOrTruck={
                            value.vehicleInformation
                              ? value.vehicleInformation.plateNo
                              : "Not Assigned"
                          }
                          addressOrLocation={
                            value.currentLocation
                              ? value.currentLocation
                              : "Not Applicable"
                          }
                        />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Avatar
                      size="large"
                      style={{
                        width: 110,
                        height: 90,
                        marginTop: "20%",
                        opacity: 0.55
                      }}
                      src={Images.NO_DATA}
                    />
                    <h1
                      style={{
                        color: colors.black,
                        fontSize: "19px",
                        fontWeight: 340,
                        opacity: 0.5,
                        marginTop: 10
                      }}
                    >
                      You Have No Driver added in Your Fleet
                    </h1>
                    <h1
                      style={{
                        color: colors.black,
                        fontSize: sizes.h3,
                        fontWeight: 300,
                        opacity: 0.4
                      }}
                    >
                      Use add driver button above to add your first driver
                    </h1>
                  </div>
                )}
              </div>
            </div>
            <Col span={19}>
              <div style={{ height: window.innerHeight - 120, width: "101%" }}>
                <MapComponent
                  markersArray={this.state.OnlineDrivers}
                  height="100%"
                />
              </div>
            </Col>
          </div>
        </div>
      );
    }
  };
  Search = text => {
    const { allDrivers } = this.props;
    if (allDrivers !== 0) {
      if (text === "" || text === undefined) {
        this.setState({ allDriversState: allDrivers });
      } else {
        this.setState({
          allDriversState: allDrivers.filter(
            val =>
              val.name === text ||
              val.phoneNumber === text ||
              (val.vehicleInformation ? val.vehicleInformation.plateNo : "") ===
                text
          )
        });
      }
    }
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.allDrivers !== this.props.allDrivers) {
      const { allDrivers } = nextProps;

      let allDrivers2 = new Array();
      this.state.OnlineDrivers.length = 0;
      // eslint-disable-next-line array-callback-return
      allDrivers.map((val, ind) => {
        if (val.connectionStatus === "Connected") {
          allDrivers2[val.driverId] = new Array();
          allDrivers2[val.driverId].push({
            name: val.name,
            phoneNumber: val.phoneNumber,
            localId: val.driverId,
            lat: 0,
            long: 0
          });
        }
      });
      this.setState({
        OnlineDrivers: allDrivers2,
        allDriversState: allDrivers
      });
    } else {
      const { allDrivers } = this.props;
      this.setState({ allDriversState: allDrivers });
    }
  }
  componentWillMount() {
    const { allDrivers } = this.props;
    let allDrivers2 = new Array();
    let id = new Array();
    this.state.OnlineDrivers.length = 0;
    // eslint-disable-next-line array-callback-return
    allDrivers.map((val, ind) => {
      if (val.connectionStatus === "Connected") {
        allDrivers2[val.driverId] = new Array();
        allDrivers2[val.driverId].push({
          name: val.name,
          phoneNumber: val.phoneNumber,
          localId: val.driverId,
          lat: 0,
          long: 0
        });
      }
    });
    // eslint-disable-next-line array-callback-return
    allDrivers2.map((val, ind) => {
      // console.log('val.localId',val[0].localId)
      id.push(val[0].localId);
    });
    //  console.log('ssids',id)
    socket.emit("join", id);
    socket.on("newMessage", msg => {
      // console.log('message from socket ',msg);
    });
    socket.on("driverUpdates", data => {
      if (this.state.OnlineDrivers[data.token]) {
        //  console.log('this.state.OnlineDrivers[data',data)
        this.state.OnlineDrivers[data.token][0].lat = Number(data.lat);
        this.state.OnlineDrivers[data.token][0].long = Number(data.long);
        // console.log('this.state.OnlineDrivers[data.token]',this.state.OnlineDrivers[data.token][0])
        this.setState({ OnlineDrivers: this.state.OnlineDrivers });
      }
    });

    this.setState({ OnlineDrivers: allDrivers2, allDriversState: allDrivers });
  }
  selectModal = () => {
    if (this.state.nextModal === true) {
      const { driverModalVisible } = this.props;

      return (
        <MainModal
          visible={driverModalVisible}
          modalType={"driver"}
          data={false}
        />
      );
    } else if (this.state.nextModal === false) {
      const { driverModalVisible } = this.props;
      return (
        <MainModal
          visible={driverModalVisible}
          modalType={"driverConnect"}
          type={"!local"}
          data={false}
        />
      );
    }
  };
  render() {
    const { onChangeDriverModalVisible } = this.props;
    console.log("this.props y hain ", this.props);
    return (
      <div>
        {this.selectModal()}
        <Modal
          title={
            <span style={{ fontSize: sizes.h5, fontWeight: 400 }}>
              Add Driver
            </span>
          }
          footer={null}
          header={null}
          visible={this.state.selectModalVisible}
          onCancel={() => {
            this.setState({ selectModalVisible: false });
          }}
          width={600}
          bodyStyle={{ padding: 10, borderRadius: 1 }}
        >
          <div style={{ textAlign: "center" }}>
            <span>How do you want to add the driver?</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center"
            }}
          >
            <Col span={12}>
              <div
                style={{
                  margin: 20,
                  justifyContent: "center",
                  textAlign: "center",
                  borderColor: colors.backColor,
                  borderWidth: 1,
                  borderStyle: "solid",
                  cursor: "pointer"
                }}
                onClick={() => {
                  onChangeDriverModalVisible(true);
                  this.setState({ selectModalVisible: false, nextModal: true });
                }}
              >
                <Avatar
                  src={Images.DRIVER2}
                  style={{ margin: 20 }}
                  size={70}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 20
                  }}
                >
                  <span
                    style={{ fontSize: sizes.h1, color: textColor.text_midle }}
                  >
                    Add New Driver
                  </span>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_light }}
                  >
                Create a New Driver Account
                  </span>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  margin: 20,
                  justifyContent: "center",
                  textAlign: "center",
                  borderColor: colors.backColor,
                  borderWidth: 1,
                  borderStyle: "solid",
                  cursor: "pointer"
                }}
                onClick={() => {
                  onChangeDriverModalVisible(true);
                  this.setState({
                    selectModalVisible: false,
                    nextModal: false
                  });
                }}
              >
                <Avatar src={Images.ADDDRIVER} style={{ margin: 20 }} size={70} />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 20
                  }}
                >
                  <span
                    style={{ fontSize: sizes.h1, color: textColor.text_midle }}
                  >
                    Add Existing Driver
                  </span>
                  <span
                    style={{ fontSize: sizes.h3, color: textColor.text_light }}
                  >
                    Invite an already Existing Driver
                  </span>
                </div>
              </div>
            </Col>
          </div>
        </Modal>
        <div className="ant-card" style={{ height: "100%" }}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              span={12}
              style={{ padding: 20 }}
            >
              <InputGroup compact style={{ marginTop: 8, marginLeft: 20 }}>
                <Select placeholder="Category" style={{ width: 120 }}>
                  <Option value="Driver">Driver</Option>
                  <Option value="Location">Location</Option>
                  <Option value="Vehicle">Vehicle</Option>
                </Select>
                <Search
                  style={{ width: 300 }}
                  placeholder="Search"
                  onChange={text => {
                    this.Search(text.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              span={12}
              style={{ padding: 20 }}
            >
              <div style={{ float: "right" }}>
                <Button
                  size="default"
                  type="primary"
                  icon="plus"
                  style={{ marginRight: 12 }}
                  onClick={() => {
                    this.setState({ selectModalVisible: true });
                  }}
                >
                  Add Driver
                </Button>
                <Radio.Group>
                  <span style={{ paddingRight: 10, fontSize: 16 }}>view :</span>
                  <ButtonGroup>
                    <Button
                      size="default"
                      type={this.state.isCard ? "primary" : "ghost"}
                      icon="credit-card"
                      onClick={() => {
                        this.setState({ isCard: true });
                      }}
                    >
                      Card
                    </Button>
                    <Button
                      size="default"
                      type={!this.state.isCard ? "primary" : "ghost"}
                      icon="area-chart"
                      onClick={() => {
                        this.setState({ isCard: false });
                      }}
                    >
                      List Map
                    </Button>
                  </ButtonGroup>
                </Radio.Group>
              </div>
            </Col>
          </Row>
        </div>

        {this.renderListView()}
      </div>
    );
  }
}
const mapStateToProps = ({ drivers, global }) => ({
  driverModalVisible: drivers.driverModalVisible,
  allDrivers: global.allDrivers
});
const mapDispatchToProps = dispatch => ({
  onChangeDriverModalVisible: visible =>
    dispatch(Actions.ON_CHANGE_DRIVER_MODAL_VISIBLE(visible)),
  onChangeDetailData: data =>
    dispatch(ActionsGlobal.ON_CHANGE_DETAIL_DATA(data)),
  onChangeNavName: navName =>
    dispatch(ActionsGlobal.ON_CHANGE_NAV_NAME(navName))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Drivers);
