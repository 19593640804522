import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const { Column } = Table;
const TripTable = props => {
  const data2 = [];
  if (props.data.length!==0 && props.data!==[]) {
    props.data.map((val,ind)=>{
      data2.push({
        key: ind,
        trip: val.tripId,
        from: val.tripRequestInformation?val.tripRequestInformation.pickupLocation.street+"  "+ val.tripRequestInformation.pickupLocation.city:"Not Assigned",
        to: val.tripRequestInformation?val.tripRequestInformation.dropoffLocation.street+"  "+val.tripRequestInformation.dropoffLocation.city:"Not Assigned",
        status: "Not Assigned",
        date: val.tripRequestInformation?moment(val.tripRequestInformation.requestedTime).format("ll"):"Not Assigned",
        truck:val.vehicleInformation?val.vehicleInformation.map((val2,ind2)=>{return val2.plateNo+","}):"Not Assigned",
        driver: val.driverInformation? val.driverInformation.map((val3,ind)=>{ return val3.name}):"Not Assigned",
      });
    })
  }
  
  const renderCoulmn = () => {
    if (props.type === 'Trips') {
      return <Column title="Driver" dataIndex="driver" key="driver" />;

    }
    else if (props.type === 'Driver') {
      return <Column title="Truck" dataIndex="truck" key="truck" />;
    }
  };
  return (
    <Table
      dataSource={data2}
      pagination={{ pageSize: 5 }}
      style={{ minheight: 250, borderRadius: 3, backgroundColor: 'white' }}
      size="middle"
      scroll={{ x: '100%', y: false }}
    >
      <Column
        title="Action"
        key="action"
        render={record => (
          <Link
            to="/loggedin/detail"
            // // onClick={() => {
            // //   window.location.reload();
            // // }}
          >
            TRP#{record.trip}
          </Link>
        )}
      />
      <Column title="From" dataIndex="from" key="from" />
      <Column title="To" dataIndex="to" key="to" />
      <Column title="status" dataIndex="status" key="status" />
      <Column title="Date" dataIndex="date" key="date" />
      {renderCoulmn()}
    </Table>
  );
};

export default TripTable;
TripTable.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
};
TripTable.defaultProps = {
  data: [],
  type: 'Trips',
};
