import React, { Component } from 'react';
import { Col, Icon } from 'antd';
import { Images } from '../../public/asset/index';
import { textColor, sizes, colors } from '../../constants/constants';
import moment from 'moment';

class TripCard extends Component {
  render() {
    const {data} = this.props;
    return (
      <div className="box2"
        style={{
          borderBottomColor: 'rgba(0,0,0,0.12)',
          borderBottomWidth: 1,
          borderBottomStyle: 'groove',
          borderTopColor: 'rgba(0,0,0,0.12)',
          borderTopWidth: 1,
          borderTopStyle: 'groove',
          padding: 20,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Col span={12}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span
                style={{
                  fontSize: sizes.h1,
                  color: colors.primaryColor,
                  marginBottom: 2,
                }}
              >
                #TRP{data?(data.tripId?data.tripId:""):""}
              </span>
              <span
                style={{
                  fontSize: sizes.h5,
                  color: textColor.text_light,
                  marginBottom: 10,
                }}
              >
                <Icon type="calendar" />
                {" "} {data?(data.tripDate?moment(data.tripDate).format("L"):"Not Assigned"):"Not Assigned"}
              
              </span>
            </div>
          </Col>
          <Col span={14}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {/* <img
                style={{
                  marginRight: 10,
                  color: textColor.text_midle,
                }}
                alt=""
                height={20}
                src={data?(data.clientPicture?data.clientPicture:Images.ICON_ONE):Images.ICON_ONE}
              /> */}
              <span
                style={{
                  fontSize: sizes.h3,
                  marginBottom: 10,
                  marginRight: 20,
                  float: 'right',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: 130,
                  height: '1.32em',
                  whiteSpace: 'nowrap',
                }}
              >

                {data?(data.clientName?data.clientName:"Self"):"Not Assigned"}
              </span>
            </div>
          </Col>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            style={{
              marginTop: 8,
              marginRight: 12,
              color: textColor.text_midle,
            }}
            alt=""
            height={40}
            src={Images.LINER}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span
              style={{
                fontSize: sizes.h3,
                color: textColor.text_light,
                marginBottom: 10,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 180,
                height: '1.32em',
                whiteSpace: 'nowrap',
              }}
            >

              {data?data.from:"Not Assigned"}
            </span>
            <span
              style={{
                fontSize: sizes.h3,
                color: textColor.text_light,
                marginBottom: 10,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 180,
                height: '1.32em',
                whiteSpace: 'nowrap',
              }}
            >

              {data?data.to:"Not Assigned"}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default TripCard;
