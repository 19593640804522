import React from "react";
import { Images } from "../../public/asset/index";
import { Avatar } from "antd";
import { sizes, colors } from "../../constants/constants";
import PropTypes from "prop-types";
const ModalComponentLeftContent = props => {
  const { mainHeading, extraText, Image } = props;
  return (
    <div style={{ width: "100%", display: "gird", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center"
        }}
      >
        <Avatar
          src={Image}
          size="large"
          style={{ width: 150, height: 150, marginTop: "30%" }}
        />
        <span
          style={{
            fontSize: sizes.h1,
            color: colors.white,
            fontWeight: 400,marginTop:20
          }}
        >
          {mainHeading}
        </span>
        <span
          style={{
            fontSize: sizes.h5,
            color: colors.whiteDull,
            fontWeight: 400,marginTop:10
          }}
        >
          {extraText}
        </span>
        <span
          style={{ fontSize: sizes.h3, color: colors.white, marginTop: "70%" }}
        >
          Help?
        </span>
      </div>
    </div>
  );
};
export default ModalComponentLeftContent;
ModalComponentLeftContent.propTypes = {
  mainHeading: PropTypes.string,
  extraText: PropTypes.string,
  Image: PropTypes.string
};
ModalComponentLeftContent.defaultProps = {
  mainHeading: "Add Something",
  extraText:
    "Do est ex proident consectetur culpa exercitation est consequat dolor.",
  Image: Images.BOOK
};
